import React from 'react'
import "./ArtificeMain.css"
import { Helmet, HelmetProvider } from 'react-helmet-async';


const ArtificeMain = () => {
    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/ai/artificialintelligence" />
                    <meta name="keywords" content="AI iCloudsoft, Software Development Company in Pune" />
                    <title> AI Development | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-5 mt-5 d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "54px" }}>
                                        AI Development Company
                                    </h3>
                                    <h1>
                                        Leading Artificial Intelligence Solutions in Pune
                                    </h1>
                                    <p>
                                        Icloudsoft Technology’s AI services and solutions Maximize your business potential with a leading AI development company. From consulting to custom app development, our AI services streamline workflows and specialize in refining models like GPT, Llama, PaLM, Mistral, and Gemini for tailored solutions.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-6">
                                <img className='w-100' src={require("../Img/ArtificeMainBg.png")} alt="ArtificeMain" title='ArtificeMain' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-2 pb-2 d-flex justify-content-between align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        AI Development Company
                                    </h3>
                                    <h4 className='laila-semibold' style={{ fontSize: "17px", fontWeight: "600", color: "#fff" }}>
                                        Leading Artificial Intelligence Solutions in Pune
                                    </h4>
                                    <p>
                                        Icloudsoft Technology’s AI services and solutions Maximize your business potential with a leading AI development company. From consulting to custom app development, our AI services streamline workflows and specialize in refining models like GPT, Llama, PaLM, Mistral, and Gemini for tailored solutions.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5">
                                <img className='w-100' src={require("../Img/ArtificeMainBg.png")} alt="ArtificeMain" title='ArtificeMain' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-3" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='IntelligenceArtificeMain'>
                            <h2>
                                <span> Artificial  </span> Intelligence Development
                            </h2>
                            <div>
                                <p>
                                    Artificial intelligence (AI) development is leading the way in technology that helps businesses stay competitive and grow. By utilizing techniques like machine learning and computer vision, AI crafts intelligent systems that improve decision-making with data. These systems create smart chatbots, predictive analytics models, and computer vision tools, enhancing operations and personalizing experiences. AI can handle large amounts of data, find patterns, and improve over time, thereby transforming industries by automating tasks, improving operations, and driving innovation.
                                </p>
                                <p>
                                    iCloudsoft technology is a top AI development company that provides innovative solutions to help businesses stay ahead in today's competitive world. Specializing in advanced AI development services, we harness machine learning, deep learning, natural language processing, and computer vision to create custom solutions for clients globally. Whether it's intelligent chatbots, predictive analytics, or automated decision-making, iCloudsoft enables organizations to leverage AI for innovation, efficiency, and customer satisfaction.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> AI Development </span> Services
                            </h2>
                            <div className='d-flex justify-content-center'>
                                <p>
                                    We Provide Specialized and Personalized AI Development Services. Our solutions leverage cutting-edge technologies like machine learning, deep learning, computer vision, and natural language processing.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        AI-Assisted Decision Making
                                    </h2>
                                    <p>
                                        Our AI also assists you in the decision-making process for your business by analyzing the vast data and unlocking patterns and insights, which helps you upgrade your business's operations. Our developers build AI agents and copilots across various industries for faster and more accurate decision-making processes.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        AI App Development
                                    </h2>
                                    <p>
                                        At iCloudsoft, we design AI-enabled mobile apps for Android and iOS that are tailored for business use and revenue enhancement. Our skilled developers are dedicated to building AI applications with rich features, stringent security measures, exceptional user experience, and high performance, utilizing the latest tools and technologies to enhance user interactions for your company.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        AI/ML Strategy Consulting
                                    </h2>
                                    <p>
                                        Our AI/ML strategy consulting assists organizations in leveraging AI and ML effectively. We assess your data, infrastructure, and processes to craft a tailored roadmap for success. Our experts identify high-impact use cases, select optimal techniques, and ensure seamless implementation, empowering your team for success.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        PoC And MVP Development
                                    </h2>
                                    <p>
                                        We build proof-of-concept (PoC) prototypes to validate AI and ML use cases and demonstrate feasibility before full deployment. Our minimum viable product (MVP) development allows you to quickly get an AI solution to market and iterate based on user feedback. Leveraging agile practices, we rapidly design, build, and test working AI prototypes.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Fine-Tuning LLMs
                                    </h2>
                                    <p>
                                        Our team specializes in customizing and optimizing large language models (LLMs) like GPT-3 for your unique needs. We fine-tune these foundation models on your proprietary data to create highly accurate and capable models tailored to your industry, domain, or use case. This allows you to leverage the power of cutting-edge AI while maintaining data privacy and model governance.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        AI Integration
                                    </h2>
                                    <p>
                                        Expert services are available for organizations that are already using AI point solutions to integrate and unify disparate AI systems into a cohesive whole. This will enhance data sharing, enable cross-system intelligence, and provide a single pane of glass for monitoring and management.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Predictive Analytics
                                    </h2>
                                    <p>
                                        Leverage the power of machine learning with our predictive analytics solutions. We analyze your data to uncover meaningful patterns, forecasting future trends and behaviors. Our custom models and dashboards are tailored to your business's unique KPIs that enable you to make informed decisions. From demand forecasting to anomaly detection, we provide actionable insights for your business's growth.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Machine Learning
                                    </h2>
                                    <p>
                                        Tailored machine-learning solutions for tasks such as classification and anomaly detection are available through our company. Our team of experts utilizes models trained on your proprietary data using techniques like supervised and deep learning. These solutions are delivered as APIs, web applications, or batch processes, seamlessly integrated into your workflow for maximum impact.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Data Annotation And Labeling
                                    </h2>
                                    <p>
                                        Developing precise AI models relies heavily on top-notch training data. Our services include data annotation using machine learning tools and a global crowd workforce. Our annotators are skilled in labeling text, images, video, and other data to assist with various use cases such as object detection, sentiment analysis, NLP, and more.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Data Analysis
                                    </h2>
                                    <p>
                                        Through advanced analytics techniques, we empower organizations to discover valuable insights within their data. Our team of professionals employs exploratory analysis, data mining, statistical modeling, and data visualization to unveil hidden patterns, trends, and actionable intelligence. We offer comprehensive reports, interactive dashboards, and data storytelling to enhance data-driven decision-making across your organization.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Generative AI Development
                                    </h2>
                                    <p>
                                        Our team excels in developing powerful generative AI systems that can create new content in text, images, audio, and synthetic data using input prompts and training data. Whether it's content creation, data enhancement, conversational AI, or creative brainstorming, our services are designed to meet a wide range of needs. By leveraging sophisticated language models and generative adversarial networks (GANs), our algorithms deliver top-notch results customized to your requirements
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Data Mining And Management
                                    </h2>
                                    <p>
                                        Our firm offers comprehensive data mining and management solutions that are designed to maximize the value of your data. Our team of experts utilizes advanced techniques to extract intelligence from diverse sources, ensuring the quality and security of your data. This includes activities such as data discovery, extraction, cleansing, transformation, and integration into analytical environments to support your AI and analytics initiatives
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> AI Development </span> Services
                            </h2>
                            <div className='d-flex justify-content-center pt-3'>
                                <p>
                                    We Provide Specialized and Personalized AI Development Services. Our solutions leverage cutting-edge technologies like machine learning, deep learning, computer vision, and natural language processing.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        AI-Assisted Decision Making
                                    </h2>
                                    <p>
                                        Our AI also assists you in the decision-making process for your business by analyzing the vast data and unlocking patterns and insights, which helps you upgrade your business's operations. Our developers build AI agents and copilots across various industries for faster and more accurate decision-making processes.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        AI App Development
                                    </h2>
                                    <p>
                                        At iCloudsoft, we design AI-enabled mobile apps for Android and iOS that are tailored for business use and revenue enhancement. Our skilled developers are dedicated to building AI applications with rich features, stringent security measures, exceptional user experience, and high performance, utilizing the latest tools and technologies to enhance user interactions for your company.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        AI/ML Strategy Consulting
                                    </h2>
                                    <p>
                                        Our AI/ML strategy consulting assists organizations in leveraging AI and ML effectively. We assess your data, infrastructure, and processes to craft a tailored roadmap for success. Our experts identify high-impact use cases, select optimal techniques, and ensure seamless implementation, empowering your team for success.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        PoC And MVP Development
                                    </h2>
                                    <p>
                                        We build proof-of-concept (PoC) prototypes to validate AI and ML use cases and demonstrate feasibility before full deployment. Our minimum viable product (MVP) development allows you to quickly get an AI solution to market and iterate based on user feedback. Leveraging agile practices, we rapidly design, build, and test working AI prototypes.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Fine-Tuning LLMs
                                    </h2>
                                    <p>
                                        Our team specializes in customizing and optimizing large language models (LLMs) like GPT-3 for your unique needs. We fine-tune these foundation models on your proprietary data to create highly accurate and capable models tailored to your industry, domain, or use case. This allows you to leverage the power of cutting-edge AI while maintaining data privacy and model governance.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        AI Integration
                                    </h2>
                                    <p>
                                        Expert services are available for organizations that are already using AI point solutions to integrate and unify disparate AI systems into a cohesive whole. This will enhance data sharing, enable cross-system intelligence, and provide a single pane of glass for monitoring and management.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Predictive Analytics
                                    </h2>
                                    <p>
                                        Leverage the power of machine learning with our predictive analytics solutions. We analyze your data to uncover meaningful patterns, forecasting future trends and behaviors. Our custom models and dashboards are tailored to your business's unique KPIs that enable you to make informed decisions. From demand forecasting to anomaly detection, we provide actionable insights for your business's growth.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Machine Learning
                                    </h2>
                                    <p>
                                        Tailored machine-learning solutions for tasks such as classification and anomaly detection are available through our company. Our team of experts utilizes models trained on your proprietary data using techniques like supervised and deep learning. These solutions are delivered as APIs, web applications, or batch processes, seamlessly integrated into your workflow for maximum impact.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Data Annotation And Labeling
                                    </h2>
                                    <p>
                                        Developing precise AI models relies heavily on top-notch training data. Our services include data annotation using machine learning tools and a global crowd workforce. Our annotators are skilled in labeling text, images, video, and other data to assist with various use cases such as object detection, sentiment analysis, NLP, and more.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Data Analysis
                                    </h2>
                                    <p>
                                        Through advanced analytics techniques, we empower organizations to discover valuable insights within their data. Our team of professionals employs exploratory analysis, data mining, statistical modeling, and data visualization to unveil hidden patterns, trends, and actionable intelligence. We offer comprehensive reports, interactive dashboards, and data storytelling to enhance data-driven decision-making across your organization.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Generative AI Development
                                    </h2>
                                    <p>
                                        Our team excels in developing powerful generative AI systems that can create new content in text, images, audio, and synthetic data using input prompts and training data. Whether it's content creation, data enhancement, conversational AI, or creative brainstorming, our services are designed to meet a wide range of needs. By leveraging sophisticated language models and generative adversarial networks (GANs), our algorithms deliver top-notch results customized to your requirements
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Data Mining And Management
                                    </h2>
                                    <p>
                                        Our firm offers comprehensive data mining and management solutions that are designed to maximize the value of your data. Our team of experts utilizes advanced techniques to extract intelligence from diverse sources, ensuring the quality and security of your data. This includes activities such as data discovery, extraction, cleansing, transformation, and integration into analytical environments to support your AI and analytics initiatives
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Benefits Of Our  <span> AI Development </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIDevelopmentBenefits">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-gear"></i>
                                            </div>
                                            <h2>
                                                Improved Efficiency
                                            </h2>
                                            <p>
                                                AI streamlines processes, automates tasks, and reduces operational costs, allowing businesses to operate more efficiently
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-brands fa-hive"></i>
                                            </div>
                                            <h2 >
                                                Enhanced Customer Experience
                                            </h2>
                                            <p>
                                                The use of AI for personalization and chatbots results in customized interactions that boost customer satisfaction and loyalty.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-layer-group"></i>
                                            </div>
                                            <h2>
                                                Data-Driven Decision Making
                                            </h2>
                                            <p>
                                                By offering valuable data insights, artificial intelligence empowers businesses to make informed strategic decisions quickly and accurately.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                Risk Management
                                            </h2>
                                            <p>
                                                AI helps identify and mitigate risks through predictive analytics and real-time monitoring of business processes.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                Competitive Advantage
                                            </h2>
                                            <p>
                                                Businesses can gain a competitive edge in rapidly evolving markets through the implementation of AI. AI helps them to lead the way in innovation and enhance operational effectiveness.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-gears"></i>
                                            </div>
                                            <h2>
                                                Scalability
                                            </h2>
                                            <p>
                                                Enterprises have the capacity to expand their operations seamlessly without the need to increase their workforce proportionally. This promotes flexibility and cost-effectiveness in their growth strategies.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/BenefitsUp.png")} alt="BenefitsUp" title='BenefitsUp' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "24px" }}>
                                Benefits Of Our <span> AI Development </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Improved Efficiency
                                    </h2>
                                    <p>
                                        AI streamlines processes, automates tasks, and reduces operational costs, allowing businesses to operate more efficiently
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2 >
                                        Enhanced Customer Experience
                                    </h2>
                                    <p>
                                        The use of AI for personalization and chatbots results in customized interactions that boost customer satisfaction and loyalty.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Data-Driven Decision Making
                                    </h2>
                                    <p>
                                        By offering valuable data insights, artificial intelligence empowers businesses to make informed strategic decisions quickly and accurately.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Risk Management
                                    </h2>
                                    <p>
                                        AI helps identify and mitigate risks through predictive analytics and real-time monitoring of business processes.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Competitive Advantage
                                    </h2>
                                    <p>
                                        Businesses can gain a competitive edge in rapidly evolving markets through the implementation of AI. AI helps them to lead the way in innovation and enhance operational effectiveness.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Scalability
                                    </h2>
                                    <p>
                                        Enterprises have the capacity to expand their operations seamlessly without the need to increase their workforce proportionally. This promotes flexibility and cost-effectiveness in their growth strategies.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-3">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Artificial Intelligence </span> Work
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AIWork'>
                                    <h2>
                                        <span> Academic AI </span> - Education Made Accessible And Easy Through WhatsApp
                                    </h2>
                                    <p>
                                        Academic AI, a product of iCloudsoft technology, is an innovative AI-based learning platform. This autonomous AI professor is well-versed in diverse academic disciplines, catering to students from primary to college education levels. Accessible via WhatsApp, it offers instant and location-independent learning assistance.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/AIwork.png")} alt="AIwork" title='AIwork' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pt-5 pb-5 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-3">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "28px" }}>
                                Our <span> Artificial Intelligence </span> Work
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/AIwork.png")} alt="AIwork" title='AIwork' />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className='AIWork'>
                                    <h2>
                                        <span> Academic AI </span> - Education Made Accessible And Easy Through WhatsApp
                                    </h2>
                                    <p>
                                        Academic AI, a product of iCloudsoft technology, is an innovative AI-based learning platform. This autonomous AI professor is well-versed in diverse academic disciplines, catering to students from primary to college education levels. Accessible via WhatsApp, it offers instant and location-independent learning assistance.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-3 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-2 pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../Img/AITechnology.png")} alt="AITechnology" title='AITechnology' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AITechnology'>
                                    <h2>
                                        Transform Your Business With <span> AI Technology </span>
                                    </h2>
                                    <p>
                                        Emerging with AI technology is like venturing into uncharted territory, where businesses can discover innovative possibilities and breakthroughs.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-2 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg  pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../Img/AITechnology.png")} alt="AITechnology" title='AITechnology' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AITechnology'>
                                    <h2>
                                        Transform Your Business With <span> AI Technology </span>
                                    </h2>
                                    <p>
                                        Emerging with AI technology is like venturing into uncharted territory, where businesses can discover innovative possibilities and breakthroughs.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> AI Solutions </span> For Industries
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/AIIndustries.png")} alt="AIIndustries" title='AIIndustries' />
                                </div>
                            </div>

                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIIndustries">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                E-Commerce
                                            </h2>
                                            <p>
                                                AI enhances the e-commerce experience through personalized recommendations, chatbots for customer support, and dynamic pricing strategies. AI provides valuable insights into customer behavior and streamlines warehousing and fulfillment processes with automation.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                Retail
                                            </h2>
                                            <p>
                                                AI powers personalized recommendations based on customer data, improving product discovery and increasing sales. Chatbots and virtual assistants provide instant customer service and support. AI-driven insights help retailers understand consumer behavior and tailor marketing strategies, while automation in warehousing and logistics streamlines operations.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                Construction / Real Estate
                                            </h2>
                                            <p>
                                                AI aids in project planning through predictive analytics, optimizing design and resource allocation, and improving safety with real-time monitoring of construction sites. AI boosts accuracy, efficiency, and insight in both construction and real estate, driving innovation and improving outcomes.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                Travel / Logistics
                                            </h2>
                                            <p>
                                                AI optimizes booking systems with personalized recommendations, predicts travel trends, and provides real-time customer support through chatbots. AI streamlines supply chain management through predictive analytics for demand forecasting, route optimization for deliveries, and automated inventory management
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                Education
                                            </h2>
                                            <p>
                                                AI enhances administrative tasks, such as grading and scheduling, allowing educators to focus more on teaching. Overall, AI is making education more accessible, personalized, and data-driven. AI-driven tools provide real-time feedback and support, while chatbots and virtual assistants offer on-demand help and answer queries.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                Finance / Banking Sector
                                            </h2>
                                            <p>
                                                AI algorithms analyze vast amounts of data to detect fraudulent transactions, automate trading, and manage risk more effectively. AI improves operational efficiency by automating routine tasks, optimizing loan approvals, and enhancing regulatory compliance.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> AI Solutions </span> For Industries
                            </h2>
                        </div>

                        <div className="row gx-0 gy-4 pt-3 AIIndustries">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        E-Commerce
                                    </h2>
                                    <p>
                                        AI enhances the e-commerce experience through personalized recommendations, chatbots for customer support, and dynamic pricing strategies. AI provides valuable insights into customer behavior and streamlines warehousing and fulfillment processes with automation.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        Retail
                                    </h2>
                                    <p>
                                        AI powers personalized recommendations based on customer data, improving product discovery and increasing sales. Chatbots and virtual assistants provide instant customer service and support. AI-driven insights help retailers understand consumer behavior and tailor marketing strategies, while automation in warehousing and logistics streamlines operations.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        Construction / Real Estate
                                    </h2>
                                    <p>
                                        AI aids in project planning through predictive analytics, optimizing design and resource allocation, and improving safety with real-time monitoring of construction sites. AI boosts accuracy, efficiency, and insight in both construction and real estate, driving innovation and improving outcomes.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        Travel / Logistics
                                    </h2>
                                    <p>
                                        AI optimizes booking systems with personalized recommendations, predicts travel trends, and provides real-time customer support through chatbots. AI streamlines supply chain management through predictive analytics for demand forecasting, route optimization for deliveries, and automated inventory management
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        Education
                                    </h2>
                                    <p>
                                        AI enhances administrative tasks, such as grading and scheduling, allowing educators to focus more on teaching. Overall, AI is making education more accessible, personalized, and data-driven. AI-driven tools provide real-time feedback and support, while chatbots and virtual assistants offer on-demand help and answer queries.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        Finance / Banking Sector
                                    </h2>
                                    <p>
                                        AI algorithms analyze vast amounts of data to detect fraudulent transactions, automate trading, and manage risk more effectively. AI improves operational efficiency by automating routine tasks, optimizing loan approvals, and enhancing regulatory compliance.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> AI Development </span> Process
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIDevelopmentBenefits">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Problem Identification
                                            </h2>
                                            <p>
                                                The initial step involves thoroughly grasping the problem or business challenge at hand. Our team of experts works closely with clients to define the objectives, scope, and desired outcomes, thus creating a well-defined roadmap for achieving success.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Analyzing Data
                                            </h2>
                                            <p>
                                                Our team meticulously collects and analyzes relevant data sources by leveraging advanced techniques like data mining, exploratory data analysis, and feature engineering to extract valuable insights.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Preprocessing
                                            </h2>
                                            <p>
                                                Prior to inputting data into AI models, we conduct preprocessing to guarantee the quality and uniformity. This includes methods such as data cleaning, normalization, managing missing values, and feature extraction, all of which empower our models to effectively learn from the preprocessed data.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Model Selection
                                            </h2>
                                            <p>
                                                Choosing the right AI model is crucial for optimal performance. Our experts leverage their deep knowledge of machine learning algorithms, such as neural networks, decision trees, and support vector machines, to select the most appropriate model for the task at hand.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Model Training
                                            </h2>
                                            <p>
                                                Throughout this stage, the AI model chosen is trained using the preprocessed data. Techniques such as regularization, hyperparameter tuning, and transfer learning are utilized to enhance model performance and prevent overfitting.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Testing And Evaluation
                                            </h2>
                                            <p>
                                                Through thorough testing and assessment, we guarantee the reliability and accuracy of the AI model. We utilize different methods such as cross-validation, holdout sets, and performance metrics including precision, recall, and F1 score to evaluate the model's performance and implement any required modifications.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Deployment and Monitoring
                                            </h2>
                                            <p>
                                                After the rigid quality standards, we deploy it into production environments. Additionally, we continuously monitor and maintain the model's ongoing performance to retrain or update it as needed to adapt to changing conditions or new data.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/BenefitsUp.png")} alt="BenefitsUp" title='BenefitsUp' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> AI Development </span> Process
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Problem Identification
                                    </h2>
                                    <p>
                                        The initial step involves thoroughly grasping the problem or business challenge at hand. Our team of experts works closely with clients to define the objectives, scope, and desired outcomes, thus creating a well-defined roadmap for achieving success.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Analyzing Data
                                    </h2>
                                    <p>
                                        Our team meticulously collects and analyzes relevant data sources by leveraging advanced techniques like data mining, exploratory data analysis, and feature engineering to extract valuable insights.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Preprocessing
                                    </h2>
                                    <p>
                                        Prior to inputting data into AI models, we conduct preprocessing to guarantee the quality and uniformity. This includes methods such as data cleaning, normalization, managing missing values, and feature extraction, all of which empower our models to effectively learn from the preprocessed data.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Model Selection
                                    </h2>
                                    <p>
                                        Choosing the right AI model is crucial for optimal performance. Our experts leverage their deep knowledge of machine learning algorithms, such as neural networks, decision trees, and support vector machines, to select the most appropriate model for the task at hand.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Model Training
                                    </h2>
                                    <p>
                                        Throughout this stage, the AI model chosen is trained using the preprocessed data. Techniques such as regularization, hyperparameter tuning, and transfer learning are utilized to enhance model performance and prevent overfitting.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Testing And Evaluation
                                    </h2>
                                    <p>
                                        Through thorough testing and assessment, we guarantee the reliability and accuracy of the AI model. We utilize different methods such as cross-validation, holdout sets, and performance metrics including precision, recall, and F1 score to evaluate the model's performance and implement any required modifications.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Deployment and Monitoring
                                    </h2>
                                    <p>
                                        After the rigid quality standards, we deploy it into production environments. Additionally, we continuously monitor and maintain the model's ongoing performance to retrain or update it as needed to adapt to changing conditions or new data.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                <span> AI Development </span> Tech Stack
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        DL Framworks
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    PyTorch
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Caffe 2
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    nVIDIA
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Chainer
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Theano
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Mxnet
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Modules And Tool Kit
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Kurento
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Core ML
                                                </div>
                                            </div>

                                            <div className='col-lg-12'>
                                                <div className='AITech'>
                                                    Microsoft Cognitive Tollki
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Libraries
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Opennn
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    TensorFlow
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Sonnet
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Tf-slim
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Tensor2Tensor
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Neuroph
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Front End
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    ANGULARJS
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Next JS
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Typescript
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Back End
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Node JS
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Python
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Rust
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                <span> AI Development </span> Tech Stack
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        DL Framworks
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    PyTorch
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Caffe 2
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    nVIDIA
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Chainer
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Theano
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Mxnet
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Modules And Tool Kit
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Kurento
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Core ML
                                                </div>
                                            </div>

                                            <div className='col-lg-12'>
                                                <div className='AITech'>
                                                    Microsoft Cognitive Tollki
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Libraries
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Opennn
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    TensorFlow
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Sonnet
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Tf-slim
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Tensor2Tensor
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Neuroph
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Front End
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    ANGULARJS
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Next JS
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Typescript
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Back End
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Node JS
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Python
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                                <div className='AITech'>
                                                    Rust
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ArtificeMain