import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Web3 = () => {
    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/ai/web3" />
                    <meta name="keywords" content="Web 3 iCloudsoft, Software Development Company in Pune." />
                    <title> Web 3 | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-5 mt-5 d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "50px" }}>
                                        WEB3 DEVELOPMENT COMPANY
                                    </h3>
                                    <h1>
                                        Transformative Web 3 Solutions in AI Development Pune
                                    </h1>
                                    <p>
                                        Web3 development is characterized by the creation of sophisticated applications that harness decentralized technologies. This facilitates the emergence of innovative revenue strategies and empowers users to generate, store, and transfer digital assets with ease. It streamlines business operations, amplifies digital presence, and embraces the transformative capabilities of decentralized platforms, signifying a major shift in the digital economy.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-6">
                                <img className='w-100' src={require("../Img/ArtificeMainBg.png")} alt="ArtificeMain" title='ArtificeMain' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-2 pb-2 d-flex justify-content-between align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        WEB3 DEVELOPMENT COMPANY
                                    </h3>
                                    <h4 className='laila-semibold' style={{ fontSize: "17px", fontWeight: "600", color: "#fff" }}>
                                        Transformative Web 3 Solutions in AI Development Pune
                                    </h4>
                                    <p>
                                        Web3 development is characterized by the creation of sophisticated applications that harness decentralized technologies. This facilitates the emergence of innovative revenue strategies and empowers users to generate, store, and transfer digital assets with ease. It streamlines business operations, amplifies digital presence, and embraces the transformative capabilities of decentralized platforms, signifying a major shift in the digital economy.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5">
                                <img className='w-100' src={require("../Img/ArtificeMainBg.png")} alt="ArtificeMain" title='ArtificeMain' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Web3 Development </span> Services
                            </h2>
                            <div className='d-flex justify-content-center'>
                                <p>
                                    We give assistance to the organization in fastening the power of decentralization with our reliable end-to-end web3 services to improve efficiency, unlock more business values, enhance the customer experience, and create new business models.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        DeFi Development
                                    </h2>
                                    <p>
                                        We provide full-service DeFi development and Web3 consulting. AMM non-custodial wallets, cross-chain DeFi exchanges, DeFi dApps, layer2 solutions, DeFi tokens, and other cutting-edge DeFi solutions are all created by our team of DeFi experts.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Metaverse Development
                                    </h2>
                                    <p>
                                        We develop cutting-edge metaverse apps and assets that add commercial value. Also, we create elements such as Web3 wallets, avatars, 3D immersive environments, and self-sovereign identification through blockchain, AR, VR, and 3D construction.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        DApps Development
                                    </h2>
                                    <p>
                                        We design next-generation dApps to create Web3 projects through programming languages like Solidity, Rust, and Golang and platforms like Brownie and Substrate. Our web3 developers give attention to developing customized web3 dApps with high security, interoperability, and scalability.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        NFT Development
                                    </h2>
                                    <p>
                                        We offer customized web3 NFT marketplaces that enable organizations to control activities like NFT trading as well as live auctions. We also offer white-label solutions for the NFT marketplace that businesses can integrate into their Web3 applications and avoid developing from scratch, which reduces time.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Web3 Game Development
                                    </h2>
                                    <p>
                                        Our developers develop trending games like play-to-earn and NFT-based multiplayer games with deep research using game development tools like Unreal Engine 5 and Unity. Further, we researched several new web-based game applications to meet the growing development needs in the gaming industry.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Smart Contract
                                    </h2>
                                    <p>
                                        Our designers develop a highly secure, reliable, and upgrade-able smart contract with contemporary Web3 standards through accompanying open-source, composable back ends and the public of Web3. Our smart contract operates business rules at many stages, which gives absolute transparency.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Native Payment Solutions
                                    </h2>
                                    <p>
                                        We develop creative digital wallets for Web3 platforms that offer two major services. It handles the native currency of the particular platform and acts as a key to transfer Web3 dApps to other networks.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Self-Sovereign Identity
                                    </h2>
                                    <p>
                                        We created an SSI identity system to help businesses offer users user-friendly access in a secure environment. Further, our experts develop wallets and credentials for users identities over many decentralized ecosystems.
                                    </p>

                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Multi Chain Solutions
                                    </h2>
                                    <p>
                                        We deliver multi-chain web3 dApp services that enable users to access web3 platforms and get involved in activities like trading. Our experts integrate Oracle, APIs, and DAO into the system, which leads to more transparency.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Web3 Development </span> Services
                            </h2>
                            <div className='d-flex justify-content-center pt-3'>
                                <p>
                                    We give assistance to the organization in fastening the power of decentralization with our reliable end-to-end web3 services to improve efficiency, unlock more business values, enhance the customer experience, and create new business models.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        DeFi Development
                                    </h2>
                                    <p>
                                        We provide full-service DeFi development and Web3 consulting. AMM non-custodial wallets, cross-chain DeFi exchanges, DeFi dApps, layer2 solutions, DeFi tokens, and other cutting-edge DeFi solutions are all created by our team of DeFi experts.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Metaverse Development
                                    </h2>
                                    <p>
                                        We develop cutting-edge metaverse apps and assets that add commercial value. Also, we create elements such as Web3 wallets, avatars, 3D immersive environments, and self-sovereign identification through blockchain, AR, VR, and 3D construction.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        DApps Development
                                    </h2>
                                    <p>
                                        We design next-generation dApps to create Web3 projects through programming languages like Solidity, Rust, and Golang and platforms like Brownie and Substrate. Our web3 developers give attention to developing customized web3 dApps with high security, interoperability, and scalability.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        NFT Development
                                    </h2>
                                    <p>
                                        We offer customized web3 NFT marketplaces that enable organizations to control activities like NFT trading as well as live auctions. We also offer white-label solutions for the NFT marketplace that businesses can integrate into their Web3 applications and avoid developing from scratch, which reduces time.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Web3 Game Development
                                    </h2>
                                    <p>
                                        Our developers develop trending games like play-to-earn and NFT-based multiplayer games with deep research using game development tools like Unreal Engine 5 and Unity. Further, we researched several new web-based game applications to meet the growing development needs in the gaming industry.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Smart Contract
                                    </h2>
                                    <p>
                                        Our designers develop a highly secure, reliable, and upgrade-able smart contract with contemporary Web3 standards through accompanying open-source, composable back ends and the public of Web3. Our smart contract operates business rules at many stages, which gives absolute transparency.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Native Payment Solutions
                                    </h2>
                                    <p>
                                        We develop creative digital wallets for Web3 platforms that offer two major services. It handles the native currency of the particular platform and acts as a key to transfer Web3 dApps to other networks.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Self-Sovereign Identity
                                    </h2>
                                    <p>
                                        We created an SSI identity system to help businesses offer users user-friendly access in a secure environment. Further, our experts develop wallets and credentials for users identities over many decentralized ecosystems.
                                    </p>

                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Multi Chain Solutions
                                    </h2>
                                    <p>
                                        We deliver multi-chain web3 dApp services that enable users to access web3 platforms and get involved in activities like trading. Our experts integrate Oracle, APIs, and DAO into the system, which leads to more transparency.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Benefits Of Our  <span> Web3 Development </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIDevelopmentBenefits">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-gear"></i>
                                            </div>
                                            <h2>
                                                Decentralization Empowerment
                                            </h2>
                                            <p>
                                                Embrace a decentralized structure, offering enhanced security and transparency. Users gain greater control over their data without the need for intermediaries.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-brands fa-hive"></i>
                                            </div>
                                            <h2 >
                                                Interoperable Solutions
                                            </h2>
                                            <p>
                                                Unlock seamless interactions across diverse blockchain networks, ensuring compatibility and connectivity in the dynamic digital environment.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-layer-group"></i>
                                            </div>
                                            <h2>
                                                User-Centric Approach
                                            </h2>
                                            <p>
                                                Empower users to own, control, and monetize their digital assets, fostering a customer-centric model that enhances engagement and trust.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                Data Privacy Assurance
                                            </h2>
                                            <p>
                                                Safeguard user data with robust privacy features, providing individuals with ownership and control over their personal information.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                Cross-Border Transactions
                                            </h2>
                                            <p>
                                                Enhance effective cross-border transactions more securely and seamlessly with blockchain technology.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-gears"></i>
                                            </div>
                                            <h2>
                                                Innovative DApp Development
                                            </h2>
                                            <p>
                                                Create advanced decentralized applications (DApps) that redefine user experiences, offering novel solutions and opportunities within the Web3 ecosystem.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/BenefitsUp.png")} alt="BenefitsUp" title='BenefitsUp' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "24px" }}>
                                Benefits Of Our <span> Web3 Development </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Decentralization Empowerment
                                    </h2>
                                    <p>
                                        Embrace a decentralized structure, offering enhanced security and transparency. Users gain greater control over their data without the need for intermediaries.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2 >
                                        Interoperable Solutions
                                    </h2>
                                    <p>
                                        Unlock seamless interactions across diverse blockchain networks, ensuring compatibility and connectivity in the dynamic digital environment.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        User-Centric Approach
                                    </h2>
                                    <p>
                                        Empower users to own, control, and monetize their digital assets, fostering a customer-centric model that enhances engagement and trust.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Data Privacy Assurance
                                    </h2>
                                    <p>
                                        Safeguard user data with robust privacy features, providing individuals with ownership and control over their personal information.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Cross-Border Transactions
                                    </h2>
                                    <p>
                                        Enhance effective cross-border transactions more securely and seamlessly with blockchain technology.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Innovative DApp Development
                                    </h2>
                                    <p>
                                        Create advanced decentralized applications (DApps) that redefine user experiences, offering novel solutions and opportunities within the Web3 ecosystem.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-3 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-2 pb-lg-3 mb-4">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Features Of Our <span> Web3 Development </span>
                            </h2>
                        </div>
                        <div className="row pt-4 g-4 d-flex justify-content-around align-items-center  ">
                            <div className="col-xl-5 col-md-6 ">
                                <div>
                                    <img className='w-100' src={require("../Img/Feature.png")} alt="Feature" title='Feature' />
                                </div>
                            </div>

                            <div className="col-xl-5 col-md-6 ">
                                <div className='BenefitsOfAI text-start '>
                                    <h2>
                                        Web3 Development Features
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Experiential Learning
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Play2Earn
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Socialization
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Web Of Intelligence
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Personalization
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Interoperability
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-2 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg  pb-lg-3 mb-4">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Features Of Our <span> Web3 Development </span>
                            </h2>
                        </div>
                        <div className="row pt-4 g-4 d-flex justify-content-around align-items-center  ">
                            <div className="col-xl-5 col-md-6 ">
                                <div>
                                    <img className='w-100' src={require("../Img/Feature.png")} alt="Feature" title='Feature' />
                                </div>
                            </div>

                            <div className="col-xl-5 col-md-6 ">
                                <div className='BenefitsOfAI text-start '>
                                    <h2>
                                        Web3 Development Features
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Experiential Learning
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Play2Earn
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Socialization
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Web Of Intelligence
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Personalization
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Interoperability
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Web3 Development </span> For Industries
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/AIIndustries.png")} alt="AIIndustries" title='AIIndustries' />
                                </div>
                            </div>

                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIIndustries">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                Platform Development
                                            </h2>
                                            <p>
                                                With the help of our specialists, create a decentralized and token-based economy based on blockchain technology in the digital era.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                GameCreation
                                            </h2>
                                            <p>
                                                Allow your Gen Z and Alpha experts to have an endless amount of fun while staying connected with in-game awards and blockchain security.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                NFTDevelopment
                                            </h2>
                                            <p>
                                                Deploy a non-fungible (ERC721) token utilizing Ethereum technology to give your users more authority over minting, selling, and buying digital assets.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                SocialMedia Platform
                                            </h2>
                                            <p>
                                                Reward your users and your social media app with cryptocurrencies when it launches on the app store. Offer post-to-earn, the next step in the creative economy's progression
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                Real EstatePlatform
                                            </h2>
                                            <p>
                                                With our 3D, web3 platform development services, you can increase the demand for your real estate platform and make your properties more accessible.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                StoreDevelopment
                                            </h2>
                                            <p>
                                                With our web3 eCommerce store creation services, you can reposition your brand by providing a unique user experience that allows users to try on clothes before purchasing them.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                EducationPlatform
                                            </h2>
                                            <p>
                                                Offer a new and accessible method to high-quality education throughout the world with proof of learning, and reveal the new potentials of online learning.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                EventPlatform
                                            </h2>
                                            <p>
                                                Plan a live event on your Web3 platform to allow users to communicate with one another using Ethereum blockchain-based smart contracts and cryptocurrency
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                Application Development
                                            </h2>
                                            <p>
                                                This level involves programming and coding based on your approved designs. Our development lifecycle is divided into three stages: alpha, beta, and release.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Web3 Development </span> For Industries
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIIndustries">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        Platform Development
                                    </h2>
                                    <p>
                                        With the help of our specialists, create a decentralized and token-based economy based on blockchain technology in the digital era.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        GameCreation
                                    </h2>
                                    <p>
                                        Allow your Gen Z and Alpha experts to have an endless amount of fun while staying connected with in-game awards and blockchain security.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        NFTDevelopment
                                    </h2>
                                    <p>
                                        Deploy a non-fungible (ERC721) token utilizing Ethereum technology to give your users more authority over minting, selling, and buying digital assets.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        SocialMedia Platform
                                    </h2>
                                    <p>
                                        Reward your users and your social media app with cryptocurrencies when it launches on the app store. Offer post-to-earn, the next step in the creative economy's progression
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        Real EstatePlatform
                                    </h2>
                                    <p>
                                        With our 3D, web3 platform development services, you can increase the demand for your real estate platform and make your properties more accessible.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        StoreDevelopment
                                    </h2>
                                    <p>
                                        With our web3 eCommerce store creation services, you can reposition your brand by providing a unique user experience that allows users to try on clothes before purchasing them.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        EducationPlatform
                                    </h2>
                                    <p>
                                        Offer a new and accessible method to high-quality education throughout the world with proof of learning, and reveal the new potentials of online learning.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        EventPlatform
                                    </h2>
                                    <p>
                                        Plan a live event on your Web3 platform to allow users to communicate with one another using Ethereum blockchain-based smart contracts and cryptocurrency
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        Application Development
                                    </h2>
                                    <p>
                                        This level involves programming and coding based on your approved designs. Our development lifecycle is divided into three stages: alpha, beta, and release.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Web3 Development </span> Platform
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIDevelopmentBenefits">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Discovery
                                            </h2>
                                            <p>
                                                We learn about your business, goals, pain, points, and top priorities. These repeated brainstorming sessions aid us in mapping our existing procedures as well as your long-term objectives.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Designing Strategies
                                            </h2>
                                            <p>
                                                We begin developing, prototyping, and user testing your platform or product at this stage, after gathering your requirements in a whiteboard session.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Development
                                            </h2>
                                            <p>
                                                This level involves programming and coding based on your approved designs. Our development lifecycle is divided into three stages: Alpha, Beta, and Release.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Testing & Quality
                                            </h2>
                                            <p>
                                                For each version of your product or platform, we continue our data-driven validation process and conduct in-depth QA testing to verify that the final product is market-ready.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Launching & Maintaining
                                            </h2>
                                            <p>
                                                Your product or platform is now available on the market. Our developers will launch your product in live environments once you approve it.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Customer Service
                                            </h2>
                                            <p>
                                                We execute continuing product and platform maintenance and optimization, deploy market strategies, and give real-time/fix support to ensure continual improvement.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/BenefitsUp.png")} alt="BenefitsUp" title='BenefitsUp' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Web3 Development </span> Platform
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Discovery
                                    </h2>
                                    <p>
                                        We learn about your business, goals, pain, points, and top priorities. These repeated brainstorming sessions aid us in mapping our existing procedures as well as your long-term objectives.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Designing Strategies
                                    </h2>
                                    <p>
                                        We begin developing, prototyping, and user testing your platform or product at this stage, after gathering your requirements in a whiteboard session.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Development
                                    </h2>
                                    <p>
                                        This level involves programming and coding based on your approved designs. Our development lifecycle is divided into three stages: Alpha, Beta, and Release.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Testing & Quality
                                    </h2>
                                    <p>
                                        For each version of your product or platform, we continue our data-driven validation process and conduct in-depth QA testing to verify that the final product is market-ready.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Launching & Maintaining
                                    </h2>
                                    <p>
                                        Your product or platform is now available on the market. Our developers will launch your product in live environments once you approve it.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Customer Service
                                    </h2>
                                    <p>
                                        We execute continuing product and platform maintenance and optimization, deploy market strategies, and give real-time/fix support to ensure continual improvement.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                <span> Web3 Development </span> Tech Stack & Technologies
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 ">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Web3 Development Tech Stack
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    NFT
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    3D Technologies
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Artificial Intelligence (AI)
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Blockchain Technology
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Cryptocurrencies Wallets
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Smart Contract
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Web3 Development Technologies
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Virtual Reality
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Machine Learning
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Blockchain
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Artificial Intelligence
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Smart Contract
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                <span> Web3 Development </span> Tech Stack
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 ">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Web3 Development Tech Stack
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    NFT
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    3D Technologies
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Artificial Intelligence (AI)
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Blockchain Technology
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Cryptocurrencies Wallets
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Smart Contract
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Web3 Development Technologies
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Virtual Reality
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Machine Learning
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Blockchain
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Artificial Intelligence
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Smart Contract
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Web3