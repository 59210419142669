import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const EmailMarketing = () => {
    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/digiatlmarketing/emailmarketing" />
                    <meta name="keywords" content="Email Marketing iCloudsoft, Software Development Company in Pune" />
                    <title> Email Marketing | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-5 mt-5 d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className=''>
                                        Email Marketing
                                    </h3>
                                    <h1>
                                        Effective Email Marketing Solutions in Pune
                                    </h1>
                                    <p>
                                        At iCloudsoft technology, we create personalized emails, target customers   accurately with behavioral  automations , and optimize with real-time reporting.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <img className='w-100' src={require("../Img/EmailM.png")} alt="EmailM" title='EmailM' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-2 pb-2 d-flex justify-content-between align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Email Marketing
                                    </h3>
                                    <h4 className='laila-semibold' style={{ fontSize: "17px", fontWeight: "600", color: "#fff" }}>
                                        Effective Email Marketing Solutions in Pune
                                    </h4>
                                    <p>
                                        At iCloudsoft technology, we create personalized emails, target customers   accurately with behavioral  automations , and optimize with real-time reporting.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5">
                                <img className='w-100' src={require("../Img/ArtificeMainBg.png")} alt="ArtificeMain" title='ArtificeMain' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-3" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='IntelligenceArtificeMain'>
                            <h2>
                                Our Prime Focus
                            </h2>
                            <div>
                                <p>
                                    We focus on digital marketing strategy that involves sending targeted and personalized messages to customers and potential customers via email. It aims to build relationships, promote products or services, and drive sales and revenue. Email marketing involves creating engaging content, segmenting email lists, and using compelling subject lines and CTAs. It offers high ROI, increased brand awareness and loyalty, and improved customer engagement and retention. By leveraging email marketing, businesses can reach their target audience, nurture leads, and drive conversions, ultimately achieving their marketing goals and objectives. It's a measurable and trackable way to connect with customers.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Email Marketing </span> Services
                            </h2>
                            <div className='d-flex justify-content-center'>
                                <p>
                                    iCloudsoft Technology offers the complete solution for engaging and strategic email marketing services. Our aim is to strengthen your online identity, create a dynamic web presence for your business, and establish real connections.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/EmailSendingandDelivery.png")} alt="EmailSendingandDelivery" title='EmailSendingandDelivery' />
                                        </div>
                                    </div>
                                    <h2>
                                        Email Sending and Delivery
                                    </h2>
                                    <p>
                                        We ensure Reliable delivery, High deliverability rates, Speed and efficiency, Authentication and verification & Bounce and complaint handling. The success and reputation of an email marketing campaign are directly impacted by the sending and delivery of the email .
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/EmailTemplateandContentCreation.png")} alt="EmailTemplateandContentCreation" title='EmailTemplateandContentCreation' />
                                        </div>
                                    </div>
                                    <h2>
                                        Email Template and Content Creation
                                    </h2>
                                    <p>
                                        We includes Customizable templates, Content writing and editing, Graphic design and multimedia, Mobile-friendliness and responsiveness & Branding consistency. Our Well-designed email templates and content help you to capture subscribers' attention, convey the message effectively, and drive desired actions.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/ListBuildingandGrowth.png")} alt="ListBuildingandGrowth" title='ListBuildingandGrowth' />
                                        </div>
                                    </div>
                                    <h2>
                                        List Building and Growth
                                    </h2>
                                    <p>
                                        It involves expanding and nurturing your email list to increase its size, quality, and engagement. Opt-in forms and sign-up pages, Lead magnets and incentives, Pop-ups and overlays, Segmentation and targeting & List cleaning and maintenance helps in Growing a high-quality email list enables you to reach a larger audience, increase engagement, and drive better campaign results.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/EmailAutomationandWorkflows.png")} alt="EmailAutomationandWorkflows" title='EmailAutomationandWorkflows' />
                                        </div>
                                    </div>
                                    <h2>
                                        Email Automation and Workflows
                                    </h2>
                                    <p>
                                        Automated email sequences are used to nurture leads, personalize interactions, and streamline communication. We're here to help you cut down on time, get more done, and send timely, pertinent messages to your list, enhancing their experience and delivering better outcomes.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/TrackingandAnalyticsReporting.png")} alt="TrackingandAnalyticsReporting" title='TrackingandAnalyticsReporting' />
                                        </div>
                                    </div>
                                    <h2>
                                        Tracking and Analytics Reporting
                                    </h2>
                                    <p>
                                        Tracking and Analytics Reporting includes monitoring and analyzing email campaign performance to measure success and inform future strategies. We helps you to identify strengths, weaknesses, and areas for improvement, enabling data-driven decisions to optimize email marketing campaigns.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/ComplianceandDeliverabilityManagement.png")} alt="ComplianceandDeliverabilityManagement" title='ComplianceandDeliverabilityManagement' />
                                        </div>
                                    </div>
                                    <h2>
                                        Compliance and Deliverability Management
                                    </h2>
                                    <p>
                                        Our team ensures email campaigns adhere to anti-spam laws and best practices, maximizing deliverability and reputation. By prioritizing compliance and deliverability, you can ensure your emails reach their intended audience and drive better campaign results.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Email Marketing </span> Services
                            </h2>
                            <div className='d-flex justify-content-center pt-3'>
                                <p>
                                    iCloudsoft Technology offers the complete solution for engaging and strategic email marketing services. Our aim is to strengthen your online identity, create a dynamic web presence for your business, and establish real connections.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/EmailSendingandDelivery.png")} alt="EmailSendingandDelivery" title='EmailSendingandDelivery' />
                                        </div>
                                    </div>
                                    <h2>
                                        Email Sending and Delivery
                                    </h2>
                                    <p>
                                        We ensure Reliable delivery, High deliverability rates, Speed and efficiency, Authentication and verification & Bounce and complaint handling. The success and reputation of an email marketing campaign are directly impacted by the sending and delivery of the email .
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/EmailTemplateandContentCreation.png")} alt="EmailTemplateandContentCreation" title='EmailTemplateandContentCreation' />
                                        </div>
                                    </div>
                                    <h2>
                                        Email Template and Content Creation
                                    </h2>
                                    <p>
                                        We includes Customizable templates, Content writing and editing, Graphic design and multimedia, Mobile-friendliness and responsiveness & Branding consistency. Our Well-designed email templates and content help you to capture subscribers' attention, convey the message effectively, and drive desired actions.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/ListBuildingandGrowth.png")} alt="ListBuildingandGrowth" title='ListBuildingandGrowth' />
                                        </div>
                                    </div>
                                    <h2>
                                        List Building and Growth
                                    </h2>
                                    <p>
                                        It involves expanding and nurturing your email list to increase its size, quality, and engagement. Opt-in forms and sign-up pages, Lead magnets and incentives, Pop-ups and overlays, Segmentation and targeting & List cleaning and maintenance helps in Growing a high-quality email list enables you to reach a larger audience, increase engagement, and drive better campaign results.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/EmailAutomationandWorkflows.png")} alt="EmailAutomationandWorkflows" title='EmailAutomationandWorkflows' />
                                        </div>
                                    </div>
                                    <h2>
                                        Email Automation and Workflows
                                    </h2>
                                    <p>
                                        Automated email sequences are used to nurture leads, personalize interactions, and streamline communication. We're here to help you cut down on time, get more done, and send timely, pertinent messages to your list, enhancing their experience and delivering better outcomes.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/TrackingandAnalyticsReporting.png")} alt="TrackingandAnalyticsReporting" title='TrackingandAnalyticsReporting' />
                                        </div>
                                    </div>
                                    <h2>
                                        Tracking and Analytics Reporting
                                    </h2>
                                    <p>
                                        Tracking and Analytics Reporting includes monitoring and analyzing email campaign performance to measure success and inform future strategies. We helps you to identify strengths, weaknesses, and areas for improvement, enabling data-driven decisions to optimize email marketing campaigns.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/ComplianceandDeliverabilityManagement.png")} alt="ComplianceandDeliverabilityManagement" title='ComplianceandDeliverabilityManagement' />
                                        </div>
                                    </div>
                                    <h2>
                                        Compliance and Deliverability Management
                                    </h2>
                                    <p>
                                        Our team ensures email campaigns adhere to anti-spam laws and best practices, maximizing deliverability and reputation. By prioritizing compliance and deliverability, you can ensure your emails reach their intended audience and drive better campaign results.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Benefits Of Our  <span> Email Marketing </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIDevelopmentBenefits">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/BuildCustomerLoyalty.png")} alt="BuildCustomerLoyalty" title='BuildCustomerLoyalty' />
                                                </div>
                                            </div>
                                            <h2>
                                                Build Customer Loyalty
                                            </h2>
                                            <p>
                                                Here, we design a web email marketing routine that will enable your business to communicate with customers and build brand loyalty while increasing sales. Campaigns for targeted email marketing not only increase sales but also reduce costs.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/ExpandYourBusinessReach.png")} alt="ExpandYourBusinessReach" title='ExpandYourBusinessReach' />
                                                </div>
                                            </div>
                                            <h2 >
                                                Expand Your Business Reach
                                            </h2>
                                            <p>
                                                Email campaigns have been shown to outperform traditional marketing strategies in terms of reach and engagement potential. Our skilled email marketing team will assist you in reaching your target consumers on any platform while adhering to the CAN-SPAM Act and being non-intrusive.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/ConnectWithDifferentAudiences.png")} alt="ConnectWithDifferentAudiences" title='ConnectWithDifferentAudiences' />
                                                </div>
                                            </div>
                                            <h2>
                                                Connect With Different Audiences
                                            </h2>
                                            <p>
                                                Hyper-personalized communication is one of the many benefits of using B2C and B2B email marketing to reach your audience. While traditional marketing methods focus on delivering a broad message to a large audience, marketing emails allow you to offer unique discounts and codes, personalized messages, and data-driven campaigns.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/SaveTimeAndEffort.png")} alt="SaveTimeAndEffort" title='SaveTimeAndEffort' />
                                                </div>
                                            </div>
                                            <h2>
                                                Save Time and Effort
                                            </h2>
                                            <p>
                                                Targeted email marketing allows you to communicate and engage with your ideal market segments (whether they're a few hundred or a hundred thousand) in a matter of minutes. If you don’t have the time to learn the ropes of email campaign optimization, the best email marketing companies can assist you.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/TestEmailCampaigns.png")} alt="TestEmailCampaigns" title='TestEmailCampaigns' />
                                                </div>
                                            </div>
                                            <h2>
                                                Test Email Campaigns and Drive Strong Results
                                            </h2>
                                            <p>
                                                There are several ways to check if your marketing efforts are hitting the mark with online email marketing. A/B and multivariate testing can be used to test nearly every aspect of your email campaign, including the subject line, send time, and campaign content.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/TrackYourAnalytics.png")} alt="TrackYourAnalytics" title='TrackYourAnalytics' />
                                                </div>
                                            </div>
                                            <h2>
                                                Track Your Analytics
                                            </h2>
                                            <p>
                                                Email marketing software offers data and analytics that provide insightful information about how well your campaigns and focused email marketing initiatives are performing. We can interpret the data and transform it into useful apps to help you contact your audiences more effectively, depending on your marketing objectives.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/BenefitsUp.png")} alt="BenefitsUp" title='BenefitsUp' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "24px" }}>
                                Benefits Of Our <span> Email Marketing </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/BuildCustomerLoyalty.png")} alt="BuildCustomerLoyalty" title='BuildCustomerLoyalty' />
                                        </div>
                                    </div>
                                    <h2>
                                        Build Customer Loyalty
                                    </h2>
                                    <p>
                                        Here, we design a web email marketing routine that will enable your business to communicate with customers and build brand loyalty while increasing sales. Campaigns for targeted email marketing not only increase sales but also reduce costs.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/ExpandYourBusinessReach.png")} alt="ExpandYourBusinessReach" title='ExpandYourBusinessReach' />
                                        </div>
                                    </div>
                                    <h2 >
                                        Expand Your Business Reach
                                    </h2>
                                    <p>
                                        Email campaigns have been shown to outperform traditional marketing strategies in terms of reach and engagement potential. Our skilled email marketing team will assist you in reaching your target consumers on any platform while adhering to the CAN-SPAM Act and being non-intrusive.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/ConnectWithDifferentAudiences.png")} alt="ConnectWithDifferentAudiences" title='ConnectWithDifferentAudiences' />
                                        </div>
                                    </div>
                                    <h2>
                                        Connect With Different Audiences
                                    </h2>
                                    <p>
                                        Hyper-personalized communication is one of the many benefits of using B2C and B2B email marketing to reach your audience. While traditional marketing methods focus on delivering a broad message to a large audience, marketing emails allow you to offer unique discounts and codes, personalized messages, and data-driven campaigns.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/SaveTimeAndEffort.png")} alt="SaveTimeAndEffort" title='SaveTimeAndEffort' />
                                        </div>
                                    </div>
                                    <h2>
                                        Save Time and Effort
                                    </h2>
                                    <p>
                                        Targeted email marketing allows you to communicate and engage with your ideal market segments (whether they're a few hundred or a hundred thousand) in a matter of minutes. If you don’t have the time to learn the ropes of email campaign optimization, the best email marketing companies can assist you.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/TestEmailCampaigns.png")} alt="TestEmailCampaigns" title='TestEmailCampaigns' />
                                        </div>
                                    </div>
                                    <h2>
                                        Test Email Campaigns and Drive Strong Results
                                    </h2>
                                    <p>
                                        There are several ways to check if your marketing efforts are hitting the mark with online email marketing. A/B and multivariate testing can be used to test nearly every aspect of your email campaign, including the subject line, send time, and campaign content.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/TrackYourAnalytics.png")} alt="TrackYourAnalytics" title='TrackYourAnalytics' />
                                        </div>
                                    </div>
                                    <h2>
                                        Track Your Analytics
                                    </h2>
                                    <p>
                                        Email marketing software offers data and analytics that provide insightful information about how well your campaigns and focused email marketing initiatives are performing. We can interpret the data and transform it into useful apps to help you contact your audiences more effectively, depending on your marketing objectives.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-3">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Email Marketing </span> Work
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AIWork'>
                                    <h2>
                                        We made <span> Email Marketing </span> easy & accessible
                                    </h2>
                                    <p>
                                        Using mobile-friendly designs and keeping emails simple and focused improves results. Additionally, leveraging email marketing platforms and tools can streamline the process. By simplifying and optimizing your email marketing strategy, you can save time, increase efficiency, and drive better results. This makes email marketing easy, efficient, and effective for achieving your marketing goals.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/EmailMarketingWork.png")} alt="EmailMarketingWork" title='EmailMarketingWork' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pt-5 pb-5 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-3">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "28px" }}>
                                Our <span> Email Marketing </span> Work
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/EmailMarketingWork.png")} alt="EmailMarketingWork" title='EmailMarketingWork' />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className='AIWork'>
                                    <h2>
                                        We made <span> Email Marketing </span> easy & accessible
                                    </h2>
                                    <p>
                                        Using mobile-friendly designs and keeping emails simple and focused improves results. Additionally, leveraging email marketing platforms and tools can streamline the process. By simplifying and optimizing your email marketing strategy, you can save time, increase efficiency, and drive better results. This makes email marketing easy, efficient, and effective for achieving your marketing goals.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-3 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-2 pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100 EmailMarketing' style={{ borderRadius: "10px" }} src={require("../Img/BusinessWithEmailMarketing2.webp")} alt="BusinessWithEmailMarketing" title='BusinessWithEmailMarketing' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AITechnology'>
                                    <h2>
                                        Transform Your Business With <span> Email Marketing </span>
                                    </h2>
                                    <p>
                                        Transform your business with us by building strong customer relationships, increasing brand awareness, and driving sales. Personalize messages, automate workflows, and analyze performance to maximize ROI. Reach new heights by leveraging email marketing's power to nurture leads, encourage conversions, and foster loyalty, ultimately growing your business.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-2 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg  pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100 EmailMarketing' style={{ borderRadius: "10px", }} src={require("../Img/BusinessWithEmailMarketing2.webp")} alt="BusinessWithEmailMarketing" title='BusinessWithEmailMarketing' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AITechnology'>
                                    <h2>
                                        Transform Your Business With <span> Email Marketing </span>
                                    </h2>
                                    <p>
                                        Transform your business with us by building strong customer relationships, increasing brand awareness, and driving sales. Personalize messages, automate workflows, and analyze performance to maximize ROI. Reach new heights by leveraging email marketing's power to nurture leads, encourage conversions, and foster loyalty, ultimately growing your business.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default EmailMarketing