import React from 'react'
import "./Services.css"
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Services = () => {



    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/services" />
                    <meta name="keywords" content="Services iCloudsoft, Software Development Company in Pune." />
                    <title> Services | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className='TopHeader'>
                                <p className='mt-3'>
                                    <a className="text-decoration-none" href='/'>
                                        <span className='TopHeaderFirst'>
                                            HOME
                                        </span>
                                    </a>
                                    <span className='TopHeaderIcon'>
                                        <i class="fa-sharp fa-solid fa-angle-right"></i>
                                    </span>
                                    <span className='TopHeaderSecond'>
                                        SERVICES
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ServicesFirstSection" style={{ backgroundImage: `url(${require("../Img/Services1.png")})`, backgroundAttachment: 'local', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container-lg p-xl-4 pb-3">
                        <div className='ReasonsHireUs'>
                            <h2>
                                Reasons to Hire Us
                            </h2>
                            <h1>
                                Innovative AI & Software Development Services in Pune
                            </h1>
                        </div>
                        <div className="row g-4  p-xl-2">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='HireUs'>
                                    <h2>
                                        <em>
                                            01.
                                        </em>
                                    </h2>
                                    <h3>
                                        Custom Solutions
                                    </h3>
                                    &nbsp;
                                    <p>
                                        Developing a web project or an application
                                        can stumble into lots of stops and shortcomings
                                        along the way. However, this means that it’s then
                                        that the most skilled coding team manages to handle
                                        the issue and make it work. We are proud to be such
                                        a team!
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='HireUs'>
                                    <h2>
                                        <em>
                                            02.
                                        </em>
                                    </h2>
                                    <h3>
                                        Speedy Workflow
                                    </h3>
                                    &nbsp;
                                    <p>
                                        We personally know a lot of competing companies
                                        where the speedy and agile work style is never
                                        cherished and all the deadlines pass by just as
                                        the team gets stuck behind schedule. We cannot
                                        emphasize enough how different our approach
                                        to timing is!
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='HireUs'>
                                    <h2>
                                        <em>
                                            03.
                                        </em>
                                    </h2>
                                    <h3>
                                        Through Testing
                                    </h3>
                                    &nbsp;
                                    <p>
                                        With the QA and testing department just as extensive
                                        as it is attentive, we’re able to assure you of
                                        the end-quality of our product. This means that
                                        regardless of either the scale or the complexity
                                        of the task at hand, we’re ready to make
                                        it faultless!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container-lg p-xl-4 pb-lg-3 mb-4">
                        <div className='ReasonsHireUs'>
                            <h2>
                                Services
                            </h2>
                            <h6>
                                See which software development services we have to offer you…
                            </h6>
                        </div>
                        <div className="row g-4 p-xl-2 mb-sm-5">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-1.png")} alt="Software Development" title='Software Development' />
                                    <h3>
                                        Software Development
                                    </h3>
                                    &nbsp;
                                    <p>
                                        If you want to transform your Business
                                        process with our standard Customized applications
                                        modernizations, you will be able to streamline
                                        your business process and applications to ensure
                                        efficiency and be more agile with the power
                                        of the New IT.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-2.png")} alt="Application Services" title='Application Services' />
                                    <h3>
                                        Application Services
                                    </h3>
                                    &nbsp;
                                    <p>
                                        Application services include app development,
                                        support, cloud migration, modernization,
                                        integration, security management, and more.
                                        In iCloudsoft brings all-around services to turn
                                        your enterprise applications into max profit.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-3.png")} alt="Blockchain" title='Blockchain' />
                                    <h3>
                                        Blockchain
                                    </h3>
                                    &nbsp;
                                    <p>
                                        The blockchain is a unique distributed record that
                                        stores data and verifies its integrity. By using
                                        a different set of cryptography based technologies,
                                        Blockchain assures that transaction is stable.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-4.png")} alt="QA & Testing" title='QA & Testing' />
                                    <h3>
                                        QA & Testing
                                    </h3>
                                    &nbsp;
                                    <p>
                                        iCloudsoft has built testing expertise in healthcare, manufacturing, retail, wholesale, logistics, and other industries. Our experts can quickly dive into your project and validate every aspect of your software: functionality, integrations, performance, usability, and security.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-5.png")} alt="E-Commerce" title='E-Commerce' />
                                    <h3>
                                        E-Commerce
                                    </h3>
                                    &nbsp;
                                    <p>
                                        We create E-Commerce website with fast page loads, flawless checkout, comprehensive conversation tracking, mind-boggling UX/UI, clear calls-to-action, kickass messaging and definitely design that works on every device is fast-to-market.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-6.png")} alt="Web Development" title='Web Development' />
                                    <h3>
                                        Web Development
                                    </h3>
                                    &nbsp;
                                    <p>
                                        Web development process is centered
                                        around understanding your unique needs and
                                        goals, and creating a website solution that
                                        helps you achieve them .

                                        Web development can range from developing a simple single static page of plain text to complex web applications, electronic businesses, and social network services.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/ServicesPortfolioSectionBg.png")})`, backgroundAttachment: 'local', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }} >
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className='ServiceSection '>
                            <h3 className='' style={{ color: "#2bc0c1" }}>
                                Portfolio
                            </h3>
                            <p>
                                Our Portfolio showcases the breadth of our expertise
                                and the depth of our innovation. Explore how we've
                                partnered with clients to deliver exceptional solutions
                                that drive growth and success.
                                <p>
                                    Ready to embark on your next digital journey?
                                </p>
                            </p>

                            <a className='text-decoration-none' href='/portfolio'>
                                <button className='text-center FirstButton'>
                                    Portfolio
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg p-xl-4 mt-4 pt-4 merriweather-bold">
                    <div className="row mt-lg-3 d-flex justify-content-around g-4">
                        <div className="col-xl-6 col-md-6">
                            <div className="row g-4 d-flex justify-content-center">
                                <div className="col-lg-12">
                                    <div className='d-flex text-center mb-4 pb-lg-4'>
                                        <img className='CoomanImgService' src={require("../Img/ServicePortfolioBottomSection.png")} alt="UX/UI Design" title='UX/UI Design' />
                                        <div className='CommonTextService'>
                                            <h2> UX/UI Design </h2>
                                            <p>
                                                Goals Blended With Colors: We Propel Brands
                                                To A Height With A New Look We no longer live
                                                in the age of the same ho-hum websites that
                                                persistently use the same primary color colors,
                                                exactly two fonts, and retina-burning hyper-links.
                                                We occupied the center of old history and impeded
                                                the heinous UI/UX to craft powerful applications,
                                                with each pixel speaking to the goal and simplicity
                                                of the company.
                                            </p>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-center text-center mb-4 pb-lg-4'>
                                        <img className='CoomanImgService' src={require("../Img/ServicePortfolioBottomSection2.png")} alt="Data Analystics" title='Data Analystics' />
                                        <div className='CommonTextService'>
                                            <h2> Data Analystics </h2>
                                            <p>
                                                Data analytics implies building an infrastructure for data aggregation,
                                                analysis, and reporting. An experienced provider of data analytics services,
                                                iCloudsoft delivers on simple and complex needs with tailored business analytics
                                                solutions. Data analytics is an important part of artificial intelligence.
                                                It expands the business and helps them acquire brand value by making a
                                                data-driven decision.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-md-6">
                            <div className="row g-4 d-flex justify-content-center">
                                <div className="col-lg-12">
                                    <div className='d-flex justify-content-center text-center mb-4 pb-lg-4'>
                                        <img className='CoomanImgService' src={require("../Img/ServicePortfolioBottomSection3.png")} alt="Al & ML" title='Al & ML' />
                                        <div className='CommonTextService'>
                                            <h2> Al & ML </h2>
                                            <p>
                                                Artificial intelligence and machine learning are two emerging
                                                trends in technology. iCloudsoft caters to the prominent AI
                                                and ML to deliver high-end solutions. We provide intelligent
                                                and data-driven insights that help your AI vision become reality.
                                                Our company helps your business enhance and provide image and video,
                                                text-to-speech, business intelligence, data forecasting,
                                                natural language processing, and data analytics.
                                            </p>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-center text-center mb-4 pb-lg-4'>
                                        <img className='CoomanImgService' src={require("../Img/ServicePortfolioBottomSectio4.png")} alt="Internet Of Things" title='Internet Of Things' />
                                        <div className='CommonTextService'>
                                            <h2> Internet Of Things </h2>
                                            <p>
                                                At iCloudsoft, we drive value-centered IoT solutions and
                                                build multi-level data pipelines for that, from edge computing
                                                to cloud data processing and data science. iCloudsoft is a globally
                                                acclaimed IoT app development company in India capable of developing
                                                unique IoT apps that stretch the connected ecosystem of gadgets with
                                                a unique user experience and seamless performance.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='d-flex justify-content-center mb-3 pb-sm-4 '>
                        <a className='text-decoration-none' href='/'>
                            <button className='text-center FirstButton'>
                                VIEW ALL
                            </button>
                        </a>
                    </div> */}
                </div>
            </section>

        </>
    )
}

export default Services