import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './Home'
// import Navbar from './Navbar'
import About from './About'
import Footer from './Footer'
import Services from './Services'
import Portfolio from './Portfolio'
import Error from "./Error"
import Contact from './Contact'
import CallButton from './CallButton'
import TopBottomIcon from './TopBottomIcon'
import Technology from './Technology'
import Solution from './Solution'
import Management from './Management'
import Industries from './Industries'
import Ai from './Ai'
import BlogChain from './BlogChain'
import ArtificeMain from './ArtificeMain'
import Cryptocurrency from './Cryptocurrency'
import VirtualReality from './VirtualReality'
import Web3 from './Web3'
import Mlm from './Mlm'
import Metaverse from './Metaverse'
import DigiatlMarketing from './DigiatlMarketing'
import SocialMedia from './SocialMedia'
import Seo from './Seo'
import EmailMarketing from './EmailMarketing'
import MarketingAutomation from './MarketingAutomation'
import VideoMarketing from './VideoMarketing'
import MobileMarketing from './MobileMarketing'

const Routing = () => {
    return (
        <>

            <React.Fragment>
                <main>
                    <Routes>
                        <Route exact to path='/' element={<Home />} />
                        <Route exact to path='/services' element={<Services />} />
                        <Route exact to path='/technology' element={<Technology />} />
                        <Route exact to path='/digiatlmarketing' element={<DigiatlMarketing />} />

                        <Route exact to path='/socialmedia' element={<SocialMedia />} />
                        <Route exact to path='/seo' element={<Seo />} />
                        <Route exact to path='/emailmarketing' element={<EmailMarketing />} />
                        <Route exact to path='/marketingautomation' element={<MarketingAutomation />} />
                        <Route exact to path='/videomarketing' element={<VideoMarketing />} />
                        <Route exact to path='/mobilemarketing' element={<MobileMarketing />} />

                        <Route exact to path='/ai' element={<Ai />} />
                        <Route exact to path='/solutions' element={<Solution />} />
                        <Route exact to path='/management' element={<Management />} />
                        <Route exact to path='/industries' element={<Industries />} />
                        <Route exact to path='/about' element={<About />} />
                        <Route exact to path='/portfolio' element={<Portfolio />} />
                        <Route exact to path='/contact' element={<Contact />} />

                        <Route exact to path='/artificialintelligence' element={<ArtificeMain />} />
                        <Route exact to path='/blockchain' element={<BlogChain />} />
                        <Route exact to path='/cryptocurrency' element={<Cryptocurrency />} />
                        <Route exact to path='/virtualreality' element={<VirtualReality />} />
                        <Route exact to path='/web3' element={<Web3 />} />
                        <Route exact to path='/mlm' element={<Mlm />} />
                        <Route exact to path='/metaverse' element={<Metaverse />} />

                        <Route exact to path='/callbutton' element={<CallButton />} />
                        <Route exact to path='/topbottomicon' element={<TopBottomIcon />} />

                        <Route exact to path='*' element={<Error />} />
                        
                    </Routes>
                </main>
                <TopBottomIcon />
                <footer>
                    <Footer />
                </footer>
            </React.Fragment>

        </>
    )
}

export default Routing