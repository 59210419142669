import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const MarketingAutomation = () => {
    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/digiatlmarketing/marketingautomation" />
                    <meta name="keywords" content="Marketing Automation iCloudsoft, Software Development Company in Pune." />
                    <title> Marketing Automation | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-5 mt-5 d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className=''>
                                        Marketing Automation
                                    </h3>
                                    <h1>
                                        Seamless Marketing Automation Solutions in Pune
                                    </h1>
                                    <p>
                                        Transform your marketing strategy with our Marketing Automation platform. Whether you're looking to nurture leads, personalize customer interactions, or automate repetitive tasks, our software has got you covered. Gain valuable insights into your audience, track campaign performance, and improve your overall marketing effectiveness. Take your marketing to the next level with our intuitive and user-friendly solution.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <img className='w-100' src={require("../Img/MarketingAuto3.png")} alt="MarketingAuto" title='MarketingAuto' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-2 pb-2 d-flex justify-content-between align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Marketing Automation
                                    </h3>
                                    <h4 className='laila-semibold' style={{ fontSize: "17px", fontWeight: "600", color: "#fff" }}>
                                        Seamless Marketing Automation Solutions in Pune
                                    </h4>
                                    <p>
                                        Transform your marketing strategy with our Marketing Automation platform. Whether you're looking to nurture leads, personalize customer interactions, or automate repetitive tasks, our software has got you covered. Gain valuable insights into your audience, track campaign performance, and improve your overall marketing effectiveness. Take your marketing to the next level with our intuitive and user-friendly solution.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5">
                                <img className='w-100' src={require("../Img/ArtificeMainBg.png")} alt="ArtificeMain" title='ArtificeMain' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-3" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='IntelligenceArtificeMain'>
                            <h2>
                                Our Prime Focus
                            </h2>
                            <div>
                                <p>
                                    Our Marketing Automation platform offers a solution to streamline your marketing efforts. With advanced features and user-friendly interface, you can easily create, manage, and track your marketing campaigns. Increase efficiency and drive better results with our automation tools.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Marketing Automation </span> Services
                            </h2>
                            <div className='d-flex justify-content-center'>
                                <p>
                                    Whether you're a small business or a large corporation, our service is tailored to meet your unique needs and goals. With intuitive tools, real-time tracking, and AI-powered recommendations, you can create personalized experiences for your customers and achieve measurable results.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Sales process
                                    </h2>
                                    <p>
                                        Our Marketing Automation Service is designed your sales process, saving your team valuable time and resources. With automated lead nurturing and email campaigns, you can easily engage with your prospects and convert them into paying customers.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Define Strategy
                                    </h2>
                                    <p>
                                        Our strategic approach focuses on understanding your brand, audience, and industry to create a personalized marketing plan that drives results. By implementing automation tools and analyzing performance metrics, we will help you reach your marketing objectives efficiently and effectively.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Improved Customer Retention
                                    </h2>
                                    <p>
                                        The Improved Customer Retention feature in our Marketing Automation Service utilizes advanced algorithms to analyse customer behaviour and tailor personalized marketing campaigns. Each customer's preferences and purchasing patterns, businesses can engage with their audience more effectively, leading to higher retention rates and increased customer loyalty.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Lead Nurturing
                                    </h2>
                                    <p>
                                        Your marketing efforts and boost your bottom line with our Lead Nurturing in Marketing Automation Service. Nurture leads at every stage of the buyer's journey, from initial contact to conversion. With our robust automation tools, you can deliver personalized messaging, track engagement, and measure results to optimize your campaigns. Drive sales and build lasting customer relationships with our comprehensive solution.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Time Saving Automation
                                    </h2>
                                    <p>
                                        Automating repetitive tasks such as lead nurturing, social media posting, and data analysis, you can focus on creative aspects of your campaigns and watch your productivity soar. With automatic scheduling, personalized email campaigns, and targeted ad placements, you can reach your audience effectively without the hassle of manual tasks.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Everyone in the loop
                                    </h2>
                                    <p>
                                        Stay connected with your team and engaged with your audience with everyone in the Loop marketing automation service. Our platform streamlines communication processes, automates repetitive marketing tasks, and ensures everyone is on the same page, resulting in greater efficiency and productivity for your business.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Marketing Automation </span> Services
                            </h2>
                            <div className='d-flex justify-content-center pt-3'>
                                <p>
                                    Whether you're a small business or a large corporation, our service is tailored to meet your unique needs and goals. With intuitive tools, real-time tracking, and AI-powered recommendations, you can create personalized experiences for your customers and achieve measurable results.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Sales process
                                    </h2>
                                    <p>
                                        Our Marketing Automation Service is designed your sales process, saving your team valuable time and resources. With automated lead nurturing and email campaigns, you can easily engage with your prospects and convert them into paying customers.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Define Strategy
                                    </h2>
                                    <p>
                                        Our strategic approach focuses on understanding your brand, audience, and industry to create a personalized marketing plan that drives results. By implementing automation tools and analyzing performance metrics, we will help you reach your marketing objectives efficiently and effectively.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Improved Customer Retention
                                    </h2>
                                    <p>
                                        The Improved Customer Retention feature in our Marketing Automation Service utilizes advanced algorithms to analyse customer behaviour and tailor personalized marketing campaigns. Each customer's preferences and purchasing patterns, businesses can engage with their audience more effectively, leading to higher retention rates and increased customer loyalty.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Lead Nurturing
                                    </h2>
                                    <p>
                                        Your marketing efforts and boost your bottom line with our Lead Nurturing in Marketing Automation Service. Nurture leads at every stage of the buyer's journey, from initial contact to conversion. With our robust automation tools, you can deliver personalized messaging, track engagement, and measure results to optimize your campaigns. Drive sales and build lasting customer relationships with our comprehensive solution.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Time Saving Automation
                                    </h2>
                                    <p>
                                        Automating repetitive tasks such as lead nurturing, social media posting, and data analysis, you can focus on creative aspects of your campaigns and watch your productivity soar. With automatic scheduling, personalized email campaigns, and targeted ad placements, you can reach your audience effectively without the hassle of manual tasks.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Everyone in the loop
                                    </h2>
                                    <p>
                                        Stay connected with your team and engaged with your audience with everyone in the Loop marketing automation service. Our platform streamlines communication processes, automates repetitive marketing tasks, and ensures everyone is on the same page, resulting in greater efficiency and productivity for your business.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Benefits Of Our  <span> Marketing Automation </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIDevelopmentBenefits">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-gear"></i>
                                            </div>
                                            <h2>
                                                Time Saving
                                            </h2>
                                            <p>
                                                Time-consuming marketing tasks with our Time Saving in Marketing Automation Service increase your team's efficiency and effectiveness by automating processes such as email campaigns, social media scheduling, lead management, and more. Let us help you work smarter, not harder, with our innovative automation solutions.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-brands fa-hive"></i>
                                            </div>
                                            <h2 >
                                                Connect Site Visitors
                                            </h2>
                                            <p>
                                                Transform the way you interact with visitors on your site using our marketing automation service. Personalize user experiences, track customer behavior, and drive engagement with precision-targeted messaging. Increase your ROI and grow your business by leveraging the power of automation to connect seamlessly with your online audience.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-layer-group"></i>
                                            </div>
                                            <h2>
                                                Cost-effective Solutions
                                            </h2>
                                            <p>
                                                Achieve marketing success on a budget with automation service. Our platform offers a range of tools to automate tasks, personalize messaging, and analyse results. Increase efficiency and drive sales with our cost-effective solution tailored to meet your business needs.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                Leads More Effectively
                                            </h2>
                                            <p>
                                                Our Leads More Effectively in Marketing Automation Service is designed to help you in lead generation process and reach your target audience with precision. With our tools and analytics, you can identify the most promising leads and nurture them through the sales funnel with ease.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/BenefitsUp.png")} alt="BenefitsUp" title='BenefitsUp' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "24px" }}>
                                Benefits Of Our <span> Marketing Automation </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Time Saving
                                    </h2>
                                    <p>
                                        Time-consuming marketing tasks with our Time Saving in Marketing Automation Service increase your team's efficiency and effectiveness by automating processes such as email campaigns, social media scheduling, lead management, and more. Let us help you work smarter, not harder, with our innovative automation solutions.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2 >
                                        Connect Site Visitors
                                    </h2>
                                    <p>
                                        Transform the way you interact with visitors on your site using our marketing automation service. Personalize user experiences, track customer behavior, and drive engagement with precision-targeted messaging. Increase your ROI and grow your business by leveraging the power of automation to connect seamlessly with your online audience.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Cost-effective Solutions
                                    </h2>
                                    <p>
                                        Achieve marketing success on a budget with automation service. Our platform offers a range of tools to automate tasks, personalize messaging, and analyse results. Increase efficiency and drive sales with our cost-effective solution tailored to meet your business needs.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Leads More Effectively
                                    </h2>
                                    <p>
                                        Our Leads More Effectively in Marketing Automation Service is designed to help you in lead generation process and reach your target audience with precision. With our tools and analytics, you can identify the most promising leads and nurture them through the sales funnel with ease.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-3">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Marketing Automation </span> Work
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AIWork'>
                                    <h2>
                                        <span> Marketing Automation </span> : Simplified Strategies for Success
                                    </h2>
                                    <p>
                                        Our Marketing Automation Work is designed to your marketing efforts, allowing you to reach your target audience more effectively. With our automated tools, you can personalize your messaging, track customer interactions, and optimize your campaigns for maximum results. Let us help you take your marketing to the next level.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/MarketingAutoWork.png")} alt="MarketingAutoWork" title='MarketingAutoWork' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pt-5 pb-5 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-3">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "28px" }}>
                                Our <span> Marketing Automation </span> Work
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/MarketingAutoWork.png")} alt="MarketingAutoWork" title='MarketingAutoWork' />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className='AIWork'>
                                    <h2>
                                        <span> Marketing Automation </span> : Simplified Strategies for Success
                                    </h2>
                                    <p>
                                        Our Marketing Automation Work is designed to your marketing efforts, allowing you to reach your target audience more effectively. With our automated tools, you can personalize your messaging, track customer interactions, and optimize your campaigns for maximum results. Let us help you take your marketing to the next level.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-3 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-2 pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../Img/AITechnology.png")} alt="AITechnology" title='AITechnology' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AITechnology'>
                                    <h2>
                                        Looking to increase your <span> marketing ROI </span>?
                                    </h2>
                                    <p>
                                        From email campaigns to social media management, our automation services are designed to save you time and boost your ROI. Take your marketing to the next level with our solutions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-2 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg  pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../Img/AITechnology.png")} alt="AITechnology" title='AITechnology' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AITechnology'>
                                    <h2>
                                        Looking to increase your <span> marketing ROI </span>?
                                    </h2>
                                    <p>
                                        From email campaigns to social media management, our automation services are designed to save you time and boost your ROI. Take your marketing to the next level with our solutions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default MarketingAutomation