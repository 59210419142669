import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import "./Home.css"
import Navbar from './Navbar'
import ScrollTrigger from "react-scroll-trigger"
import CountUp from 'react-countup';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Home = () => {

  const [counterOn, setCounterOn] = useState(false);

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoftware.co.in"/>
          <meta name="keywords" content="iCloudsoft Technology is a Best AI & Software Development Company in Pune. We offer Web Design-Development, Software Development, Digital Marketing Services." />
          <title> AI & Software Development Company in Pune | India  </title>
        </Helmet>
      </HelmetProvider>

      <section>
        <div className='container-fluid p-0 HomeContentMain d-md-none d-none d-lg-block MainImg' style={{ backgroundImage: `url(${require("../Img/HomeBgUp.jpg")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top,center' }}>
          <Navbar />
          <div className=' HomeContent'  >
            <h6>We are Capable of Developing</h6>
            <h2>AI PROJECT!</h2>
            <h1>
              Expand Your Business with iCloudSoft Technology's AI Solutions in Pune
              {/* iCloudSoft Technology provides complete AI solutions, from strategy development to <br /> execution, helping businesses across industries */}
            </h1>
            <div className=' pt-4'>
              <NavLink className='' to='/ai' >
                <button className=''> MORE</button>
              </NavLink>
            </div>
          </div>
        </div>

        <div className='container-fluid p-0 HomeContentMain MainImg d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/HomeBgUp.jpg")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top,center' }}>
          <Navbar />
          <div className=' HomeContent'  >
            <h6>We are Capable of Developing</h6>
            <h2>AI PROJECT!</h2>
            <p>
              Expand Your Business with iCloudSoft Technology's AI Solutions in Pune
            </p>
            <div className=''>
              <NavLink className='' to='/contactus' >
                <button className=''> MORE</button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-lg merriweather-bold">
          <div className=' ServicesHome' >
            <h6>Services</h6>
            <h4> Delivering impactful services for growth </h4>
          </div>

          <div className="row g-4 mt-lg-5">
            <div className="col-xl-4 col-md-4">
              <div className="row g-4 ">
                <div className="col-lg-12">
                  <div className='d-xl-flex text-center mb-4'>
                    <i class="fa-solid fa-laptop mt-1 CommonIcon fs-3"></i>
                    <div className='CommonText'>
                      <a className='text-decoration-none' href="/services">
                        <h2> Software Development  </h2>
                      </a>
                      <p>
                        Our custom software development process is collaborative, flexible, and focused on delivering solutions that meet your unique business needs.
                      </p>
                    </div>
                  </div>

                  <div className='d-xl-flex text-center mb-4'>
                    <i class="fa-brands fa-windows mt-1 CommonIcon" ></i>
                    <div className='CommonText'>
                      <a className='text-decoration-none' href="/services">
                        <h2> Application Services </h2>
                      </a>
                      <p>
                        iCloudsoft brings all-around services to turn your enterprise applications into max profit. We are flexible in terms of cooperation.
                      </p>
                    </div>
                  </div>

                  <div className='d-xl-flex text-center mb-4'>
                    <i class="fa-brands fa-bitcoin mt-1 CommonIcon" ></i>
                    <div className='CommonText'>
                      <a className='text-decoration-none' href="/blockchain">
                        <h2> Blockchain </h2>
                      </a>
                      <p>
                        Blockchain is not limited to just currency but enlarges to any domain where anything of value is transacted, be it contracts, personal information, health records, business data and much more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-4">
              <div className='text-center'>
                <img className='text-center w-100' src={require("../Img/MobileImg.png")} alt='MobileImg' title='MobileImg' />
              </div>
            </div>

            <div className="col-xl-4 col-md-4">
              <div className="row g-4 ">
                <div className="col-lg-12">
                  <div className='d-xl-flex text-center mb-4'>
                    <i class="fa-solid fa-comment-dots mt-1 CommonIcon"></i>
                    <div className='CommonText'>
                      <a className='text-decoration-none' href="/services">
                        <h2> QA & Testing </h2>
                      </a>
                      <p>
                        iCloudsoft Technology take full responsibility for the success of an application. to experience positive cooperation with the development team we recommend turning attention to QA & Testing Services.
                      </p>
                    </div>
                  </div>

                  <div className='d-xl-flex text-center mb-4'>
                    <i class="fa-regular fa-cart-shopping mt-1 CommonIcon" ></i>
                    <div className='CommonText'>
                      <a className='text-decoration-none' href="/services">
                        <h2> E-Commerce </h2>
                      </a>
                      <p>
                        We specialize in creating e-commerce solutions that provide a seamless shopping experience for your customers and help you drive sales and growth.
                      </p>
                    </div>
                  </div>

                  <div className='d-xl-flex text-center mb-4'>
                    <i class="fa-regular fa-code mt-1 CommonIcon"></i>
                    <div className='CommonText'>
                      <a className='text-decoration-none' href="/services">
                        <h2> Web Development </h2>
                      </a>
                      <p>
                        Web development process is centered around understanding your unique needs and goals, and creating a website solution that helps you achieve them
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/Tab.jpg")})`, backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }} >
          <div className="row d-flex justify-content-center align-items-center mt-5">
            <div className='ServiceSection '>
              <h3>
                We're Ready!
              </h3>
              <p>
                Whatever Specific Type of App or Project You Want Us to Take On
              </p>
              <a className='text-decoration-none' href='/services'>
                <button className='text-center FirstButton'>
                  MORE
                </button>
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a className='text-decoration-none' href='/about'>
                <button className='text-center SecondButton'>
                  START A JOURNEY
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid MobileUse" style={{ backgroundImage: `url(${require("../Img/MobileUse.png")})`, backgroundAttachment: 'local', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: "top" }} >
          <div className="container">
            <div className="row g-4 d-flex justify-content-around align-items-center merriweather-bold">
              <div className="col-lg-6">
                <div className="row g-4 d-flex justify-content-around align-items-center mt-lg-5">
                  <div className='text-start mb-4 mt-lg-5' >
                    <div className='' style={{ paddingBottom: "16px" }} >
                      <h6 className='laila-bold' style={{ fontSize: "45px", lineHeight: "1.2em", color: "#333e52", fontWeight: "600" }} > Software Development </h6>
                    </div>
                    <h4 className='laila-bold mb-4 me-lg-5' style={{ fontSize: "18px", lineHeight: "1.6", color: "#151515" }} >
                      With our all-inclusive AI & software development solutions, embrace the future. Our specialty is the development of clever software programs that make use of modern artificial intelligence algorithms. We allow businesses to boost user experiences, streamline processes, and maintain an advantage in the constantly shifting digital landscape of today. Our offerings range from predictive analytics to automation powered by machine learning. Join together with us to leverage AI's potential for more intelligent, effective solutions customised to your specific needs.
                    </h4>
                    <a className='text-decoration-none ' href='/solutions'>
                      <button className='text-center FirstButton'>
                        VIEW
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container-fluid' style={{ backgroundImage: `url(${require("../Img/CountUpSection.png")})`, backgroundAttachment: 'local', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: "top", height: "100%" }} >
          <div className="container p-lg-5 merriweather-bold" >
            <div className='text-center ConuntUpSection'>
              <h2>
                Over the last six years, we’ve completed
              </h2>
              <h5>
                a multitude of complex projects!
              </h5>
            </div>
            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
              <div className="row g-4 pb-lg-4  d-flex justify-content-center align-items-center  text-center">
                <div className="col-xl-3 col-md-6 col-sm-6 d-flex justify-content-center align-items-center">
                  <div>
                    <div className='pt-4 ms-3 CounterFirst' > {counterOn && <CountUp start={0} end={10000} duration={2} delay={0}> </CountUp>}  </div>
                    <p className=' mt-4' style={{ fontSize: "18px", lineHeight: "2", textTransform: "capitalize", color: "#fff", fontWeight: "700" }}>Cases completed</p>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6 col-sm-6 d-flex justify-content-center align-items-center">
                  <div>
                    <div className='laila-semibold pt-4 ms-3 text-center' style={{ fontSize: "45px", color: "#fff", border: "3px solid #fff", borderRadius: "50%", width: "130px", height: "130px" }}> {counterOn && <CountUp start={0} end={315} duration={2} delay={0}> </CountUp>}  </div>
                    <p className=' mt-4' style={{ fontSize: "18px", lineHeight: "2", textTransform: "capitalize", color: "#fff", fontWeight: "700" }}>Consultants</p>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6 col-sm-6 d-flex justify-content-center align-items-center">
                  <div >
                    <div className='laila-semibold pt-4 ms-3' style={{ fontSize: "45px", color: "#fff", border: "3px solid #fff", borderRadius: "50%", width: "130px", height: "130px" }}> {counterOn && <CountUp start={0} end={170} duration={2} delay={0}> </CountUp>} </div>
                    <p className=' mt-4' style={{ fontSize: "18px", lineHeight: "2", textTransform: "capitalize", color: "#fff", fontWeight: "700" }}>Project completed </p>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6 col-sm-6 d-flex justify-content-center align-items-center">
                  <div>
                    <div className='laila-semibold pt-4 ms-3' style={{ fontSize: "45px", color: "#fff", border: "3px solid #fff", borderRadius: "50%", width: "130px", height: "130px" }}> {counterOn && <CountUp start={0} end={215} duration={2} delay={0}> </CountUp>}</div>
                    <p className=' mt-4' style={{ fontSize: "18px", lineHeight: "2", textTransform: "capitalize", color: "#fff", fontWeight: "700" }}>Satisfied customers</p>
                  </div>
                </div>
              </div>
            </ScrollTrigger>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container-lg p-xl-4 merriweather-bold">
            <div className='text-center WhatMakes pb-lg-5'>
              <h5>
                What Makes Us Special
              </h5>
            </div>

            <div className="row d-flex justify-content-around  g-4 mb-lg-5 pb-3">

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className=' AboutUsTeam'>
                  <h4>
                    iCloudsoft Technology is a self-made company
                    dedicated to providing the industry’s most
                    effective software and web development,
                    ensuring high client satisfaction, delivering
                    results, and investing profits in client and
                    employee success. We lead with creativity and
                    think strategically; we build an execution process
                    and give it our best work. Investing in software
                    or technology that can automate tasks.
                  </h4>
                  <h4>
                    We have a great portfolio of successful projects,
                    showing our experience and advanced problem-solving
                    skills. We also use the latest technologies to help
                    to prevent our clients data. So, if you need help
                    with your custom software project, let’s talk about
                    how we can bring your idea into reality
                  </h4>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                <img className='text-center w-100 ' src={require("../Img/CEO.png")} alt='CEO' title='CEO' />
                <div className='mt-2 AboutUsTeam '>
                  <h2>
                    ANIL KADAM
                  </h2>
                  <h4 className='text-center'>
                    CEO / FOUNDER
                  </h4>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#f2f3f5" }}>
          <div className='text-center PortfolioSection'>
            <h2>
              Our Portfolio
            </h2>
            <h5>
              Explore our portfolio and see how we can bring your ideas to life.
            </h5>
          </div>

          <div className="container pb-3">
            <div className="row g-4 d-flex justify-content-center align-items-center pb-5">
              <div className="col-xl-4 col-md-6 col-sm-6">
                <NavLink className='text-decoration-none' to='https://gavseva.icloudsoftdigital.com/'>
                  <div className="image HoverEffect ">
                    <img className='' src={require("../Img/slider1.png")} alt='Digital India' title='Digital India' />
                    <div className='content'>
                      <h5> Digital India </h5>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div className="col-xl-4 col-md-6 col-sm-6">
                <NavLink className='text-decoration-none' to='https://education.icloudsoftdigital.com/'>
                  <div className="image HoverEffect ">
                    <img className='' src={require("../Img/HighCanvasLMS2.png")} alt='Education' title='Education' />
                    <div className='content'>
                      <h5 className=''> Education </h5>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div className="col-xl-4 col-md-6 col-sm-6">
                <NavLink className='text-decoration-none' to='https://luxusrycar.icloudsoftdigital.com/'>
                  <div className="image HoverEffect ">
                    <img className='' src={require("../Img/HomeUpdateBg.png")} alt='Automobile' title='Automobile' />
                    <div className='content'>
                      <h5> Automobile  </h5>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div className="col-xl-4 col-md-6 col-sm-6">
                <NavLink className='text-decoration-none' to='https://pashumitra.icloudsoftdigital.com/'>
                  <div className="image HoverEffect ">
                    <img className='' src={require("../Img/PS1.png")} alt='Veteranary' title='Veteranary' />
                    <div className='content'>
                      <h5> Veteranary </h5>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div className="col-xl-4 col-md-6 col-sm-6">
                <NavLink className='text-decoration-none' to='https://industrial.icloudsoftdigital.com/'>
                  <div className="image HoverEffect ">
                    <img className='' src={require("../Img/cnc3.png")} alt='Industrial and Manufacturing' title='Industrial and Manufacturing' />
                    <div className='content'>
                      <h5> Industrial and Manufacturing </h5>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div className="col-xl-4 col-md-6 col-sm-6">
                <NavLink className='text-decoration-none' to='https://builder.icloudsoftdigital.com/'>
                  <div className="image HoverEffect ">
                    <img className='' src={require("../Img/Silde1.png")} alt='Builder And Developer' title='Builder And Developer' />
                    <div className='content'>
                      <h5> Builder And Developer </h5>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid pb-3">
          <div className="container-lg p-xl-4 mb-lg-5  merriweather-bold">
            <div className='text-center TeamSection'>
              <h2>
                Packages & Pricing
              </h2>
              <h5>
                Pricing section for your product
              </h5>
            </div>

            <div className="row g-0">
              <div className="col-xl-4 col-md-4">
                <div className='PricingTableMain'>
                  <div className='mt-2 p-3 '>
                    <h2>
                      Database <br />
                      Management
                    </h2>
                  </div>

                  <hr className='DividerCommon'></hr>

                  <div className='mt-2 pt-3 '>
                    <div className='mb-3 pb-3'>
                      <p>
                        <span>
                          <i class="fa-solid fa-indian-rupee-sign"></i>
                        </span>
                        15000
                      </p>
                      <h6>
                        starting at
                      </h6>
                    </div>
                    <div className='d-flex justify-content-center align-items-center mb-0 pb-0'  >
                      <div style={{ borderBottom: "4px solid #2bc0c1", width: "15%" }}>
                      </div>
                    </div>
                  </div>

                  <hr className='DividerCommon'></hr>

                  <div className='p-3'>
                    <h5 className='mt-3'>
                      Working with your website's or your application's backend architecture is just as important...
                    </h5>
                    <div className='d-flex justify-content-center mb-3 '>
                      <NavLink className='text-decoration-none' to='http://wa.me/9527468898'>
                        <button className='text-center FirstButton'>
                          PURCHASE
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4">
                <div className='PricingTableMainSecond'>
                  <div className='mt-2 p-3 '>
                    <h2>
                      iOS/MacOS Apps
                    </h2>
                  </div>

                  <hr className='DividerPricingTableMainSecond'></hr>

                  <div className='mt-2 pt-3 '>
                    <div className='mb-3 pb-3'>
                      <p>
                        <span>
                          <i class="fa-solid fa-indian-rupee-sign"></i>
                        </span>
                        60000
                      </p>
                      <h6>
                        starting at
                      </h6>
                    </div>
                    <div className='d-flex justify-content-center align-items-center mb-0 pb-0'  >
                      <div style={{ borderBottom: "4px solid #fff", width: "15%" }}>
                      </div>
                    </div>
                  </div>

                  <hr className='DividerPricingTableMainSecond'></hr>

                  <div className='p-3'>
                    <h5 className='mt-3'>
                      The world of iOS and MacOS applications is front and center of the most of our projects. Working with your website’s or your application’s…
                    </h5>
                    <div className='d-flex justify-content-center mb-3 '>
                      <NavLink className='text-decoration-none' to='http://wa.me/9527468898'>
                        <button className='text-center PricingTableMainSecondButton'>
                          PURCHASE
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4">
                <div className='PricingTableMain'>
                  <div className='mt-2 p-3 '>
                    <h2>
                      Android <br />
                      Applications
                    </h2>
                  </div>

                  <hr className='DividerCommon'></hr>

                  <div className='mt-2 pt-3 '>
                    <div className='mb-3 pb-3'>
                      <p>
                        <span>
                          <i class="fa-solid fa-indian-rupee-sign"></i>
                        </span>
                        60000
                      </p>
                      <h6>
                        starting at
                      </h6>
                    </div>
                    <div className='d-flex justify-content-center align-items-center mb-0 pb-0'  >
                      <div style={{ borderBottom: "4px solid #2bc0c1", width: "15%" }}>
                      </div>
                    </div>
                  </div>

                  <hr className='DividerCommon'></hr>

                  <div className='p-3'>
                    <h5 className='mt-3'>
                      Mobile devices are by far the most dynamic of all enterprise platforms in IT…
                    </h5>
                    <div className='d-flex justify-content-center mb-3 '>
                      <NavLink className='text-decoration-none' to='http://wa.me/9527468898'>
                        <button className='text-center FirstButton'>
                          PURCHASE
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/TestimonialBg.png")})`, backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: "top" }} >
          <div className="container pt-4 pb-lg-5">
            <div className='text-center TestimonialSection'>
              <h2>
                Testimonials
              </h2>
              <h5>
                People just love using our software, hear them out:
              </h5>
            </div>

            <div class="row d-flex justify-content-center align text-white text-center">
              <div class="col-lg-4">
                {/* <div>
                  <img className='rounded shadow-1-strong mb-3 p-1' style={{ width: "200px",height:"80px" }} src={require("../Img/perennialLogo1.png")} alt='Testimonial' title='Testimonial' />
                </div> */}
                <i class="fas fa-quote-left mb-3" style={{ color: "#2bc0c1", fontSize: "40px" }}></i>
                <h6 class="text-white" style={{ fontSize: "15px", lineHeight: "1.3", fontWeight: "200" }}>
                  Icloudsoft team communication and support throughout
                  the entire project have been outstanding, always answering
                  our questions promptly and offering valuable insights to help
                  us make informed decisions.
                </h6>

                &nbsp;
                <p class="merriweather-bold pb-3" style={{ fontSize: "24px", color: "#2bc0c1", lineHeight: "1.2", fontWeight: "600" }}>
                  — Perennial Technologies Pvt Ltd
                </p>
              </div>

              <div class="col-lg-4">
                {/* <div>
                  <img className='rounded shadow-1-strong mb-4' style={{ width: "200px",height:"70px" }} src={require("../Img/KanBiosys.png")} alt='Testimonial' title='Testimonial' />
                </div> */}
                <i class="fas fa-quote-left mb-3" style={{ color: "#2bc0c1", fontSize: "40px" }}></i>
                <h6 class="text-white" style={{ fontSize: "16px", lineHeight: "1.3", fontWeight: "200" }}>
                  Dedication to excellence and commitment to customer
                  satisfaction make them a standout option in the industry.
                </h6>

                &nbsp;
                <p class="merriweather-bold pb-3" style={{ fontSize: "24px", color: "#2bc0c1", lineHeight: "1.2", fontWeight: "600" }}>
                  — Kan Biosys Pvt Ltd.
                </p>
              </div>

              <div class="col-lg-4">
                {/* <div>
                  <img className='rounded shadow-1-strong mb-4' style={{ width: "200px",height:"70px" }} src={require("../Img/Nccs.png")} alt='Testimonial1' title='Testimonial1' />
                </div> */}
                <i class="fas fa-quote-left mb-3" style={{ color: "#2bc0c1", fontSize: "40px" }}></i>
                <h6 class="text-white" style={{ fontSize: "15px", lineHeight: "1.3", fontWeight: "200" }}>
                  icloudsoft technology was professional, responsive,
                  and approachable. The level of detail and innovation
                  in their work truly sets them apart from the rest.
                </h6>

                &nbsp;
                <p class="merriweather-bold pb-3" style={{ fontSize: "24px", color: "#2bc0c1", lineHeight: "1.2", fontWeight: "600" }}>
                  — NCCS
                </p>
              </div>


            </div>

          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#f2f3f5" }}>
          <div className="container p-xl-4 merriweather-bold">
            <div className='text-center TeamSection'>
              <h2>
                Place an Order
              </h2>
              <h5>
                I would like to discuss
              </h5>
            </div>

            <div className='d-flex justify-content-center'>
              <NavLink className='text-decoration-none ' to='http://wa.me/9527468898'>
                <button className='text-center fs-5 FirstButton'>
                  Send Message
                </button>
              </NavLink>
            </div>

          </div>
        </div>
      </section>

      <section className="whatsApp">
        <a href="https://wa.me/+919527468898?text=">
          <i class="fa-brands fa-whatsapp" ></i>
        </a>
      </section>

    </>
  )
}

export default Home