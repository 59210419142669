import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Industries = () => {
  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoftware.co.in/industries" />
          <meta name="keywords" content="Industries iCloudsoft, Software Development Company in Pune." />
          <title> Industries | AI & Software Development Company in Pune | India </title>
        </Helmet>
      </HelmetProvider>

      <section>
        <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
          <nav className="navbar navbar-expand-lg navbar p-0" >
            <div className="container ">
              <div className='LogoSection'>
                <a className="text-decoration-none" href="/">
                  <h4>
                    <span>I</span>
                    cloudsoft
                  </h4>
                </a>
              </div>
              <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" ></span>
              </button>
              <div className="collapse navbar-collapse " id="navbarSupportedContent">
                <ul className="navbar-nav d-flex justify-content-end ">
                  <li className="nav-item MainPaddingNav" >
                    <a className="nav-link active " aria-current="page" href="/">HOME</a>
                  </li>

                  <li className="nav-item MainPaddingNav">
                    <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                  </li>

                  <li className="nav-item MainPaddingNav">
                    <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                  </li>

                  <li className="nav-item MainPaddingNav">
                    <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                  </li>

                  <li className="nav-item MainPaddingNav">
                    <a className="nav-link " aria-current="page" href="/ai">AI</a>
                  </li>

                  <li className="nav-item dropdown MainPaddingNav">
                    <a className="nav-link" href='/solutions' >
                      SOlUTIONS  <span className=''>
                        <i class="fa-solid fa-angle-down"></i>
                      </span>
                    </a>
                    <ul className="dropdown-menu ">
                      <li className='border-bottom'>
                        <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                      </li>

                      <li className='border-bottom'>
                        <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                      </li>

                      <li className=''>
                        <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                      </li>

                    </ul>
                  </li>

                  <li className="nav-item MainPaddingNav">
                    <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                  </li>

                  <li className="nav-item MainPaddingNav">
                    <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                  </li>

                  <li className="nav-item MainPaddingNav">
                    <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>

        <div className="container-fluid p-0 d-lg-none d-md-block">
          <nav className="navbar navbar-expand-lg p-0 " >
            <div className="container-fluid p-0 ">
              <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                <a className="text-decoration-none" href="/">
                  <h4>
                    <span>I</span>
                    cloudsoft
                  </h4>
                </a>
              </div>
              <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
              </button>
              <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                  </li>

                  <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                    <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                    <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                      <li className='nav-item '>
                        <div className=' '>
                          <div className="row d-flex justify-content-start  fs-6">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                              <a className='text-decoration-none' href="/solutions">
                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                <div className="border-bottom" ></div>
                              </a>
                              <a className='text-decoration-none' href="/management">
                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                <div className="border-bottom" ></div>
                              </a>
                              <a className='text-decoration-none' href="/industries">
                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                  </li>

                </ul>
              </div>
            </div>
          </nav>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className='TopHeader'>
                <p className='mt-3'>
                  <a className="text-decoration-none" href='/'>
                    <span className='TopHeaderFirst'>
                      HOME
                    </span>
                  </a>
                  <span className='TopHeaderIcon'>
                    <i class="fa-sharp fa-solid fa-angle-right"></i>
                  </span>
                  <span className='TopHeaderSecond'>
                    INDUSTRIES
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container-lg p-xl-4 pb-lg-3">
            <div className='ReasonsHireUs'>
              <h2>
                Industries
              </h2>
              <h1>
                Leading Industry Solutions in AI & Software Development Pune
              </h1>
            </div>
            <div className="row g-4  p-xl-2 mb-sm-5">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='ServicesSection'>
                  <img src={require("../Img/ServicesSection3-1.png")} alt="HealthCare" title='HealthCare' />
                  <h3>
                    HealthCare
                  </h3>
                  &nbsp;
                  <p>
                    Health care is the improvement of health via the prevention, diagnosis, treatment, amelioration, or cure of disease, illness, injury, and other physical and mental impairments in people. In healthcare, iCloudsoft offers full-cycle IT services for medical organizations to leverage tech-enabled healthcare without expanding an in-house IT team.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='ServicesSection'>
                  <img src={require("../Img/ServicesSection3-2.png")} alt="Banking" title='Banking' />
                  <h3>
                    Banking ,Financial Services & Insurance
                  </h3>
                  &nbsp;
                  <p>
                    Financial software development services are aimed
                    to help companies from banking, financial services,
                    and insurance industries increase operational efficiency,
                    improve customer engagement and retention, and leverage
                    new, more profitable business models.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='ServicesSection'>
                  <img src={require("../Img/ServicesSection3-3.png")} alt="Manufacturing" title='Manufacturing' />
                  <h3>
                    Manufacturing
                  </h3>
                  &nbsp;
                  <p>
                    iCloudsoft offers robust solutions and related
                    services to support and modernize your enterprise’s
                    operations and ensure increased efficiency of
                    operations and optimized business costs,
                    all without IT budget overruns.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='ServicesSection'>
                  <img src={require("../Img/ServicesSection3-4.png")} alt="Professional" title='Professional' />
                  <h3>
                    Professional Services
                  </h3>
                  &nbsp;
                  <p>
                    IT solutions for professional services allow businesses
                    to digitalize service delivery and automate internal
                    operations for increased productivity and a more
                    competitive advantage in their industry.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='ServicesSection'>
                  <img src={require("../Img/ServicesSection3-5.png")} alt="Retail" title='Retail' />
                  <h3>
                    Retail
                  </h3>
                  &nbsp;
                  <p>
                    Retail IT services focus on achieving higher efficiency
                    in business operations and creating truly customer-centric
                    experience for digital and physical sales channels.
                    With of domain experience, our retail IT consultants
                    offer custom software solutions to the entire
                    retail ecosystem.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='ServicesSection'>
                  <img src={require("../Img/ServicesSection3-6.png")} alt="Transportation" title='Transportation' />
                  <h3>
                    Transportation and Logistics
                  </h3>
                  &nbsp;
                  <p>
                    iCloudsoft has provided tailored IT solutions
                    and dedicated services for the transportation
                    and logistics (T&L) industry, which propel digital
                    transformation across business management
                    and all major T&L activities.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='ServicesSection'>
                  <img src={require("../Img/ServicesSection3-1.png")} alt="Oil" title='Oil' />
                  <h3>
                    Oil & Gas
                  </h3>
                  &nbsp;
                  <p>
                    iCloudsoft offers IT solutions for the oil and gas
                    industry by employing cloud, IIoT, big data,
                    advanced analysis, virtual reality (VR),
                    and augmented reality (AR). We will be happy
                    to provide you with a tailored IT solution for
                    improved oil and gas exploration and
                    production processes and data-driven decision-making.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Industries