import React from 'react'
// import Navbar from './Navbar'

const Error = () => {
    return (

        <>
        
        {/* <div className='' style={{backgroundColor:"#202734"}}>
        <Navbar/>
        </div> */}

        <div className='bg-light text-center ' style={{ height: "50vh" }}>
            <div className="container p-5">
                <div className="row error-container">
                    <div className="col-md-12 text-center">
                        <h6 className="error-heading fs-1">404</h6>
                        <p className="error-text">Oops! The page you are looking for could not be found.</p>
                        <a className="text-decoration-none btn btn-lg btn-back fs-2 bg-info" href="/" >Go Back</a>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}

export default Error