import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const SocialMedia = () => {
    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/digiatlmarketing/socialmedia" />
                    <meta name="keywords" content="Social Media iCloudsoft, Software Development Company in Pune." />
                    <title> Social Media | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-5 mt-5 d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className=''>
                                        Social Media Marketing
                                    </h3>
                                    <h1>
                                        Expert Social Media Marketing Services in Pune
                                    </h1>
                                    <p>
                                        Our key objective in social media marketing at iCloudsoft Technology is to build networks that are focused around improving branding excellence. We are well aware of the influence building an efficient and vibrant community has on those we serve.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <img className='w-100' src={require("../Img/Smm1Up.png")} alt="Smm1Up" title='Smm1Up' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-2 pb-2 d-flex justify-content-between align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Social Media Marketing
                                    </h3>
                                    <h4 className='laila-semibold' style={{ fontSize: "17px", fontWeight: "600", color: "#fff" }}>
                                        Expert Social Media Marketing Services in Pune
                                    </h4>
                                    <p>
                                        Our key objective in social media marketing at iCloudsoft Technology is to build networks that are focused around improving branding excellence. We are well aware of the influence building an efficient and vibrant community has on those we serve.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5">
                                <img className='w-100' src={require("../Img/Smm1Up.png")} alt="Smm1Up" title='Smm1Up' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-3" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='IntelligenceArtificeMain'>
                            <h2>
                                Our Prime Focus
                            </h2>
                            <div>
                                <p>
                                    In a world where paid reach is prevalent, icloudsoft technology sets itself apart with its expertly crafted Social Media Marketing strategies. Our deep-seated expertise enables us to prioritize organic reach, ensuring exceptional engagement while upholding brand values and tone.
                                </p>
                                <p>
                                    Although organic social media reach can be challenging for brands, the solution lies in employing the correct approach. We produce content that resonates with diverse audiences across different platforms, ensuring superior results.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Social Media </span> Services
                            </h2>
                            <div className='d-flex justify-content-center'>
                                <p>
                                    iCloudsoft Technology offers the complete solution for engaging and strategic Social Media Management services. Our aim is to strengthen your online identity, create a dynamic web presence for your business, and establish real connections.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/AccountSetup.png")} alt="AccountSetup" title='AccountSetup' />
                                        </div>
                                    </div>
                                    <h2>
                                        Account Setup
                                    </h2>
                                    <p>
                                        Develop and enhance social media profiles on appropriate channels, ensuring that they incorporate business information methodically, optimize acronyms, and uphold consistent branding.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/SocialMediaStrategy.png")} alt="SocialMediaStrategy" title='SocialMediaStrategy' />
                                        </div>
                                    </div>
                                    <h2>
                                        Social Media Strategy
                                    </h2>
                                    <p>
                                        To attract maximum engagement and accomplish your business objectives, design a personalized roadmap that details your goals, target audience, content themes, and techniques.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/Contentcreationanddistribution1.png")} alt="Contentcreationanddistribution1" title='Contentcreationanddistribution1' />
                                        </div>
                                    </div>
                                    <h2>
                                        Content creation and distribution
                                    </h2>
                                    <p>
                                        To boost visibility and engagement, create highly specialized, high-quality content that appeals to the audience you want and distribute it effectively all throughout social media platforms.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/SearchAudit.png")} alt="SearchAudit" title='SearchAudit' />
                                        </div>
                                    </div>
                                    <h2>
                                        Social media audit
                                    </h2>
                                    <p>
                                        Detailed examination of your present social media profile to identify areas of strength, weakness, and growth and development, offering valuable insight for a successful strategy.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/CommunityEngagement.png")} alt="CommunityEngagement" title='CommunityEngagement' />
                                        </div>
                                    </div>
                                    <h2>
                                        Community Engagement
                                    </h2>
                                    <p>
                                        To create a favorable brand image, cultivate and interact with your social media audience by building relationships, encouraging conversation, responding inquiries, and resolving concerns.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/AnalyticsandReporting.png")} alt="AnalyticsandReporting" title='AnalyticsandReporting' />
                                        </div>
                                    </div>
                                    <h2>
                                        Analytics and Reporting
                                    </h2>
                                    <p>
                                        Create choices based on data to optimize SMM efforts through employing advanced analytics to monitor customer behavior, examine goals and objectives, and gain insights.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Social Media </span> Services
                            </h2>
                            <div className='d-flex justify-content-center pt-3'>
                                <p>
                                    iCloudsoft Technology offers the complete solution for engaging and strategic Social Media Management services. Our aim is to strengthen your online identity, create a dynamic web presence for your business, and establish real connections.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/AccountSetup.png")} alt="AccountSetup" title='AccountSetup' />
                                        </div>
                                    </div>
                                    <h2>
                                        Account Setup
                                    </h2>
                                    <p>
                                        Develop and enhance social media profiles on appropriate channels, ensuring that they incorporate business information methodically, optimize acronyms, and uphold consistent branding.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/SocialMediaStrategy.png")} alt="SocialMediaStrategy" title='SocialMediaStrategy' />
                                        </div>
                                    </div>
                                    <h2>
                                        Social Media Strategy
                                    </h2>
                                    <p>
                                        To attract maximum engagement and accomplish your business objectives, design a personalized roadmap that details your goals, target audience, content themes, and techniques.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/Contentcreationanddistribution1.png")} alt="Contentcreationanddistribution1" title='Contentcreationanddistribution1' />
                                        </div>
                                    </div>
                                    <h2>
                                        Content creation and distribution
                                    </h2>
                                    <p>
                                        To boost visibility and engagement, create highly specialized, high-quality content that appeals to the audience you want and distribute it effectively all throughout social media platforms.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/SearchAudit.png")} alt="SearchAudit" title='SearchAudit' />
                                        </div>
                                    </div>
                                    <h2>
                                        Social media audit
                                    </h2>
                                    <p>
                                        Detailed examination of your present social media profile to identify areas of strength, weakness, and growth and development, offering valuable insight for a successful strategy.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/CommunityEngagement.png")} alt="CommunityEngagement" title='CommunityEngagement' />
                                        </div>
                                    </div>
                                    <h2>
                                        Community Engagement
                                    </h2>
                                    <p>
                                        To create a favorable brand image, cultivate and interact with your social media audience by building relationships, encouraging conversation, responding inquiries, and resolving concerns.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/AnalyticsandReporting.png")} alt="AnalyticsandReporting" title='AnalyticsandReporting' />
                                        </div>
                                    </div>
                                    <h2>
                                        Analytics and Reporting
                                    </h2>
                                    <p>
                                        Create choices based on data to optimize SMM efforts through employing advanced analytics to monitor customer behavior, examine goals and objectives, and gain insights.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Benefits Of Our  <span> Social Media Marketing </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIDevelopmentBenefits">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/Improveyourbrand.png")} alt="Improveyourbrand" title='Improveyourbrand' />
                                                </div>
                                            </div>
                                            <h2>
                                                Improve your brand
                                            </h2>
                                            <p>
                                                social media marketing can have a positive impact on your brand. It builds authority, recognition, trust, and loyalty while aiding in showing your business. You can increase the reliability and dependability of your brand by producing material that features actual customers of your product in addition to the people who founded it.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/EngageAudiance.png")} alt="EngageAudiance" title='EngageAudiance' />
                                                </div>
                                            </div>
                                            <h2 >
                                                Engage the audience
                                            </h2>
                                            <p>
                                                Social media offers companies the chance to engage, respond to posts, share user-generated content, and follow up with dissatisfied customers. Unlike traditional media like radio, television, newspapers, and magazines, social media allows customers and businesses to interact.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/Promotecustomerservice.png")} alt="Promotecustomerservice" title='Promotecustomerservice' />
                                                </div>
                                            </div>
                                            <h2>
                                                Promote customer service
                                            </h2>
                                            <p>
                                                Social media is another great way to connect. A well-timed and thoughtful reply shows your broader audience that you’re listening to feedback and responding. Providing a resolution to an upset customer not only increases the chance of that customer shopping with your company again — it also shows other consumers the type of service you offer, building trust and loyalty.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/ImproveSERPpresence.png")} alt="ImproveSERPpresence" title='ImproveSERPpresence' />
                                                </div>
                                            </div>
                                            <h2>
                                                Improve SERP presence
                                            </h2>
                                            <p>
                                                Google is constantly pulling information from social media platforms — such as Instagram images, Facebook pages, and tweets — into its search engine results. By successfully marketing your brand on social media, you increase your SERP presence as well. Keeping an eye on what’s new and trending can help you cater your social content to the needs of the public while adding your brand’s unique flair.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/Boostwebsitetraffic.png")} alt="Boostwebsitetraffic" title='Boostwebsitetraffic' />
                                                </div>
                                            </div>
                                            <h2>
                                                Boost website traffic
                                            </h2>
                                            <p>
                                                Social media content helps with increasing your company website’s traffic. By posting high-quality content from your website or blog to your social media platforms, you can easily attract readers each time you publish. Engaging in interactive chats on social media is a great way to get your brand’s name out there, reach new audiences, and demonstrate your skills while directing people to your website.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/Studythecompetition.png")} alt="Studythecompetition" title='Studythecompetition' />
                                                </div>
                                            </div>
                                            <h2>
                                                Study the competition
                                            </h2>
                                            <p>
                                                You can gain insight into where you need to improve and what not to do by looking through your competitors’ social media posts. This can help you get a sense of how they position a product within the market or which trends they’re keying in on.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/BenefitsUp.png")} alt="BenefitsUp" title='BenefitsUp' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "24px" }}>
                                Benefits Of Our <span> AI Development </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/Improveyourbrand.png")} alt="Improveyourbrand" title='Improveyourbrand' />
                                        </div>
                                    </div>
                                    <h2>
                                        Improve your brand
                                    </h2>
                                    <p>
                                        social media marketing can have a positive impact on your brand. It builds authority, recognition, trust, and loyalty while aiding in showing your business. You can increase the reliability and dependability of your brand by producing material that features actual customers of your product in addition to the people who founded it.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/EngageAudiance.png")} alt="EngageAudiance" title='EngageAudiance' />
                                        </div>
                                    </div>
                                    <h2 >
                                        Engage the audience
                                    </h2>
                                    <p>
                                        Social media offers companies the chance to engage, respond to posts, share user-generated content, and follow up with dissatisfied customers. Unlike traditional media like radio, television, newspapers, and magazines, social media allows customers and businesses to interact.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/Promotecustomerservice.png")} alt="Promotecustomerservice" title='Promotecustomerservice' />
                                        </div>
                                    </div>
                                    <h2>
                                        Promote customer service
                                    </h2>
                                    <p>
                                        Social media is another great way to connect. A well-timed and thoughtful reply shows your broader audience that you’re listening to feedback and responding. Providing a resolution to an upset customer not only increases the chance of that customer shopping with your company again — it also shows other consumers the type of service you offer, building trust and loyalty.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/ImproveSERPpresence.png")} alt="ImproveSERPpresence" title='ImproveSERPpresence' />
                                        </div>
                                    </div>
                                    <h2>
                                        Improve SERP presence
                                    </h2>
                                    <p>
                                        Google is constantly pulling information from social media platforms — such as Instagram images, Facebook pages, and tweets — into its search engine results. By successfully marketing your brand on social media, you increase your SERP presence as well. Keeping an eye on what’s new and trending can help you cater your social content to the needs of the public while adding your brand’s unique flair.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/Boostwebsitetraffic.png")} alt="Boostwebsitetraffic" title='Boostwebsitetraffic' />
                                        </div>
                                    </div>
                                    <h2>
                                        Boost website traffic
                                    </h2>
                                    <p>
                                        Social media content helps with increasing your company website’s traffic. By posting high-quality content from your website or blog to your social media platforms, you can easily attract readers each time you publish. Engaging in interactive chats on social media is a great way to get your brand’s name out there, reach new audiences, and demonstrate your skills while directing people to your website.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/Studythecompetition.png")} alt="Studythecompetition" title='Studythecompetition' />
                                        </div>
                                    </div>
                                    <h2>
                                        Study the competition
                                    </h2>
                                    <p>
                                        You can gain insight into where you need to improve and what not to do by looking through your competitors’ social media posts. This can help you get a sense of how they position a product within the market or which trends they’re keying in on.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-3">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Social Media </span> Work
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AIWork'>
                                    <h2>
                                        <span> Social Media Marketing </span> Made Easy & Accessible
                                    </h2>
                                    <p>
                                        We have made Social media marketing easily accessible to you with the help of Widespread internet access, Affordable devices, User-friendly platforms, Free or low-cost advertising options, Availability of analytics tools, Online resources and tutorials, Social media management tools, Increased mobile usage Democratization of content creation & Constant platform updates. These factors have collectively made social media marketing more accessible, allowing businesses and individuals to reach and engage with their target audience more easily.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/SocialMediaWork3.png")} alt="SocialMediaWork3" title='SocialMediaWork3' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pt-5 pb-5 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-3">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "28px" }}>
                                Our <span> Social Media </span> Work
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/SocialMediaWork3.png")} alt="SocialMediaWork3" title='SocialMediaWork3' />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className='AIWork'>
                                    <h2>
                                        <span> Social Media Marketing </span> Made Easy & Accessible
                                    </h2>
                                    <p>
                                        We have made Social media marketing easily accessible to you with the help of Widespread internet access, Affordable devices, User-friendly platforms, Free or low-cost advertising options, Availability of analytics tools, Online resources and tutorials, Social media management tools, Increased mobile usage Democratization of content creation & Constant platform updates. These factors have collectively made social media marketing more accessible, allowing businesses and individuals to reach and engage with their target audience more easily.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-3 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-2 pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../Img/BusinessGrowth.png")} alt="BusinessGrowth" title='BusinessGrowth' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AITechnology'>
                                    <h2>
                                        <span> Grow Your Business </span> With Us
                                    </h2>
                                    <p>
                                        Using strategies designed to stay ahead of the curve and accept the future. Our team of experts continually looks into new trends to maintain your business on the forefront of digital advancement.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-2 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg  pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../Img/BusinessGrowth.png")} alt="BusinessGrowth" title='BusinessGrowth' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AITechnology'>
                                    <h2>
                                        <span> Grow Your Business </span> With Us
                                    </h2>
                                    <p>
                                        Using strategies designed to stay ahead of the curve and accept the future. Our team of experts continually looks into new trends to maintain your business on the forefront of digital advancement.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default SocialMedia