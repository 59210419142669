import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Management = () => {
  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoftware.co.in/management" />
          <meta name="keywords" content="Management iCloudsoft, Software Development Company in Pune." />
          <title> Management | AI & Software Development Company in Pune | India </title>
        </Helmet>
      </HelmetProvider>

      <section>
        <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
          <nav className="navbar navbar-expand-lg navbar p-0" >
            <div className="container ">
              <div className='LogoSection'>
                <a className="text-decoration-none" href="/">
                  <h4>
                    <span>I</span>
                    cloudsoft
                  </h4>
                </a>
              </div>
              <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" ></span>
              </button>
              <div className="collapse navbar-collapse " id="navbarSupportedContent">
                <ul className="navbar-nav d-flex justify-content-end ">
                  <li className="nav-item MainPaddingNav" >
                    <a className="nav-link active " aria-current="page" href="/">HOME</a>
                  </li>

                  <li className="nav-item MainPaddingNav">
                    <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                  </li>

                  <li className="nav-item MainPaddingNav">
                    <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                  </li>

                  <li className="nav-item MainPaddingNav">
                    <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                  </li>

                  <li className="nav-item MainPaddingNav">
                    <a className="nav-link " aria-current="page" href="/ai">AI</a>
                  </li>

                  <li className="nav-item dropdown MainPaddingNav">
                    <a className="nav-link" href='/solutions' >
                      SOlUTIONS  <span className=''>
                        <i class="fa-solid fa-angle-down"></i>
                      </span>
                    </a>
                    <ul className="dropdown-menu ">
                      <li className='border-bottom'>
                        <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                      </li>

                      <li className='border-bottom'>
                        <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                      </li>

                      <li className=''>
                        <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                      </li>

                    </ul>
                  </li>

                  <li className="nav-item MainPaddingNav">
                    <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                  </li>

                  <li className="nav-item MainPaddingNav">
                    <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                  </li>

                  <li className="nav-item MainPaddingNav">
                    <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>

        <div className="container-fluid p-0 d-lg-none d-md-block">
          <nav className="navbar navbar-expand-lg p-0 " >
            <div className="container-fluid p-0 ">
              <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                <a className="text-decoration-none" href="/">
                  <h4>
                    <span>I</span>
                    cloudsoft
                  </h4>
                </a>
              </div>
              <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
              </button>
              <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                  </li>

                  <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                    <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                    <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                      <li className='nav-item '>
                        <div className=' '>
                          <div className="row d-flex justify-content-start  fs-6">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                              <a className='text-decoration-none' href="/solutions">
                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                <div className="border-bottom" ></div>
                              </a>
                              <a className='text-decoration-none' href="/management">
                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                <div className="border-bottom" ></div>
                              </a>
                              <a className='text-decoration-none' href="/industries">
                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                  </li>

                </ul>
              </div>
            </div>
          </nav>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className='TopHeader'>
                <p className='mt-3'>
                  <a className="text-decoration-none" href='/'>
                    <span className='TopHeaderFirst'>
                      HOME
                    </span>
                  </a>
                  <span className='TopHeaderIcon'>
                    <i class="fa-sharp fa-solid fa-angle-right"></i>
                  </span>
                  <span className='TopHeaderSecond'>
                    MANAGEMENT
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container-lg p-xl-4 pb-lg-3 mb-4">
            <div className='ReasonsHireUs'>
              <h2>
                Management
              </h2>
              <h1>
                Expert Management Solutions in AI & Software Development
              </h1>
            </div>

            <div className="row g-4 p-xl-2 mb-sm-5">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='ServicesSection'>
                  <img src={require("../Img/ServicesSection3-1.png")} alt="Document Management" title='Document Management' />
                  <h3>
                    Document Management
                  </h3>
                  &nbsp;
                  <p>
                    Document automation software reduces the drafting time of highly formalized documents (e.g., financial statements, invoices, government forms, and employment contracts) by employing reusable document templates that leverage conditional logic and connected data sources. Document automation software integrates with OCR, DMS, CRM software, etc.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='ServicesSection'>
                  <img src={require("../Img/ServicesSection3-2.png")} alt="Supply Chain Management" title='Supply Chain Management' />
                  <h3>
                    Supply Chain Management
                  </h3>
                  &nbsp;
                  <p>
                    Documentation automation software reduces the drafting
                    time of highly formalized documents by employing reusable
                    document templates that leverage conditional logic and
                    connected data sources. Documentation automation software
                    integrates with OCR, DMS, CRM software, etc.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='ServicesSection'>
                  <img src={require("../Img/ServicesSection3-3.png")} alt="Operations Management" title='Operations Management' />
                  <h3>
                    Operations Management
                  </h3>
                  &nbsp;
                  <p>
                    Operations management is the administration of business activities to accomplish goals, achieve higher productivity, and maximize profitability. Operations management is an area of management concerned with designing and controlling the production process and redesigning business operations in the production of goods or services.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='ServicesSection'>
                  <img src={require("../Img/ServicesSection3-4.png")} alt="Financial Management" title='Financial Management' />
                  <h3>
                    Financial Management
                  </h3>
                  &nbsp;
                  <p>
                    iCloudsoft provides companies in various industries with advisory and practical assistance on the design and implementation of reliable financial management software to improve their corporate finance processes.
                    Digital financial management consulting services aim to help companies drive improvements across corporate financial management processes and target ambitious business goals
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='ServicesSection'>
                  <img src={require("../Img/ServicesSection3-5.png")} alt="Asset Management" title='Asset Management' />
                  <h3>
                    Asset Management
                  </h3>
                  &nbsp;
                  <p>
                    Asset management is the day-to-day running of a wealth portfolio. It is usually headed by an investment manager. The management of assets involves building a portfolio of investments. iCloudsoft designs, develops, implements, and supports EAM solutions for tech-driven asset planning and optimization.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='ServicesSection'>
                  <img src={require("../Img/ServicesSection3-6.png")} alt="Project Management" title='Project Management' />
                  <h3>
                    Project Management
                  </h3>
                  &nbsp;
                  <p>
                    Project management is the application of processes, methods,
                    skills, knowledge and experience to achieve specific project
                    objectives according to the project acceptance criteria within
                    agreed parameters.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Management