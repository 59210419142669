import React from 'react'
import "./VirtualReality.css"
import { Helmet, HelmetProvider } from 'react-helmet-async';


const VirtualReality = () => {
    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/ai/virtualreality" />
                    <meta name="keywords" content="AR/VR/MR/XR iCloudsoft, Software Development Company in Pune." />
                    <title> AR/VR/MR/XR | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-5 mt-5 d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "54px" }}>
                                        VR Development Company
                                    </h3>
                                    <h1>
                                        Revolutionary AR/VR/MR/XR Solutions in AI Development Pune
                                    </h1>
                                    <p>
                                        Top Virtual Reality (VR) Company provide the best Augmented Reality (AR), Virtual Reality (VR), and Mixed Reality (MR) services and solutions. Maximize your business potential with a leading VR Development Company. iCloudsoftt technology leads the way in VR innovation, crafting advanced virtual reality environments that cater to various business sectors. We specialize in empowering brands and tech firms to captivate users within immersive virtual environments, transforming visions into compelling interactive experiences.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-6">
                                <img className='w-100' src={require("../Img/ArtificeMainBg.png")} alt="ArtificeMain" title='ArtificeMain' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-2 pb-2 d-flex justify-content-between align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        VR Development Company
                                    </h3>
                                    <h4 className='laila-semibold' style={{ fontSize: "17px", fontWeight: "600", color: "#fff" }}>
                                        Revolutionary AR/VR/MR/XR Solutions in AI Development Pune
                                    </h4>
                                    <p>
                                        Top Virtual Reality (VR) Company provide the best Augmented Reality (AR), Virtual Reality (VR), and Mixed Reality (MR) services and solutions. Maximize your business potential with a leading VR Development Company. iCloudsoftt technology leads the way in VR innovation, crafting advanced virtual reality environments that cater to various business sectors. We specialize in empowering brands and tech firms to captivate users within immersive virtual environments, transforming visions into compelling interactive experiences.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5">
                                <img className='w-100' src={require("../Img/ArtificeMainBg.png")} alt="ArtificeMain" title='ArtificeMain' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> VR Development </span> Services
                            </h2>
                            <div className='d-flex justify-content-center'>
                                <p>
                                    With extensive expertise in immersive platforms and devices, iCloudsoft Technology is a virtual reality (VR) company. Our team is proficient in crafting augmented reality and virtual reality applications tailored for diverse industries, such as manufacturing and retail, to meet their specific requirements.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Custom VR App Development
                                    </h2>
                                    <p>
                                        We deliver customized immersive solutions that cater to the dynamic needs of the evolving digital landscape. Our versatile VR services span across platforms, enabling 3D modeling, rendering, texturing, and seamless system integration. Our team of specialists will design precise customized solutions to fit your specific needs perfectly.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        VR Game Development
                                    </h2>
                                    <p>
                                        We create intelligent, high-definition visuals with interactive 3D models that run in real-time, delivering the ultimate VR gaming experience. Steer into the hyper-functional aspects of our VR gaming platform with innovative concepts that push the boundaries of imagination in modern gaming.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        VR In IoT & Data Visualization
                                    </h2>
                                    <p>
                                        Our team partners with the potential of augmented and virtual reality in conjunction with IoT in order to provide a fluid experience across numerous devices. Our adept VR application developers facilitate the smooth incorporation of VR headsets and other hardware options to enable real-time data visualization, monitoring, and control.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Augmented Reality (AR) Solutions
                                    </h2>
                                    <p>
                                        Using state-of-the-art AR tools and platforms, our team specializes in developing immersive experiences that seamlessly blend digital content with the physical world. With expertise across various industries, including manufacturing, healthcare, retail, and education, we collaborate closely with clients to implement revolutionary Augmented Reality solutions.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Virtual Reality (VR) Solutions
                                    </h2>
                                    <p>
                                        Our Virtual Reality developers offer scalable and fully supported platforms, ranging from portable VR rigs to multi-user, warehouse-scale 3D projection VR showrooms. We create future-proof VR solutions that allow organizations to visualize unimaginable 3D projection VR displays, backpack VR rigs, VR headsets, and more.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Mixed Reality (MR) Solutions
                                    </h2>
                                    <p>
                                        Using Mixed Reality (MR) technology, our skilled team creates captivating experiences that seamlessly integrate virtual environments with real-world elements, transporting users into hybrid worlds where reality and virtually converge.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Cross-Platform Compatibility
                                    </h2>
                                    <p>
                                        Across smartphones, tablets, PCs, AR glasses, smart TVs, VR headsets, and more, our VR development solutions guarantee seamless performance. They are carefully crafted to be compatible with major platforms (iOS, Android) through a unified codebase.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        VR Simulation Development
                                    </h2>
                                    <p>
                                        Our experts provide VR simulations through the use of Unity, and many other tools that can be experienced anywhere with just a headset. This technology is mainly used in operations and maintenance training, which helps our Warfighters. Anything from integrating an aircraft to an anatomical model is within our capabilities.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> VR Development </span> Services
                            </h2>
                            <div className='d-flex justify-content-center pt-3'>
                                <p>
                                    With extensive expertise in immersive platforms and devices, iCloudsoft Technology is a virtual reality (VR) company. Our team is proficient in crafting augmented reality and virtual reality applications tailored for diverse industries, such as manufacturing and retail, to meet their specific requirements.
                                </p>

                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Custom VR App Development
                                    </h2>
                                    <p>
                                        We deliver customized immersive solutions that cater to the dynamic needs of the evolving digital landscape. Our versatile VR services span across platforms, enabling 3D modeling, rendering, texturing, and seamless system integration. Our team of specialists will design precise customized solutions to fit your specific needs perfectly.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        VR Game Development
                                    </h2>
                                    <p>
                                        We create intelligent, high-definition visuals with interactive 3D models that run in real-time, delivering the ultimate VR gaming experience. Steer into the hyper-functional aspects of our VR gaming platform with innovative concepts that push the boundaries of imagination in modern gaming.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        VR In IoT & Data Visualization
                                    </h2>
                                    <p>
                                        Our team partners with the potential of augmented and virtual reality in conjunction with IoT in order to provide a fluid experience across numerous devices. Our adept VR application developers facilitate the smooth incorporation of VR headsets and other hardware options to enable real-time data visualization, monitoring, and control.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Augmented Reality (AR) Solutions
                                    </h2>
                                    <p>
                                        Using state-of-the-art AR tools and platforms, our team specializes in developing immersive experiences that seamlessly blend digital content with the physical world. With expertise across various industries, including manufacturing, healthcare, retail, and education, we collaborate closely with clients to implement revolutionary Augmented Reality solutions.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Virtual Reality (VR) Solutions
                                    </h2>
                                    <p>
                                        Our Virtual Reality developers offer scalable and fully supported platforms, ranging from portable VR rigs to multi-user, warehouse-scale 3D projection VR showrooms. We create future-proof VR solutions that allow organizations to visualize unimaginable 3D projection VR displays, backpack VR rigs, VR headsets, and more.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Mixed Reality (MR) Solutions
                                    </h2>
                                    <p>
                                        Using Mixed Reality (MR) technology, our skilled team creates captivating experiences that seamlessly integrate virtual environments with real-world elements, transporting users into hybrid worlds where reality and virtually converge.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Cross-Platform Compatibility
                                    </h2>
                                    <p>
                                        Across smartphones, tablets, PCs, AR glasses, smart TVs, VR headsets, and more, our VR development solutions guarantee seamless performance. They are carefully crafted to be compatible with major platforms (iOS, Android) through a unified codebase.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        VR Simulation Development
                                    </h2>
                                    <p>
                                        Our experts provide VR simulations through the use of Unity, and many other tools that can be experienced anywhere with just a headset. This technology is mainly used in operations and maintenance training, which helps our Warfighters. Anything from integrating an aircraft to an anatomical model is within our capabilities.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Workflow In Building <span> VR Platform </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIDevelopmentBenefits">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-gear"></i>
                                            </div>
                                            <h2>
                                                Requirement Analysis
                                            </h2>
                                            <p>
                                                We initially understand the requirements, objectives, and data by gathering all necessary information to lay the foundation for the project.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-brands fa-hive"></i>
                                            </div>
                                            <h2 >
                                                Integration And Testing
                                            </h2>
                                            <p>
                                                We integrate various components and conduct rigorous testing to ensure seamless functionality, performance, and compatibility across devices and platforms.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-layer-group"></i>
                                            </div>
                                            <h2>
                                                Prototyping And Validation
                                            </h2>
                                            <p>
                                                Using wireframes and prototypes, our VR developers create visual representations of the platform to validate its functionality and user interface.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                Deployment And Launch
                                            </h2>
                                            <p>
                                                We deploy the VR services in the intended environment. Our team manages the deployment process to ensure a smooth and successful launch.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                Post-Launch Support And Maintenance
                                            </h2>
                                            <p>
                                                We provide ongoing support and maintenance services to address any issues, implement updates, and optimize performance, ensuring long-term success.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-gears"></i>
                                            </div>
                                            <h2>
                                                Design And Development
                                            </h2>
                                            <p>
                                                Our team has validated prototypes in hand with cutting-edge technologies and tools to bring VR platforms to life with best industry practices.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/BenefitsUp.png")} alt="BenefitsUp" title='BenefitsUp' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "24px" }}>
                                Workflow In Building <span> VR Platform </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Requirement Analysis
                                    </h2>
                                    <p>
                                        We initially understand the requirements, objectives, and data by gathering all necessary information to lay the foundation for the project.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2 >
                                        Integration And Testing
                                    </h2>
                                    <p>
                                        We integrate various components and conduct rigorous testing to ensure seamless functionality, performance, and compatibility across devices and platforms.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Prototyping And Validation
                                    </h2>
                                    <p>
                                        Using wireframes and prototypes, our VR developers create visual representations of the platform to validate its functionality and user interface.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Deployment And Launch
                                    </h2>
                                    <p>
                                        We deploy the VR services in the intended environment. Our team manages the deployment process to ensure a smooth and successful launch.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Post-Launch Support And Maintenance
                                    </h2>
                                    <p>
                                        We provide ongoing support and maintenance services to address any issues, implement updates, and optimize performance, ensuring long-term success.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Design And Development
                                    </h2>
                                    <p>
                                        Our team has validated prototypes in hand with cutting-edge technologies and tools to bring VR platforms to life with best industry practices.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                <span> VR Development </span> For Industries
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/AIIndustries.png")} alt="AIIndustries" title='AIIndustries' />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIIndustries">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-gear"></i>
                                            </div>
                                            <h2>
                                                Automotive
                                            </h2>
                                            <p>
                                                Improve automotive experiences utilizing immersive augmented reality and virtual reality solutions for design processes, training initiatives, and customer interaction.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-brands fa-hive"></i>
                                            </div>
                                            <h2 >
                                                Finance
                                            </h2>
                                            <p>
                                                Use AR/VR to modernize finance, delivering immersive banking experiences, advanced financial analysis, and elevated customer engagement.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-layer-group"></i>
                                            </div>
                                            <h2>
                                                Pharma
                                            </h2>
                                            <p>
                                                Advance pharmaceutical operations through the utilization of augmented reality and virtual reality, covering the entire spectrum from drug development to the training of personnel and the education of patients.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                Manufacturing
                                            </h2>
                                            <p>
                                                Streamline manufacturing processes with AR/VR for design visualization, training, and quality control.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                Defence
                                            </h2>
                                            <p>
                                                Boost defense operations by incorporating AR/VR simulations into training, mission planning, and equipment maintenance.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-gears"></i>
                                            </div>
                                            <h2>
                                                Retail
                                            </h2>
                                            <p>
                                                Reshape retail with seamless AR/VR integration for sales, in-store experiences, and customer interactions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "24px" }}>
                                <span> VR Development </span> For Industries
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIIndustries">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Automotive
                                    </h2>
                                    <p>
                                        Improve automotive experiences utilizing immersive augmented reality and virtual reality solutions for design processes, training initiatives, and customer interaction.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2 >
                                        Finance
                                    </h2>
                                    <p>
                                        Use AR/VR to modernize finance, delivering immersive banking experiences, advanced financial analysis, and elevated customer engagement.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Pharma
                                    </h2>
                                    <p>
                                        Advance pharmaceutical operations through the utilization of augmented reality and virtual reality, covering the entire spectrum from drug development to the training of personnel and the education of patients.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Manufacturing
                                    </h2>
                                    <p>
                                        Streamline manufacturing processes with AR/VR for design visualization, training, and quality control.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Defence
                                    </h2>
                                    <p>
                                        Boost defense operations by incorporating AR/VR simulations into training, mission planning, and equipment maintenance.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Retail
                                    </h2>
                                    <p>
                                        Reshape retail with seamless AR/VR integration for sales, in-store experiences, and customer interactions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-3 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-2 pb-lg-3 mb-4">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Features Of Our <span> Blockchain Development </span>
                            </h2>
                        </div>
                        <div className="row pt-4 g-4 d-flex justify-content-around align-items-center  ">
                            <div className="col-xl-5 col-md-6 ">
                                <div>
                                    <img className='w-100' src={require("../Img/Feature.png")} alt="Feature" title='Feature' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className="row g-4">
                                    <div className="col-lg-12">
                                        <div className='BenefitsOfAI text-start '>
                                            <h2>
                                                Virtual Reality
                                            </h2>
                                            <p>
                                                <div className='row pt-3 text-center g-4 d-flex '>
                                                    <div className='col-lg-6'>
                                                        <div className='AITech'>
                                                            Unreal Engine
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className='AITech'>
                                                            Cry Engine
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className='AITech'>
                                                            Open VR
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className='AITech'>
                                                            Steam VR
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className='AITech'>
                                                            WebXR
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className='AITech'>
                                                            SDKs
                                                        </div>
                                                    </div>
                                                </div>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className='BenefitsOfAI text-start '>
                                            <h2>
                                                Augmented Reality
                                            </h2>
                                            <p>
                                                <div className='row pt-3 text-center g-4 d-flex '>
                                                    <div className='col-lg-6'>
                                                        <div className='AITech'>
                                                            Unity 3D
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className='AITech'>
                                                            Vuforia
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className='AITech'>
                                                            ARKit (for iOS)
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className='AITech'>
                                                            ARCore (for Android)
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className='AITech'>
                                                            Spark AR
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className='AITech'>
                                                            Wikitude
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className='AITech'>
                                                            Immersal
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className='AITech'>
                                                            Zappar
                                                        </div>
                                                    </div>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-2 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg  pb-lg-3 mb-4">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Features Of Our <span> Blockchain Development </span>
                            </h2>
                        </div>
                        <div className="row pt-4 g-4 d-flex justify-content-around align-items-center  ">
                            <div className="col-xl-5 col-md-6 ">
                                <div>
                                    <img className='w-100' src={require("../Img/Feature.png")} alt="Feature" title='Feature' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className="row g-4">
                                    <div className="col-lg-12 col-sm-6">
                                        <div className='BenefitsOfAI text-start '>
                                            <h2>
                                                Virtual Reality
                                            </h2>
                                            <p>
                                                <div className='row pt-3 text-center g-4 d-flex '>
                                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                                        <div className='AITech'>
                                                            Unreal Engine
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                                        <div className='AITech'>
                                                            Cry Engine
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                                        <div className='AITech'>
                                                            Open VR
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                                        <div className='AITech'>
                                                            Steam VR
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                                        <div className='AITech'>
                                                            WebXR
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                                        <div className='AITech'>
                                                            SDKs
                                                        </div>
                                                    </div>
                                                </div>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-6">
                                        <div className='BenefitsOfAI text-start '>
                                            <h2>
                                                Augmented Reality
                                            </h2>
                                            <p>
                                                <div className='row pt-3 text-center g-4 d-flex '>
                                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                                        <div className='AITech'>
                                                            Unity 3D
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                                        <div className='AITech'>
                                                            Vuforia
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                                        <div className='AITech'>
                                                            ARKit (for iOS)
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                                        <div className='AITech'>
                                                            ARCore (for Android)
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                                        <div className='AITech'>
                                                            Spark AR
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                                        <div className='AITech'>
                                                            Wikitude
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                                        <div className='AITech'>
                                                            Immersal
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                                        <div className='AITech'>
                                                            Zappar
                                                        </div>
                                                    </div>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default VirtualReality