import React from 'react'
import "./About.css"
import { Helmet, HelmetProvider } from 'react-helmet-async';

const About = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/about" />
                    <meta name="keywords" content="About iCloudsoft, Software Development Company in Pune" />
                    <title> About Us | AI & Software Development Company in Pune | India  </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className='TopHeader'>
                                <p className='mt-3'>
                                    <a className="text-decoration-none" href='/'>
                                        <span className='TopHeaderFirst'>
                                            HOME
                                        </span>
                                    </a>
                                    <span className='TopHeaderIcon'>
                                        <i class="fa-sharp fa-solid fa-angle-right"></i>
                                    </span>
                                    <span className='TopHeaderSecond'>
                                        ABOUT US
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="container p-xl-4">
                        <div className="row d-flex justify-content-center">
                            <div className="col-xl-12">
                                <img className='w-100' src={require("../Img/AboutUs1.png")} alt="AboutUs" title='AboutUs' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="container p-xl-4">
                        <div className="row d-flex justify-content-center">
                            <div className="col-xl-12">
                                <div className='AboutSecond'>
                                    <h4>
                                        Incorporated in 2018, iCloudsoft brings 6+ years of business experience in delivering software development, cloud solutions, digital solutions, and IT services to a wide range of companies, from entrepreneurs to large business owners, which are significantly widening to meet the business and technology needs of today’s digital environment.
                                    </h4>
                                    <h1>
                                        iCloudsoft Technology has been providing full-cycle, end-to-end software development services. We help companies launch their projects, adopt advanced technologies, switch to digital-first strategies, and grow their businesses.
                                    </h1>
                                    <p className='mt-4'>
                                        We develop innovative and creative products and services that provide total communication and information solutions. iCloudsoft Technologies business philosophy is to assure the highest quality product, total client satisfaction, timely delivery of solutions, and the best quality and price ratio found in the industry.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container-lg p-xl-4 pb-3">
                        <div className='ReasonsHireUs'>
                            <h2>
                                Why We’re the Best?
                            </h2>
                        </div>
                        <div className="row g-4 p-xl-2 ">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='AboutUsHireUs'>
                                    <h2>
                                        <em>
                                            01.
                                        </em>
                                    </h2>
                                    <h3>
                                        95% Customer Satisfaction
                                    </h3>
                                    &nbsp;
                                    <p>
                                        We work on the latest technologies and frameworks to give the user- friendly, scalable, secure solutions that meet any business need.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='AboutUsHireUs'>
                                    <h2>
                                        <em>
                                            02.
                                        </em>
                                    </h2>
                                    <h3>
                                        Flexible Engagement Models
                                    </h3>
                                    &nbsp;
                                    <p>
                                        We provide the flexibility to our clients to choose from recruitment and engagement models that fit of your budget
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='AboutUsHireUs'>
                                    <h2>
                                        <em>
                                            03.
                                        </em>
                                    </h2>
                                    <h3>
                                        Competitive pricing & on time delivery
                                    </h3>
                                    &nbsp;
                                    <p>
                                        We deliver an ideal mix of cost- effective solutions with incredible quality to guarantee the lowest prices in our segment and go to market quickly
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#202734" }}>
                    <div className="container MainProgressSize laila-semibold">
                        <div className="row d-flex justify-content-between ">
                            {/* <div className="col-xl-8">
                                <div className='CommonProgressSize'>
                                    <div className='d-flex justify-content-between align-items-center text-white'>
                                        <span className='ProgressText'>
                                            Projects Completed
                                        </span>
                                        &nbsp;
                                        <span className='ProgressPercentage'>
                                            83%
                                        </span>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar " style={{ width: "83%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='CommonProgressSize'>
                                    <div className='d-flex justify-content-between align-items-center text-white'>
                                        <span className='ProgressText'>
                                            Staff members
                                        </span>
                                        &nbsp;
                                        <span className='ProgressPercentage'>
                                            76%
                                        </span>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar " style={{ width: "76%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='CommonProgressSize'>
                                    <div className='d-flex justify-content-between align-items-center text-white'>
                                        <span className='ProgressText'>
                                            Million Man-hours
                                        </span>
                                        &nbsp;
                                        <span className='ProgressPercentage'>
                                            92%
                                        </span>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar " style={{ width: "92%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-xl-5">
                                <div className='text-white'>
                                    <h2 className='merriweather-bold'>
                                        Mision
                                    </h2>
                                    <p className='' style={{ fontWeight: "400" }}>
                                        As an accurate, intelligent IT Company, iCloudsoft Technology is dedicated to serving innovative, reliable and accessible Software and Web Development with working with the next generation for make the business growth.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-5">
                                <div className='text-white'>
                                    <h2 className='merriweather-bold'>
                                        Vision
                                    </h2>
                                    <p className='' style={{ fontWeight: "400" }}>
                                        To be a leading provider of innovative
                                        cloud services and software solutions,
                                        empowering businesses to thrive in the
                                        digital age.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container-lg p-xl-4 merriweather-bold">
                        <div className='text-center TeamSection'>
                            <h5>
                                Meet The CEO
                            </h5>
                        </div>

                        <div className="row d-flex justify-content-around g-4 mb-lg-5 pb-3">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                <img className='text-center w-100 ' src={require("../Img/CEO.png")} alt='CEO' title='CEO' />
                                <div className='mt-2 AboutUsTeam '>
                                    <h2>
                                        Anil Kadam
                                    </h2>
                                    <h4 className='text-center'>
                                        CEO / founder
                                    </h4>
                                </div>
                            </div>

                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-6">
                                <div className='mt-2 AboutUsTeam'>
                                    <a className='text-decoration-none' href='/'>
                                        <h4>
                                            iCloudsoft Technology is a Software Development Company in Pune offering a comprehensive portfolio of products and services. We provide business consultancy and optimal solutions through our cloud based products.
                                        </h4>
                                        <h4>
                                            Our value proposition includes reduced total cost of ownership, fast and quality computing, high scalability, reduced security risks and superior performance. We help our clients achieve their goals by transforming the shortcomings into business advantages.
                                        </h4>
                                        <h4>
                                            In addition to these goals, iCloudsoft Technology also wants to make sure its customers get the best experience possible when using our products and services. We want to make sure every customer gets exactly what they need from us and nothing more so they can fully enjoy their experience with us and be able to take advantage of all of our amazing product offerings!
                                        </h4>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/ServicesPortfolioSectionBg.png")})`, backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }} >
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className='ABoutUsSection '>
                            <p>
                                Let's talk about your plan
                                to turn it into an awesome
                                DIGITAL PRODUCT
                            </p>
                            <a className='text-decoration-none' href='/contact'>
                                <button className='text-center FirstButton'>
                                    Start Your Project
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container p-sm-5 p-2 ">
                        <div className='FAQ'>
                            <h2>
                                FAQ
                            </h2>
                        </div>
                        <div className="row g-4 mb-lg-4 pb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Q : How much time is required for software development?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>
                                                It's depend on the size and complexity of the project. They are the biggest factors in its overall duration.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Q : What information do you need from us to begin the work?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>
                                                Information such as Company Background, Overview, Requirement, Timeframe and Budget need to be submitted.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Q : Do you provide product support services after the app development is complete?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>
                                                We provide a wide range of services to help clients create the best possible software for their needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default About