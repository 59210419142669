import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Seo = () => {
    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/digiatlmarketing/seo" />
                    <meta name="keywords" content="SEO iCloudsoft, Software Development Company in Pune." />
                    <title> SEO | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-5 mt-5 d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' >
                                        SEO ( Search Engine Optimization )
                                    </h3>
                                    <h1>
                                        Top-Ranked SEO Services in Pune for Business Growth
                                    </h1>
                                    <p>
                                        Our search engine optimization service utilizes the latest industry techniques to increase your website's visibility online. By optimizing your website's content and structure, we help improve your search engine rankings and drive more organic traffic to your site.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <img className='w-100' src={require("../Img/SEO.png")} alt="SEO" title='SEO' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-2 pb-2 d-flex justify-content-between align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        SEO ( Search Engine Optimization )
                                    </h3>
                                    <h4 className='laila-semibold' style={{ fontSize: "17px", fontWeight: "600", color: "#fff" }}>
                                        Top-Ranked SEO Services in Pune for Business Growth
                                    </h4>
                                    <p>
                                        Our search engine optimization service utilizes the latest industry techniques to increase your website's visibility online. By optimizing your website's content and structure, we help improve your search engine rankings and drive more organic traffic to your site.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5">
                                <img className='w-100' src={require("../Img/Smm1Up.png")} alt="SEO" title='SEO' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-3" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='IntelligenceArtificeMain'>
                            <h2>
                                Our Prime Focus
                            </h2>
                            <div>
                                <p>
                                    Take your website to the next level with our search engine optimization service. Increase your online presence, drive more traffic, and boost your conversions with our proven SEO techniques. Our dedicated team will work tirelessly to ensure your website is optimized for search engines, resulting in higher visibility and better performance in the digital landscape.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> SEO </span> Services
                            </h2>
                            <div className='d-flex justify-content-center'>
                                <p>
                                    Our team of experts will analyze your website, identify valuable keywords, and implement proven strategies to boost your search engine rankings. Drive more potential customers to your site and watch your business grow with our SEO solutions.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/OnPageSeo.png")} alt="OnPageSeo" title='OnPageSeo' />
                                        </div>
                                    </div>
                                    <h2>
                                        On Page SEO
                                    </h2>
                                    <p>
                                        From keyword research to content optimization, our team will work diligently to improve your website's SEO performance and drive more qualified traffic to your site.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/OffPageSeo.png")} alt="OffPageSeo" title='OffPageSeo' />
                                        </div>
                                    </div>
                                    <h2>
                                        Off Page SEO
                                    </h2>
                                    <p>
                                        Our off page search engine optimization service focuses on building high-quality backlinks to improve your website's search engine ranking.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/LocalSeo.png")} alt="LocalSeo" title='LocalSeo' />
                                        </div>
                                    </div>
                                    <h2>
                                        Local SEO
                                    </h2>
                                    <p>
                                        From optimizing your website with local keywords to managing your Google My Business listing, we will work with you to increase your online visibility and attract more local customers.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/SubmitSearch1.png")} alt="SubmitSearch1" title='SubmitSearch1' />
                                        </div>
                                    </div>
                                    <h2>
                                        Submit Site to Search Engine
                                    </h2>
                                    <p>
                                        Submitting your website in Search Engine service allows you to effortlessly increase your online presence by ensuring that your website is indexed by all major search engines.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/ContentOptimization.png")} alt="ContentOptimization" title='ContentOptimization' />
                                        </div>
                                    </div>
                                    <h2>
                                        Content Optimization
                                    </h2>
                                    <p>
                                        Improve the effectiveness of your website with our Content Optimization service. We will analyze your content, identify areas for improvement, and implement strategic changes to attract more visitors.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/RankingAndTraffic.png")} alt="RankingAndTraffic" title='RankingAndTraffic' />
                                        </div>
                                    </div>
                                    <h2>
                                        Ranking and Traffic
                                    </h2>
                                    <p>
                                        Our team will work tirelessly to enhance your website's ranking on search engines and drive organic traffic to your site.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> SEO </span> Services
                            </h2>
                            <div className='d-flex justify-content-center pt-3'>
                                <p>
                                    Our team of experts will analyze your website, identify valuable keywords, and implement proven strategies to boost your search engine rankings. Drive more potential customers to your site and watch your business grow with our SEO solutions.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/OnPageSeo.png")} alt="OnPageSeo" title='OnPageSeo' />
                                        </div>
                                    </div>
                                    <h2>
                                        On Page SEO
                                    </h2>
                                    <p>
                                        From keyword research to content optimization, our team will work diligently to improve your website's SEO performance and drive more qualified traffic to your site.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/OffPageSeo.png")} alt="OffPageSeo" title='OffPageSeo' />
                                        </div>
                                    </div>
                                    <h2>
                                        Off Page SEO
                                    </h2>
                                    <p>
                                        Our off page search engine optimization service focuses on building high-quality backlinks to improve your website's search engine ranking.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/LocalSeo.png")} alt="LocalSeo" title='LocalSeo' />
                                        </div>
                                    </div>
                                    <h2>
                                        Local SEO
                                    </h2>
                                    <p>
                                        From optimizing your website with local keywords to managing your Google My Business listing, we will work with you to increase your online visibility and attract more local customers.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/SubmitSearch1.png")} alt="SubmitSearch1" title='SubmitSearch1' />
                                        </div>
                                    </div>
                                    <h2>
                                        Submit Site to Search Engine
                                    </h2>
                                    <p>
                                        Submitting your website in Search Engine service allows you to effortlessly increase your online presence by ensuring that your website is indexed by all major search engines.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/ContentOptimization.png")} alt="ContentOptimization" title='ContentOptimization' />
                                        </div>
                                    </div>
                                    <h2>
                                        Content Optimization
                                    </h2>
                                    <p>
                                        Improve the effectiveness of your website with our Content Optimization service. We will analyze your content, identify areas for improvement, and implement strategic changes to attract more visitors.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/RankingAndTraffic.png")} alt="RankingAndTraffic" title='RankingAndTraffic' />
                                        </div>
                                    </div>
                                    <h2>
                                        Ranking and Traffic
                                    </h2>
                                    <p>
                                        Our team will work tirelessly to enhance your website's ranking on search engines and drive organic traffic to your site.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Benefits Of Our  <span> SEO </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIDevelopmentBenefits">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/Enhanceonlinevisibility.png")} alt="Enhanceonlinevisibility" title='Enhanceonlinevisibility' />
                                                </div>
                                            </div>
                                            <h2>
                                                Enhance online visibility
                                            </h2>
                                            <p>
                                                By implementing search engine optimization strategies, companies can improve their rankings on search engine results pages, leading to increased visibility and more organic traffic to their websites.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/BoostBrandCredibility.png")} alt="BoostBrandCredibility" title='BoostBrandCredibility' />
                                                </div>
                                            </div>
                                            <h2 >
                                                Boost brand credibility
                                            </h2>
                                            <p>
                                                Websites that appear higher in search engine results are often perceived as more trustworthy and credible by users. By investing in SEO, businesses can build their brand reputation and establish themselves as industry leaders.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/Drivetargetedtraffic.png")} alt="Drivetargetedtraffic" title='Drivetargetedtraffic' />
                                                </div>
                                            </div>
                                            <h2>
                                                Drive targeted traffic
                                            </h2>
                                            <p>
                                                SEO helps businesses attract high-quality leads who are actively searching for products or services related to their industry. This targeted traffic can result in higher conversion rates and ultimately, increased revenue.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/IncreaseWebsiteTraffic.png")} alt="IncreaseWebsiteTraffic" title='IncreaseWebsiteTraffic' />
                                                </div>
                                            </div>
                                            <h2>
                                                Increase Website Traffic
                                            </h2>
                                            <p>
                                                By implementing SEO strategies, your website can rank higher in search engine results, leading to more organic traffic from potential customers.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/BoostConversions.png")} alt="BoostConversions" title='BoostConversions' />
                                                </div>
                                            </div>
                                            <h2>
                                                Boost Conversions
                                            </h2>
                                            <p>
                                                Optimizing your website for search engines can result in higher conversion rates, as the traffic generated is more likely to be interested in your products or services.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='d-flex justify-content-start'>
                                                <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/BoostSales.png")} alt="BoostSales" title='BoostSales' />
                                                </div>
                                            </div>
                                            <h2>
                                                Boost Sales
                                            </h2>
                                            <p>
                                                By optimizing your website for search engines, you can enhance user experience, increase brand credibility, and ultimately drive more conversions and sales for your business.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/BenefitsUp.png")} alt="BenefitsUp" title='BenefitsUp' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "24px" }}>
                                Benefits Of Our <span> SEO </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/Enhanceonlinevisibility.png")} alt="Enhanceonlinevisibility" title='Enhanceonlinevisibility' />
                                        </div>
                                    </div>
                                    <h2>
                                        Enhance online visibility
                                    </h2>
                                    <p>
                                        By implementing search engine optimization strategies, companies can improve their rankings on search engine results pages, leading to increased visibility and more organic traffic to their websites.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/BoostBrandCredibility.png")} alt="BoostBrandCredibility" title='BoostBrandCredibility' />
                                        </div>
                                    </div>
                                    <h2 >
                                        Boost brand credibility
                                    </h2>
                                    <p>
                                        Websites that appear higher in search engine results are often perceived as more trustworthy and credible by users. By investing in SEO, businesses can build their brand reputation and establish themselves as industry leaders.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/Drivetargetedtraffic.png")} alt="Drivetargetedtraffic" title='Drivetargetedtraffic' />
                                        </div>
                                    </div>
                                    <h2>
                                        Drive targeted traffic
                                    </h2>
                                    <p>
                                        SEO helps businesses attract high-quality leads who are actively searching for products or services related to their industry. This targeted traffic can result in higher conversion rates and ultimately, increased revenue.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/IncreaseWebsiteTraffic.png")} alt="IncreaseWebsiteTraffic" title='IncreaseWebsiteTraffic' />
                                        </div>
                                    </div>
                                    <h2>
                                        Increase Website Traffic
                                    </h2>
                                    <p>
                                        By implementing SEO strategies, your website can rank higher in search engine results, leading to more organic traffic from potential customers.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/BoostConversions.png")} alt="BoostConversions" title='BoostConversions' />
                                        </div>
                                    </div>
                                    <h2>
                                        Boost Conversions
                                    </h2>
                                    <p>
                                        Optimizing your website for search engines can result in higher conversion rates, as the traffic generated is more likely to be interested in your products or services.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='CommonIconArtificeMain d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/BoostSales.png")} alt="BoostSales" title='BoostSales' />
                                        </div>
                                    </div>
                                    <h2>
                                        Boost Sales
                                    </h2>
                                    <p>
                                        By optimizing your website for search engines, you can enhance user experience, increase brand credibility, and ultimately drive more conversions and sales for your business.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-3">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> SEO </span> Work
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AIWork'>
                                    <h2>
                                        Digital Marketing made accessible and easy for <span> business growth </span>
                                    </h2>
                                    <p>
                                        Looking to improve your website's search engine rankings and attract more qualified leads? Our SEO services offer a comprehensive approach to optimizing your online presence, from conducting thorough keyword research to analyzing your competitors' strategies. By implementing a custom SEO plan for your business, we can help you stand out in search engine results and drive valuable traffic to your website, ultimately leading to increased conversions and success for your brand.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/SeoWork1.jpg")} alt="SeoWork1" title='SeoWork1' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pt-5 pb-5 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-3">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "28px" }}>
                                Our <span> SEO </span> Work
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/SeoWork1.jpg")} alt="SeoWork1" title='SeoWork1' />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className='AIWork'>
                                    <h2>
                                        Digital Marketing made accessible and easy for <span> business growth </span>
                                    </h2>
                                    <p>
                                        Looking to improve your website's search engine rankings and attract more qualified leads? Our SEO services offer a comprehensive approach to optimizing your online presence, from conducting thorough keyword research to analyzing your competitors' strategies. By implementing a custom SEO plan for your business, we can help you stand out in search engine results and drive valuable traffic to your website, ultimately leading to increased conversions and success for your brand.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-3 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-2 pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../Img/OnlineBusiness.webp")} alt="OnlineBusiness" title='OnlineBusiness' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AITechnology'>
                                    <h2>
                                        Transfer your <span> Offline Business to Online </span>
                                    </h2>
                                    <p>
                                        Take your offline business to the next level with our digital marketing service. Our team will work closely with you to develop a tailored strategy that aligns with your business goals and objectives. From creating a user-friendly website to implementing effective online marketing tactics, we've got you covered every step of the way.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-2 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg  pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../Img/OnlineBusiness.webp")} alt="OnlineBusiness" title='OnlineBusiness' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AITechnology'>
                                    <h2>
                                        Transfer your <span> Offline Business to Online </span>
                                    </h2>
                                    <p>
                                        Take your offline business to the next level with our digital marketing service. Our team will work closely with you to develop a tailored strategy that aligns with your business goals and objectives. From creating a user-friendly website to implementing effective online marketing tactics, we've got you covered every step of the way.
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Seo