import React from 'react'
import "./CallButton.css"

const CallButton = () => {

    return (
        <>

            <div className="MainContainer " >
                <button className='share-btn'>
                    <i className="fa-solid fa-share-nodes"></i>
                </button>

                <div className="share-options">
                    <p className='title'> share </p>
                    <div className='social-media'>
                        <button className='social-media-btn'>
                            <i className="fa-brands fa-square-whatsapp"></i>
                        </button>

                        <button className='social-media-btn'>
                            <i className="fa-brands fa-facebook-f"></i>
                        </button>

                        <button className='social-media-btn'>
                            <i className="fa-brands fa-instagram"></i>
                        </button>

                        <button className='social-media-btn'>
                            <i className="fa-solid fa-phone"></i>
                        </button>

                        <button className='social-media-btn'>
                            <i className="fa-brands fa-linkedin"></i>
                        </button>

                        <button className='social-media-btn'>
                            <i className="fa-brands fa-twitter"></i>
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CallButton