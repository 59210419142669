import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Metaverse = () => {
    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/ai/metaverse" />
                    <meta name="keywords" content="Metaverse/NFT iCloudsoft, Software Development Company in Pune." />
                    <title> Metaverse/NFT | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-5 mt-5 d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "50px" }}>
                                        Metaverse Development Company
                                    </h3>
                                    <h1>
                                        Innovative Metaverse & NFT Solutions in AI Development Pune
                                    </h1>
                                    <p>
                                        Create your immersive universe with top-notch Metaverse Development Services. We redefine human interaction and experiences by blending the metaverse with our cutting-edge tech stack.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-6">
                                <img className='w-100' src={require("../Img/ArtificeMainBg.png")} alt="ArtificeMain" title='ArtificeMain' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-2 pb-2 d-flex justify-content-between align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Metaverse Development Company
                                    </h3>
                                    <h4 className='laila-semibold' style={{ fontSize: "17px", fontWeight: "600", color: "#fff" }}>
                                        Innovative Metaverse & NFT Solutions in AI Development Pune
                                    </h4>
                                    <p>
                                        Create your immersive universe with top-notch Metaverse Development Services. We redefine human interaction and experiences by blending the metaverse with our cutting-edge tech stack.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5">
                                <img className='w-100' src={require("../Img/ArtificeMainBg.png")} alt="ArtificeMain" title='ArtificeMain' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Metaverse Development </span> Services
                            </h2>
                            <div className='d-flex justify-content-center'>
                                <p>
                                    We give assistance to the organization in fastening the power of decentralization with our reliable end-to-end web3 services to improve efficiency, unlock more business values, enhance the customer experience, and create new business models.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Metaverse Game Development
                                    </h2>
                                    <p>
                                        Metaverse takes gaming to the next level with highly interactive virtual worlds. Utilizing blockchain for secure transactions and NFTs for authenticity, we offer unique gaming experiences with stunning visuals and innovative gameplay mechanics, ensuring players are fully immersed in the metaverse.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Metaverse App Development
                                    </h2>
                                    <p>
                                        Metaverse technology transforms apps by integrating advanced technologies like AI and blockchain. We develop innovative apps with seamless user interactions and immersive experiences within the metaverse. Our apps are versatile, engaging, and designed for a wide range of user needs.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Metaverse Virtual Mall Development
                                    </h2>
                                    <p>
                                        Metaverse Mall brings interactive commerce and entertainment together in a futuristic digital space. Our advanced platform includes virtual cineplexes, interactive fun zones, and social hubs, creating an immersive shopping experience where you can explore, connect, and shop like never before.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Metaverse Theatre Development
                                    </h2>
                                    <p>
                                        Metaverse transforms the theater experience by blending live performances within virtual environments. Our metaverse theater allows audiences to enjoy shows from anywhere, with enhanced interactivity and engagement through seamless digital ticketing and real-time audience interaction.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Metaverse Event Platform Development
                                    </h2>
                                    <p>
                                        With cutting-edge virtual environments and interactive features, the metaverse redefines event experiences. Our platform combines virtual spaces with interactive tools, furnishing live streaming and virtual booth features, enabling users to attend conferences, expos, and meetings from anywhere.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Metaverse Virtual Mall Development
                                    </h2>
                                    <p>
                                        Leveraging VR technology, Metaverse transforms shopping by combining e-commerce with immersive experiences. Our virtual malls let users explore, try on, and purchase products in a digital space. We ensure seamless and secure shopping through integrated payment gateways and digital asset management.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Metaverse Social Media Platform Development
                                    </h2>
                                    <p>
                                        Metaverse Social Media Platform redefines social networking by blending virtual reality, augmented reality, and traditional social media elements. We combine cutting-edge technologies like VR, AR, and blockchain to build unique and engaging platforms that offer a new dimension to social networking.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Metaverse 3D Space Development
                                    </h2>
                                    <p>
                                        Transform your ideas into immersive 3D virtual workspaces specially designed for collaboration, art galleries for showcasing masterpieces, museums for interactive learning, or educational environments for immersive experiences. We specialize in building dynamic and engaging virtual worlds.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Metaverse 3D Avatar Development
                                    </h2>
                                    <p>
                                        It involves creating virtual representations of users or characters for use in immersive virtual environments. These avatars are designed to be customizable, allowing users to express their individuality and interact with others in the virtual space. We create detailed 3D modeling and animation avatars that can mimic real-life movements and expressions.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Metaverse Token Development
                                    </h2>
                                    <p>
                                        Metaverse Token Development creates digital tokens for virtual environments, representing assets or currency. These tokens enable transactions and interactions within the metaverse. They help businesses manage virtual economies and incentivize user engagement.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Metaverse Real Estate Development
                                    </h2>
                                    <p>
                                        Metaverse real estate focuses on constructing and managing virtual properties and environments within a digital realm. We create virtual assets that mimic real-world real estate, offering investment, and interaction opportunities. Individuals and businesses can engage in buying, selling, and developing virtual land, structures, and experiences.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Metaverse Integration Services
                                    </h2>
                                    <p>
                                        Metaverse integration services involve integrating virtual environments into existing platforms or systems. This process enables seamless interaction between real-world applications and the metaverse, enhancing user experiences and functionality. By integrating with the metaverse, businesses can expand their reach, offer innovative services, and create immersive experiences for their users.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Metaverse Development </span> Services
                            </h2>
                            <div className='d-flex justify-content-center pt-3'>
                                <p>
                                    We give assistance to the organization in fastening the power of decentralization with our reliable end-to-end web3 services to improve efficiency, unlock more business values, enhance the customer experience, and create new business models.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Metaverse Game Development
                                    </h2>
                                    <p>
                                        Metaverse takes gaming to the next level with highly interactive virtual worlds. Utilizing blockchain for secure transactions and NFTs for authenticity, we offer unique gaming experiences with stunning visuals and innovative gameplay mechanics, ensuring players are fully immersed in the metaverse.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Metaverse App Development
                                    </h2>
                                    <p>
                                        Metaverse technology transforms apps by integrating advanced technologies like AI and blockchain. We develop innovative apps with seamless user interactions and immersive experiences within the metaverse. Our apps are versatile, engaging, and designed for a wide range of user needs.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Metaverse Virtual Mall Development
                                    </h2>
                                    <p>
                                        Metaverse Mall brings interactive commerce and entertainment together in a futuristic digital space. Our advanced platform includes virtual cineplexes, interactive fun zones, and social hubs, creating an immersive shopping experience where you can explore, connect, and shop like never before.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Metaverse Theatre Development
                                    </h2>
                                    <p>
                                        Metaverse transforms the theater experience by blending live performances within virtual environments. Our metaverse theater allows audiences to enjoy shows from anywhere, with enhanced interactivity and engagement through seamless digital ticketing and real-time audience interaction.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Metaverse Event Platform Development
                                    </h2>
                                    <p>
                                        With cutting-edge virtual environments and interactive features, the metaverse redefines event experiences. Our platform combines virtual spaces with interactive tools, furnishing live streaming and virtual booth features, enabling users to attend conferences, expos, and meetings from anywhere.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Metaverse Virtual Mall Development
                                    </h2>
                                    <p>
                                        Leveraging VR technology, Metaverse transforms shopping by combining e-commerce with immersive experiences. Our virtual malls let users explore, try on, and purchase products in a digital space. We ensure seamless and secure shopping through integrated payment gateways and digital asset management.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Metaverse Social Media Platform Development
                                    </h2>
                                    <p>
                                        Metaverse Social Media Platform redefines social networking by blending virtual reality, augmented reality, and traditional social media elements. We combine cutting-edge technologies like VR, AR, and blockchain to build unique and engaging platforms that offer a new dimension to social networking.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Metaverse 3D Space Development
                                    </h2>
                                    <p>
                                        Transform your ideas into immersive 3D virtual workspaces specially designed for collaboration, art galleries for showcasing masterpieces, museums for interactive learning, or educational environments for immersive experiences. We specialize in building dynamic and engaging virtual worlds.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Metaverse 3D Avatar Development
                                    </h2>
                                    <p>
                                        It involves creating virtual representations of users or characters for use in immersive virtual environments. These avatars are designed to be customizable, allowing users to express their individuality and interact with others in the virtual space. We create detailed 3D modeling and animation avatars that can mimic real-life movements and expressions.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Metaverse Token Development
                                    </h2>
                                    <p>
                                        Metaverse Token Development creates digital tokens for virtual environments, representing assets or currency. These tokens enable transactions and interactions within the metaverse. They help businesses manage virtual economies and incentivize user engagement.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Metaverse Real Estate Development
                                    </h2>
                                    <p>
                                        Metaverse real estate focuses on constructing and managing virtual properties and environments within a digital realm. We create virtual assets that mimic real-world real estate, offering investment, and interaction opportunities. Individuals and businesses can engage in buying, selling, and developing virtual land, structures, and experiences.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Metaverse Integration Services
                                    </h2>
                                    <p>
                                        Metaverse integration services involve integrating virtual environments into existing platforms or systems. This process enables seamless interaction between real-world applications and the metaverse, enhancing user experiences and functionality. By integrating with the metaverse, businesses can expand their reach, offer innovative services, and create immersive experiences for their users.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Benefits Of Our  <span> Metaverse Development </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIDevelopmentBenefits">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-gear"></i>
                                            </div>
                                            <h2>
                                                Enhanced Customer Engagement
                                            </h2>
                                            <p>
                                                Embrace a decentralized structure, offering enhanced security and transparency. Users gain greater control over their data without the need for intermediaries.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-brands fa-hive"></i>
                                            </div>
                                            <h2 >
                                                Personalization and Customization
                                            </h2>
                                            <p>
                                                Unlock seamless interactions across diverse blockchain networks, ensuring compatibility and connectivity in the dynamic digital environment.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-layer-group"></i>
                                            </div>
                                            <h2>
                                                Global Accessibility
                                            </h2>
                                            <p>
                                                Empower users to own, control, and monetize their digital assets, fostering a customer-centric model that enhances engagement and trust.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                Collaborative Environments
                                            </h2>
                                            <p>
                                                Safeguard user data with robust privacy features, providing individuals with ownership and control over their personal information.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                Scalability and Flexibility
                                            </h2>
                                            <p>
                                                Enhance effective cross-border transactions more securely and seamlessly with blockchain technology.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-gears"></i>
                                            </div>
                                            <h2>
                                                Environmental Sustainability
                                            </h2>
                                            <p>
                                                Create advanced decentralized applications (DApps) that redefine user experiences, offering novel solutions and opportunities within the Web3 ecosystem.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-brands fa-hive"></i>
                                            </div>
                                            <h2 >
                                                New Revenue Streams
                                            </h2>
                                            <p>
                                                Unlock seamless interactions across diverse blockchain networks, ensuring compatibility and connectivity in the dynamic digital environment.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-layer-group"></i>
                                            </div>
                                            <h2>
                                                Limitless Opportunities
                                            </h2>
                                            <p>
                                                Empower users to own, control, and monetize their digital assets, fostering a customer-centric model that enhances engagement and trust.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                High-Quality Graphics and Design
                                            </h2>
                                            <p>
                                                Safeguard user data with robust privacy features, providing individuals with ownership and control over their personal information.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/BenefitsUp.png")} alt="BenefitsUp" title='BenefitsUp' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "24px" }}>
                                Benefits Of Our <span> Metaverse Development </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Enhanced Customer Engagement
                                    </h2>
                                    <p>
                                        Embrace a decentralized structure, offering enhanced security and transparency. Users gain greater control over their data without the need for intermediaries.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2 >
                                        Personalization and Customization
                                    </h2>
                                    <p>
                                        Unlock seamless interactions across diverse blockchain networks, ensuring compatibility and connectivity in the dynamic digital environment.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Global Accessibility
                                    </h2>
                                    <p>
                                        Empower users to own, control, and monetize their digital assets, fostering a customer-centric model that enhances engagement and trust.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Collaborative Environments
                                    </h2>
                                    <p>
                                        Safeguard user data with robust privacy features, providing individuals with ownership and control over their personal information.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Scalability and Flexibility
                                    </h2>
                                    <p>
                                        Enhance effective cross-border transactions more securely and seamlessly with blockchain technology.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Environmental Sustainability
                                    </h2>
                                    <p>
                                        Create advanced decentralized applications (DApps) that redefine user experiences, offering novel solutions and opportunities within the Web3 ecosystem.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2 >
                                        New Revenue Streams
                                    </h2>
                                    <p>
                                        Unlock seamless interactions across diverse blockchain networks, ensuring compatibility and connectivity in the dynamic digital environment.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Limitless Opportunities
                                    </h2>
                                    <p>
                                        Empower users to own, control, and monetize their digital assets, fostering a customer-centric model that enhances engagement and trust.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        High-Quality Graphics and Design
                                    </h2>
                                    <p>
                                        Safeguard user data with robust privacy features, providing individuals with ownership and control over their personal information.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our End-To-End <span> Metaverse Development </span> Workflow
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/AIIndustries.png")} alt="AIIndustries" title='AIIndustries' />
                                </div>
                            </div>

                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIIndustries">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                Requirements Gathering and Analysis
                                            </h2>
                                            <p>
                                                We collaborate closely with our clients to understand their vision, goals, and requirements for the Metaverse project by conducting in-depth analysis to define the project scope.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                Conceptualization and Design
                                            </h2>
                                            <p>
                                                Our creative team conceptualizes and designs engaging virtual environments, user interfaces, and avatars that align with their brand identity and target audience preferences.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                Technology Stack Selection
                                            </h2>
                                            <p>
                                                Based on project requirements, we carefully select the most suitable technologies, ranging from game engines to 3D modeling tools, blockchain solutions, and extended reality (XR) platforms.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                Asset Creation and Integration
                                            </h2>
                                            <p>
                                                We create or integrate 3D assets like environments, objects, avatars, and multimedia elements to ensure a cohesive and captivating user experience.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                Testing and Deployment
                                            </h2>
                                            <p>
                                                Our rigorous testing methodologies ensure that the Metaverse platform developed is bug-free, optimized, and ready for deployment on chosen platforms or devices.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-start BenefitsOfAI'>
                                            <h2>
                                                Ongoing Support and Evolution
                                            </h2>
                                            <p>
                                                We provide continuous support, maintenance, and regular updates to keep your Metaverse project aligned with emerging trends and evolving user demands.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our End-To-End <span> Metaverse Development </span> Workflow
                            </h2>
                        </div>

                        <div className="row gx-0 gy-4 pt-3 AIIndustries">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        Requirements Gathering and Analysis
                                    </h2>
                                    <p>
                                        We collaborate closely with our clients to understand their vision, goals, and requirements for the Metaverse project by conducting in-depth analysis to define the project scope.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        Conceptualization and Design
                                    </h2>
                                    <p>
                                        Our creative team conceptualizes and designs engaging virtual environments, user interfaces, and avatars that align with their brand identity and target audience preferences.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        Technology Stack Selection
                                    </h2>
                                    <p>
                                        Based on project requirements, we carefully select the most suitable technologies, ranging from game engines to 3D modeling tools, blockchain solutions, and extended reality (XR) platforms.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        Asset Creation and Integration
                                    </h2>
                                    <p>
                                        We create or integrate 3D assets like environments, objects, avatars, and multimedia elements to ensure a cohesive and captivating user experience.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        Testing and Deployment
                                    </h2>
                                    <p>
                                        Our rigorous testing methodologies ensure that the Metaverse platform developed is bug-free, optimized, and ready for deployment on chosen platforms or devices.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='text-start BenefitsOfAI'>
                                    <h2>
                                        Ongoing Support and Evolution
                                    </h2>
                                    <p>
                                        We provide continuous support, maintenance, and regular updates to keep your Metaverse project aligned with emerging trends and evolving user demands.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-3 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-2 pb-lg-3 mb-4">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Features Of Our <span> Metaverse Development </span>
                            </h2>
                        </div>
                        <div className="row pt-4 g-4 d-flex justify-content-around align-items-center  ">
                            <div className="col-xl-5 col-md-6 ">
                                <div>
                                    <img className='w-100' src={require("../Img/Feature.png")} alt="Feature" title='Feature' />
                                </div>
                            </div>

                            <div className="col-xl-5 col-md-6 ">
                                <div className='BenefitsOfAI text-start '>
                                    <h2>
                                        Metaverse Development Features
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Immersive Environments
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Virtual Economy
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Interactive Experiences
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Decentralized Assets
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Custom Avatars
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Security
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Blockchain Integration
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Social Connectivity
                                                </div>
                                            </div>

                                            <div className='col-lg-12'>
                                                <div className='AITech'>
                                                    Cross-Platform Compatibility
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-2 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg  pb-lg-3 mb-4">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Features Of Our <span> Metaverse Development </span>
                            </h2>
                        </div>
                        <div className="row pt-4 g-4 d-flex justify-content-around align-items-center  ">
                            <div className="col-xl-5 col-md-6 ">
                                <div>
                                    <img className='w-100' src={require("../Img/Feature.png")} alt="Feature" title='Feature' />
                                </div>
                            </div>

                            <div className="col-xl-5 col-md-6 ">
                                <div className='BenefitsOfAI text-start '>
                                    <h2>
                                        Metaverse Development Features
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Immersive Environments
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Virtual Economy
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Interactive Experiences
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Decentralized Assets
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Custom Avatars
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Security
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Blockchain Integration
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Social Connectivity
                                                </div>
                                            </div>

                                            <div className='col-lg-12'>
                                                <div className='AITech'>
                                                    Cross-Platform Compatibility
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Metaverse Development </span> Industries
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIDevelopmentBenefits">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Advertising
                                            </h2>
                                            <p>
                                                We begin by thoroughly understanding the problem or business challenge at hand. Our experts work closely with clients to define the objectives, scope, and desired outcomes to create a clear roadmap for success.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Events
                                            </h2>
                                            <p>
                                                Our team meticulously collects and analyzes relevant data sources by leveraging advanced techniques like data mining, exploratory data analysis, and feature engineering to extract valuable insights.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Manufacturing
                                            </h2>
                                            <p>
                                                Before feeding data into AI models, we preprocess it to ensure quality and consistency. This involves techniques like data cleaning, normalization, handling missing values, and feature extraction, which enable our models to learn effectively from the preprocessed data.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Social Networks
                                            </h2>
                                            <p>
                                                Choosing the right AI model is crucial for optimal performance. Our experts leverage their deep knowledge of machine learning algorithms, such as neural networks, decision trees, and support vector machines, to select the most appropriate model for the task at hand.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Sports
                                            </h2>
                                            <p>
                                                In this phase, we train the selected AI model using the preprocessed data. Advanced techniques like regularization, hyperparameter tuning, and transfer learning are employed to optimize model performance and prevent overfitting.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Entertainment
                                            </h2>
                                            <p>
                                                With rigorous testing and evaluation, we ensure the AI model's reliability and accuracy. We employ various techniques like cross-validation, holdout sets, and performance metrics like precision, recall, and F1 score to assess the model's performance and make necessary adjustments.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI text-start'>
                                            <h2>
                                                Gaming
                                            </h2>
                                            <p>
                                                After the rigid quality standards, we deploy it into production environments. Additionally, we continuously monitor and maintain the model's ongoing performance to retrain or update it as needed to adapt to changing conditions or new data.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/BenefitsUp.png")} alt="BenefitsUp" title='BenefitsUp' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Metaverse Development </span> Industries
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Advertising
                                    </h2>
                                    <p>
                                        We begin by thoroughly understanding the problem or business challenge at hand. Our experts work closely with clients to define the objectives, scope, and desired outcomes to create a clear roadmap for success.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Events
                                    </h2>
                                    <p>
                                        Our team meticulously collects and analyzes relevant data sources by leveraging advanced techniques like data mining, exploratory data analysis, and feature engineering to extract valuable insights.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Manufacturing
                                    </h2>
                                    <p>
                                        Before feeding data into AI models, we preprocess it to ensure quality and consistency. This involves techniques like data cleaning, normalization, handling missing values, and feature extraction, which enable our models to learn effectively from the preprocessed data.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Social Networks
                                    </h2>
                                    <p>
                                        Choosing the right AI model is crucial for optimal performance. Our experts leverage their deep knowledge of machine learning algorithms, such as neural networks, decision trees, and support vector machines, to select the most appropriate model for the task at hand.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Sports
                                    </h2>
                                    <p>
                                        In this phase, we train the selected AI model using the preprocessed data. Advanced techniques like regularization, hyperparameter tuning, and transfer learning are employed to optimize model performance and prevent overfitting.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Entertainment
                                    </h2>
                                    <p>
                                        With rigorous testing and evaluation, we ensure the AI model's reliability and accuracy. We employ various techniques like cross-validation, holdout sets, and performance metrics like precision, recall, and F1 score to assess the model's performance and make necessary adjustments.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Gaming
                                    </h2>
                                    <p>
                                        After the rigid quality standards, we deploy it into production environments. Additionally, we continuously monitor and maintain the model's ongoing performance to retrain or update it as needed to adapt to changing conditions or new data.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Metaverse </span> Tech Stack
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        MERN Stack
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    MongoDB
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Express JS
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    React JS
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Node JS
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Virtual Reality
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Oculus Quest
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Viverse
                                                </div>
                                            </div>

                                            <div className='col-lg-12'>
                                                <div className='AITech'>
                                                    Valve Index
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Gamification & Animation
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Unity
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Unreal Engine
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    PlayCanvas
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Blockchain
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-4'>
                                                <div className='AITech'>
                                                    Ethereum
                                                </div>
                                            </div>

                                            <div className='col-lg-4'>
                                                <div className='AITech'>
                                                    Tron
                                                </div>
                                            </div>

                                            <div className='col-lg-4'>
                                                <div className='AITech'>
                                                    BNB
                                                </div>
                                            </div>

                                            <div className='col-lg-4'>
                                                <div className='AITech'>
                                                    Polygon
                                                </div>
                                            </div>

                                            <div className='col-lg-4'>
                                                <div className='AITech'>
                                                    Aptos
                                                </div>
                                            </div>

                                            <div className='col-lg-4'>
                                                <div className='AITech'>
                                                    Corda
                                                </div>
                                            </div>

                                            <div className='col-lg-4'>
                                                <div className='AITech'>
                                                    Wax
                                                </div>
                                            </div>

                                            <div className='col-lg-4'>
                                                <div className='AITech'>
                                                    Fantom
                                                </div>
                                            </div>

                                            <div className='col-lg-4'>
                                                <div className='AITech'>
                                                    Avalanche
                                                </div>
                                            </div>

                                            <div className='col-lg-4'>
                                                <div className='AITech'>
                                                    Harmony
                                                </div>
                                            </div>

                                            <div className='col-lg-4'>
                                                <div className='AITech'>
                                                    Huobi
                                                </div>
                                            </div>

                                            <div className='col-lg-4'>
                                                <div className='AITech'>
                                                    Solana
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Metaverse </span> Tech Stack
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        MERN Stack
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    MongoDB
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Express JS
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    React JS
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Node JS
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Virtual Reality
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Oculus Quest
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Viverse
                                                </div>
                                            </div>

                                            <div className='col-lg-12'>
                                                <div className='AITech'>
                                                    Valve Index
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Gamification & Animation
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Unity
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    Unreal Engine
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='AITech'>
                                                    PlayCanvas
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI text-start'>
                                    <h2>
                                        Blockchain
                                    </h2>
                                    <p>
                                        <div className='row pt-3 text-center g-4 d-flex '>
                                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Ethereum
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Tron
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    BNB
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Polygon
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Aptos
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Corda
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Wax
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Fantom
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Avalanche
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Harmony
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Huobi
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                                <div className='AITech'>
                                                    Solana
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Metaverse