import React from 'react'
import "./Cryptocurrency.css"
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Cryptocurrency = () => {
    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/ai/cryptocurrency" />
                    <meta name="keywords" content="Cryptocurrency iCloudsoft, Software Development Company in Pune" />
                    <title> Cryptocurrency | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-5 mt-5 d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "54px" }}>
                                        CRYPTOCURRENCY Development Company
                                    </h3>
                                    <h1>
                                        Advanced Cryptocurrency Solutions in AI Development Pune
                                    </h1>
                                    <p>
                                        iCloudsoft technology  has plethora of cryptocurrency development services to help entrepreneurs and start-ups in creating robust and innovative Cryptocurrency like Bitcoin with groundbreaking security features.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-6">
                                <img className='w-100' src={require("../Img/ArtificeMainBg.png")} alt="ArtificeMain" title='ArtificeMain' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-2 pb-2 d-flex justify-content-between align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        CRYPTOCURRENCY Development Company
                                    </h3>
                                    <h4 className='laila-semibold' style={{ fontSize: "17px", fontWeight: "600", color: "#fff" }}>
                                        Advanced Cryptocurrency Solutions in AI Development Pune
                                    </h4>
                                    <p>
                                        iCloudsoft technology  has plethora of cryptocurrency development services to help entrepreneurs and start-ups in creating robust and innovative Cryptocurrency like Bitcoin with groundbreaking security features.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5">
                                <img className='w-100' src={require("../Img/ArtificeMainBg.png")} alt="ArtificeMain" title='ArtificeMain' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Cryptocurrency Development </span> Services
                            </h2>
                            <div className='d-flex justify-content-center'>
                                <p>
                                    As a leading cryptocurrency development company, we provide customized blockchain development services designed to meet your valuable business needs and requirements.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Altcoin Creation Service
                                    </h2>
                                    <p>
                                        In the cryptocurrency development service, the software processing of coin creation plays a vital role in order to make it effective and secure for future business upgrades all around the world. Our service extends to providing ICO development that boosts the digital wallet.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Cryptocurrency Exchange Development
                                    </h2>
                                    <p>
                                        One-stop-shop for your seamless trade over cryptocurrency exchange, facilitating secure and fast transactions. With our cutting-edge technologies on cryptocurrency exchange, own your independent exchange platform which unambiguously upsurges your marketing.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Cryptocurrency Exchange App Development
                                    </h2>
                                    <p>
                                        Our experienced team provides innovative ideas and exact solutions to problems according to your business needs, leveraging our experts' wide knowledge in diverse industries. Through our support, you can acquire an invincible crypto solution that satisfies our clients.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Cryptocurrency Wallet Development
                                    </h2>
                                    <p>
                                        Cryptocurrency wallet is a software functionality that allows users to acquire, audit, and transfer digital currencies. As a matter of fact, cryptocurrency is transferred to the wallet by means of transferring it through ownership of the currency stepped down to a desired wallet's address.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Bitcoin Wallet Development
                                    </h2>
                                    <p>
                                        Bitcoin wallet app is a digital wallet used to receive, secure, and store bitcoins. A cryptocurrency wallet is software that stores private and public keys and also interacts with various blockchains to send or receive digital currency. Bitcoin is the simplest form of exchange; you only need the bitcoin address of the recipient.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Smart Contract Development
                                    </h2>
                                    <p>
                                        Smart contracts are self-executing digital contracts that are backed by the ability to digitally facilitate, verify, or enforce the terms of an agreement between businesses. As smart contracts are linked to the blockchain, the data becomes immutable and the need for a third party is eliminated, thereby automating the business process and cutting down on extra costs.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Ethereum Token Development
                                    </h2>
                                    <p>
                                        Acquire robust Ethereum token development services, including the creation of tokens on different standards like ERC 20, ERC 721, ERC 777, and ERC 223, token transfer, digital wallet, and listing on an exchange.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Bitcoin Mining Software Development
                                    </h2>
                                    <p>
                                        Bitcoin mining is a procedure that involves a shared PC process used to secure and verify bitcoin transactions. Each transaction is individually approved and added to the blockchain or public record.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        ICO Development
                                    </h2>
                                    <p>
                                        Initial Coin Offerings (ICOs) are the fundraising mechanism for startup businesses or new project developers to sell their crypto tokens in exchange for fiat currency or other cryptocurrencies. It is an emerging practice for companies involved in blockchain and cryptocurrency to raise funds for their ventures.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Bitcoin MLM Software Development
                                    </h2>
                                    <p>
                                        Our organization, Analytics, offers digital money MLM programming arrangements based on blockchain technology with excellent IT support. Our association plays an important role in providing high-quality MLM programming services according to the client's business requirements.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        STO Development
                                    </h2>
                                    <p>
                                        STO - Security Token Offering that makes your business more reliable and flexible with its prime attributes. Secure tokens provide assurance to the investors and back their investments, ensuring that they realize something. This helps to enhance your business's appeal in the market and gives investors ownership, ultimately bringing capital to your business.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Cryptocurrency Consultations Services
                                    </h2>
                                    <p>
                                        Our experienced team provides innovative ideas and exact solutions to problems according to your business needs, leveraging our experts' wide knowledge in diverse industries. With our support, you can acquire an invincible crypto solution that satisfies our clients.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Cryptocurrency Development </span> Services
                            </h2>
                            <div className='d-flex justify-content-center pt-3'>
                                <p>
                                    As a leading cryptocurrency development company, we provide customized blockchain development services designed to meet your valuable business needs and requirements.
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Altcoin Creation Service
                                    </h2>
                                    <p>
                                        In the cryptocurrency development service, the software processing of coin creation plays a vital role in order to make it effective and secure for future business upgrades all around the world. Our service extends to providing ICO development that boosts the digital wallet.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Cryptocurrency Exchange Development
                                    </h2>
                                    <p>
                                        One-stop-shop for your seamless trade over cryptocurrency exchange, facilitating secure and fast transactions. With our cutting-edge technologies on cryptocurrency exchange, own your independent exchange platform which unambiguously upsurges your marketing.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Cryptocurrency Exchange App Development
                                    </h2>
                                    <p>
                                        Our experienced team provides innovative ideas and exact solutions to problems according to your business needs, leveraging our experts' wide knowledge in diverse industries. Through our support, you can acquire an invincible crypto solution that satisfies our clients.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Cryptocurrency Wallet Development
                                    </h2>
                                    <p>
                                        Cryptocurrency wallet is a software functionality that allows users to acquire, audit, and transfer digital currencies. As a matter of fact, cryptocurrency is transferred to the wallet by means of transferring it through ownership of the currency stepped down to a desired wallet's address.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Bitcoin Wallet Development
                                    </h2>
                                    <p>
                                        Bitcoin wallet app is a digital wallet used to receive, secure, and store bitcoins. A cryptocurrency wallet is software that stores private and public keys and also interacts with various blockchains to send or receive digital currency. Bitcoin is the simplest form of exchange; you only need the bitcoin address of the recipient.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Smart Contract Development
                                    </h2>
                                    <p>
                                        Smart contracts are self-executing digital contracts that are backed by the ability to digitally facilitate, verify, or enforce the terms of an agreement between businesses. As smart contracts are linked to the blockchain, the data becomes immutable and the need for a third party is eliminated, thereby automating the business process and cutting down on extra costs.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Ethereum Token Development
                                    </h2>
                                    <p>
                                        Acquire robust Ethereum token development services, including the creation of tokens on different standards like ERC 20, ERC 721, ERC 777, and ERC 223, token transfer, digital wallet, and listing on an exchange.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Bitcoin Mining Software Development
                                    </h2>
                                    <p>
                                        Bitcoin mining is a procedure that involves a shared PC process used to secure and verify bitcoin transactions. Each transaction is individually approved and added to the blockchain or public record.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        ICO Development
                                    </h2>
                                    <p>
                                        Initial Coin Offerings (ICOs) are the fundraising mechanism for startup businesses or new project developers to sell their crypto tokens in exchange for fiat currency or other cryptocurrencies. It is an emerging practice for companies involved in blockchain and cryptocurrency to raise funds for their ventures.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Bitcoin MLM Software Development
                                    </h2>
                                    <p>
                                        Our organization, Analytics, offers digital money MLM programming arrangements based on blockchain technology with excellent IT support. Our association plays an important role in providing high-quality MLM programming services according to the client's business requirements.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        STO Development
                                    </h2>
                                    <p>
                                        STO - Security Token Offering that makes your business more reliable and flexible with its prime attributes. Secure tokens provide assurance to the investors and back their investments, ensuring that they realize something. This helps to enhance your business's appeal in the market and gives investors ownership, ultimately bringing capital to your business.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Cryptocurrency Consultations Services
                                    </h2>
                                    <p>
                                        Our experienced team provides innovative ideas and exact solutions to problems according to your business needs, leveraging our experts' wide knowledge in diverse industries. With our support, you can acquire an invincible crypto solution that satisfies our clients.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Why <span> Cryptocurrency Development ? </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIDevelopmentBenefits">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-gear"></i>
                                            </div>
                                            <h2>
                                                Unique Solution
                                            </h2>
                                            <p>
                                                In general, cryptocurrency software development provides excellent solutions to all business problems with the assistance of highly experienced professionals. To create unique solutions, the best cryptocurrency developers gain insights and reviews based on their business requirements.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-brands fa-hive"></i>
                                            </div>
                                            <h2 >
                                                Expeditious Response
                                            </h2>
                                            <p>
                                                This blockchain technology makes everyone believe in providing a great response to all the queries that help enhance business as per your requirements. Using this technology adds value to the project on cryptocurrency app development with an additional understanding of the concept.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-layer-group"></i>
                                            </div>
                                            <h2>
                                                Secure, Reliable and Transparent
                                            </h2>
                                            <p>
                                                Blockchain has found its place in handling security rules for cryptocurrency. As usual, it provides a secure platform for performing cryptocurrency development services, ensuring reliability and transparency in affording such services.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                Decentralized Cryptocurrency
                                            </h2>
                                            <p>
                                                The control of decentralization works on each cryptocurrency by using distributed hyperledger technology. It makes use of developers who are well-versed in handling all new technologies of decentralization. As Bitcoin, which is the first decentralized cryptocurrency, it provides open-source software with high security.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/BenefitsUp.png")} alt="BenefitsUp" title='BenefitsUp' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "24px" }}>
                                Why <span> Cryptocurrency Development ? </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Unique Solution
                                    </h2>
                                    <p>
                                        In general, cryptocurrency software development provides excellent solutions to all business problems with the assistance of highly experienced professionals. To create unique solutions, the best cryptocurrency developers gain insights and reviews based on their business requirements.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2 >
                                        Expeditious Response
                                    </h2>
                                    <p>
                                        This blockchain technology makes everyone believe in providing a great response to all the queries that help enhance business as per your requirements. Using this technology adds value to the project on cryptocurrency app development with an additional understanding of the concept.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Secure, Reliable and Transparent
                                    </h2>
                                    <p>
                                        Blockchain has found its place in handling security rules for cryptocurrency. As usual, it provides a secure platform for performing cryptocurrency development services, ensuring reliability and transparency in affording such services.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Decentralized Cryptocurrency
                                    </h2>
                                    <p>
                                        The control of decentralization works on each cryptocurrency by using distributed hyperledger technology. It makes use of developers who are well-versed in handling all new technologies of decentralization. As Bitcoin, which is the first decentralized cryptocurrency, it provides open-source software with high security.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Cryptocurrency