import React from 'react'
import "./Ai.css"
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Ai = () => {
    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/ai" />
                    <meta name="keywords" content="Artificial Intelligence iCloudsoft, Software Development Company in Pune" />
                    <title> Artificial Intelligence | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundImage: `url(${require("../Img/AIBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'right' }}>
                    <div className="container-lg p-5">
                        <div className="row g-4 pt-5 mt-5 d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-5">
                                <img className='w-100' src={require("../Img/AI1-1.png")} alt="icloudsoft_logo" title='ai' />
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h6>
                                        Who We Are
                                    </h6>
                                    <h1 className='' style={{ fontSize: "50px" }}>
                                        Powerful AI Features Built For Marketing in Pune
                                    </h1>
                                    <p>
                                        AI Future enables us to focus more on content rather than editing motion. And it definitely makes our workflow smoother focus more on content rather than editing motion. And it AI Future enables us to focus more
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundImage: `url(${require("../Img/AIBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'right' }}>
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-2 pb-2 d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-5">
                                <img className='w-100' src={require("../Img/AI1-1.png")} alt="icloudsoft_logo" title='ai' />
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h6>
                                        Who We Are
                                    </h6>
                                    <h3 className='' style={{ fontSize: "25px" }}>
                                        Powerful AI Features Built For Marketing in Pune
                                    </h3>
                                    <p>
                                        AI Future enables us to focus more on content rather than editing motion. And it definitely makes our workflow smoother focus more on content rather than editing motion. And it AI Future enables us to focus more
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "rgb(27, 26, 46)" }}>
                    <div className="container-lg " style={{ padding: "20px" }}>
                        <div className="row d-flex justify-content-center align-items-center  MainGradient">
                            <div className="col-xl-5 col-md-5 p-0">
                                <div className='AISectionFirst'>
                                    <p>
                                        Millions of people have used our artificial intelligence services.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-7 col-md-7 p-0">
                                <div className='AISectionSecond'>
                                    <p>
                                        Our artificial intelligence services have been utilized by a vast number of individuals worldwide.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pt-2 pb-4 d-lg-none d-md-block" style={{ backgroundColor: "rgb(27, 26, 46)" }}>
                    <div className="container-lg p-3">
                        <div className="row d-flex justify-content-center align-items-center  MainGradient">
                            <div className="col-xl-5 col-md-5 p-0">
                                <div className='AISectionFirst'>
                                    <p>
                                        Millions of people have used our artificial intelligence services.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-7 col-md-7 p-0">
                                <div className='AISectionSecond'>
                                    <p>
                                        Our artificial intelligence services have been utilized by a vast number of individuals worldwide.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-3 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "rgb(27, 26, 46)" }}>
                    <div className="container-lg p-2 pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100' src={require("../Img/Ai2-1.png")} alt="icloudsoft_logo" title='ai' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AboutSectionHome'>
                                    <h6>
                                        ABOUT AUTOMATON
                                    </h6>
                                    <h3>
                                        Artificial Intelligence for Business Applications.
                                    </h3>
                                    <p>
                                        Artificial intelligence has become increasingly important in the realm of business applications. The use of AI technology allows businesses to automate processes, analyze large amounts of data, and make more informed decisions. From customer service chatbots to predictive analytics, AI is revolutionizing the way businesses operate and interact with their customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-2 d-lg-none d-md-block" style={{ backgroundColor: "rgb(27, 26, 46)" }}>
                    <div className="container-lg  pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100' src={require("../Img/Ai2-1.png")} alt="icloudsoft_logo" title='ai' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AboutSectionHome'>
                                    <h6>
                                        ABOUT AUTOMATON
                                    </h6>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Artificial Intelligence for Business Applications.
                                    </h3>
                                    <p>
                                        Artificial intelligence has become increasingly important in the realm of business applications. The use of AI technology allows businesses to automate processes, analyze large amounts of data, and make more informed decisions. From customer service chatbots to predictive analytics, AI is revolutionizing the way businesses operate and interact with their customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "rgb(27, 26, 46)" }}>
                    <div className="container-lg">
                        <div className="row g-4 d-flex justify-content-around align-items-center">
                            <div className="col-xl-5 col-lg-5 ">
                                <div className='OurServices'>
                                    <h6>
                                        ABOUT SERVICE
                                    </h6>
                                    <h2>
                                        Artificial Intelligence for Business Applications
                                    </h2>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6">
                                <div className='OurServices'>
                                    <p>
                                        Artificial Intelligence (AI) has become increasingly essential for businesses looking to gain a competitive edge in today's market. AI technology can be applied to a wide range of business applications, from customer service and marketing to supply chain management and financial analysis.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row g-4 pt-lg-5 mt-5">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/artificialintelligence'>
                                    <div className='AboutService'>
                                        <div className='CommonIconAbout'>
                                            <i class="fa-solid fa-gear"></i>
                                        </div>
                                        <h2>
                                            Artificial Intelligence
                                        </h2>
                                        <p>
                                            Intelligent Solutions for a Smarter Tomorrow
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/blockchain'>
                                    <div className='AboutService'>
                                        <div className='CommonIconAbout'>
                                            <i class="fa-brands fa-hive"></i>
                                        </div>
                                        <h2>
                                            Blockchain
                                        </h2>
                                        <p>
                                            The Secure Path to Innovation
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/cryptocurrency'>
                                    <div className='AboutService'>
                                        <div className='CommonIconAbout'>
                                            <i class="fa-solid fa-dollar-sign"></i>
                                        </div>
                                        <h2>
                                            Cryptocurrency
                                        </h2>
                                        <p>
                                            Secure, Transparent, and Borderless
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/virtualreality'>
                                    <div className='AboutService'>
                                        <div className='CommonIconAbout'>
                                            <i class="fa-solid fa-lightbulb"></i>
                                        </div>
                                        <h2>
                                            AR/VR/MR/XR
                                        </h2>
                                        <p>
                                            Beyond the Ordinary, Endless Possibilities
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/web3'>
                                    <div className='AboutService'>
                                        <div className='CommonIconAbout'>
                                            <i class="fa-solid fa-chart-simple"></i>
                                        </div>
                                        <h2>
                                            Web 3
                                        </h2>
                                        <p>
                                            Step into the next generation of the web with Web 3.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/mlm'>
                                    <div className='AboutService'>
                                        <div className='CommonIconAbout'>
                                            <i class="fa-solid fa-gears"></i>
                                        </div>
                                        <h2>
                                            MLM
                                        </h2>
                                        <p>
                                            Decode the undiscovered with Masked Language Modeling.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/metaverse'>
                                    <div className='AboutService'>
                                        <div className='CommonIconAbout'>
                                            <i class="fa-solid fa-layer-group"></i>
                                        </div>
                                        <h2>
                                            Metaverse /NFT
                                        </h2>
                                        <p>
                                            Metaverse and NFTs, the Ultimate Digital Experience, your Gateway to the Future.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 d-lg-none d-md-block" style={{ backgroundColor: "rgb(27, 26, 46)" }}>
                    <div className="container-lg">
                        <div className="row g-4 d-flex justify-content-around align-items-center">
                            <div className="col-xl-5 col-lg-5 ">
                                <div className='OurServices'>
                                    <h6>
                                        ABOUT SERVICE
                                    </h6>
                                    <h2 className='' style={{ fontSize: "30px" }}>
                                        Artificial Intelligence for Business Applications
                                    </h2>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6">
                                <div className='OurServices'>
                                    <p>
                                        Artificial Intelligence (AI) has become increasingly essential for businesses looking to gain a competitive edge in today's market. AI technology can be applied to a wide range of business applications, from customer service and marketing to supply chain management and financial analysis.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row g-4 pt-lg-5 mt-5">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/artificialintelligence'>
                                    <div className='AboutService'>
                                        <div className='CommonIconAbout'>
                                            <i class="fa-solid fa-gear"></i>
                                        </div>
                                        <h2>
                                            Artificial Intelligence
                                        </h2>
                                        <p>
                                            Intelligent Solutions for a Smarter Tomorrow
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/blockchain'>
                                    <div className='AboutService'>
                                        <div className='CommonIconAbout'>
                                            <i class="fa-brands fa-hive"></i>
                                        </div>
                                        <h2>
                                            Blockchain
                                        </h2>
                                        <p>
                                            The Secure Path to Innovation
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/cryptocurrency'>
                                    <div className='AboutService'>
                                        <div className='CommonIconAbout'>
                                            <i class="fa-solid fa-dollar-sign"></i>
                                        </div>
                                        <h2>
                                            Cryptocurrency
                                        </h2>
                                        <p>
                                            Secure, Transparent, and Borderless
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/virtualreality'>
                                    <div className='AboutService'>
                                        <div className='CommonIconAbout'>
                                            <i class="fa-solid fa-lightbulb"></i>
                                        </div>
                                        <h2>
                                            AR/VR/MR/XR
                                        </h2>
                                        <p>
                                            Beyond the Ordinary, Endless Possibilities
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/web3'>
                                    <div className='AboutService'>
                                        <div className='CommonIconAbout'>
                                            <i class="fa-solid fa-chart-simple"></i>
                                        </div>
                                        <h2>
                                            Web 3
                                        </h2>
                                        <p>
                                            Step into the next generation of the web with Web 3.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/mlm'>
                                    <div className='AboutService'>
                                        <div className='CommonIconAbout'>
                                            <i class="fa-solid fa-gears"></i>
                                        </div>
                                        <h2>
                                            MLM
                                        </h2>
                                        <p>
                                            Decode the undiscovered with Masked Language Modeling.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/metaverse'>
                                    <div className='AboutService'>
                                        <div className='CommonIconAbout'>
                                            <i class="fa-solid fa-layer-group"></i>
                                        </div>
                                        <h2>
                                            Metaverse /NFT
                                        </h2>
                                        <p>
                                            Metaverse and NFTs, the Ultimate Digital Experience, your Gateway to the Future.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container-lg pt-5 pb-5  mb-4">
                        <div className='AIsectionBottom'>
                            <h2>
                                The power of computers to intelligent machines.
                            </h2>
                            <h6>
                                Whatever your current goals, our data analysts are here to support you at each stage of your data journey
                            </h6>
                        </div>
                        <div className="row g-4 pt-5 mb-sm-5">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className='AboutServiceBottom'>
                                    <div className='CommonIconAboutBottom'>
                                        <i class="fa-solid fa-lightbulb"></i>
                                    </div>
                                    <h2>
                                        Shine a light on dark data.
                                    </h2>
                                    <p>
                                        Shed light on the obscured data that holds valuable potential for analysis and decision-making.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className='AboutServiceBottom'>
                                    <div className='CommonIconAboutBottom'>
                                        <i class="fa-solid fa-database"></i>
                                    </div>
                                    <h2>
                                        Transform data for new insights.
                                    </h2>
                                    <p>
                                        Enhance information to uncover fresh perspectives and understandings
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className='AboutServiceBottom'>
                                    <div className='CommonIconAboutBottom'>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Fast response times.
                                    </h2>
                                    <p>
                                        Fast response allows for a more effective work
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className='AboutServiceBottom'>
                                    <div className='CommonIconAboutBottom'>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Automate workflows.
                                    </h2>
                                    <p>
                                        By utilizing technology to handle repetitive tasks and streamline operations, businesses can increase efficiency and productivity.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Ai