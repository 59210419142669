import React from 'react'
import "./Technology.css"
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Technology = () => {
    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/technology" />
                    <meta name="keywords" content="Technology iCloudsoft, Software Development Company in Pune." />
                    <title> Technology | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className='TopHeader'>
                                <p className='mt-3'>
                                    <a className="text-decoration-none" href='/'>
                                        <span className='TopHeaderFirst'>
                                            HOME
                                        </span>
                                    </a>
                                    <span className='TopHeaderIcon'>
                                        <i class="fa-sharp fa-solid fa-angle-right"></i>
                                    </span>
                                    <span className='TopHeaderSecond'>
                                        TECHNOLOGY
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ServicesFirstSection" style={{ backgroundImage: `url(${require("../Img/TechnologyBgUpdate.png")})`, backgroundAttachment: 'local', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: "center" }}>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container-lg p-xl-4 pb-lg-3 mb-4">
                        <div className='ReasonsHireUs'>
                            <h2>
                                Technology
                            </h2>
                            <h1>
                                Advanced Technology Solution in AI & Software Development
                            </h1>
                        </div>
                        <div className="row g-4 p-xl-2 mb-sm-5">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-1.png")} alt="Java" title='Java' />
                                    <h3>
                                        Java
                                    </h3>
                                    &nbsp;
                                    <p>
                                        Java is a class-based, object-oriented, high-level programming language that aims to minimize implementation dependencies. At iCloudsoft, we assist customers in rapidly and inexpensively building Java-based software that can be sustainable.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-2.png")} alt="Dot Net" title='Dot Net' />
                                    <h3>
                                        Dot Net
                                    </h3>
                                    &nbsp;
                                    <p>
                                        Dot Net software development services cover engineering and evolution of web, mobile, and desktop applications with the use of Dot Net platform. Mature and impact-focused, iCloudsoft builds robust Dot Net apps from scratch and modernizes legacy solutions fast and reduced costs.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-3.png")} alt="Python" title='Python' />
                                    <h3>
                                        Python
                                    </h3>
                                    &nbsp;
                                    <p>
                                        iCloudsoft provides data scientists and Python developers who offer high productivity, timely delivery, and error-free code from the start. Our customized offerings range from delivering self-managed Python development teams to covering specialized Python skill gaps.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-6.png")} alt="Node js" title='Node js' />
                                    <h3>
                                        Node js
                                    </h3>
                                    &nbsp;
                                    <p>
                                        Node.js is a JavaScript runtime used for server-side development of software that can efficiently operate in a real-time, &nbsp;&nbsp;&nbsp; data-intensive environment.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-4.png")} alt="React js" title='React js' />
                                    <h3>
                                        React js
                                    </h3>
                                    &nbsp;
                                    <p>
                                        React js is used for building interactive user interfaces and web applications quickly and efficiently with significantly less code. It is maintained by Meta and a community of individual developers and companies.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-5.png")} alt="Mobile" title='Mobile' />
                                    <h3>
                                        Mobile
                                    </h3>
                                    &nbsp;
                                    <p>
                                        Mobile application development is the process to making software for smartphones and digital assistants. iCloudsoft develope app for Android, Approved banking apps, and other mobile solutions that power large corporations.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/ServicesPortfolioSectionBg.png")})`, backgroundAttachment: 'local', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }} >
                    <div className="container-lg p-xl-4 mt-4 pt-4 merriweather-bold">
                        <div className='Trending'>
                            <h2 className='text-white'>
                                Trending
                            </h2>

                            <div className='pt-2' style={{ borderBottom: "3px solid #fff", width: "7%" }}>
                            </div>
                        </div>

                        <div className="row mt-lg-5 d-flex justify-content-around g-4 ">
                            <div className="col-xl-6 col-md-6">
                                <div className="row g-4 d-flex justify-content-center">
                                    <div className="col-lg-12">
                                        <div className='d-flex text-center mb-4'>
                                            <img className='CoomanImgService' src={require("../Img/ServicePortfolioBottomSection.png")} alt="Big Data" title='Big Data' />
                                            <div className='CommonTextService '>
                                                <h2 className='text-white'> Big Data </h2>
                                                <p className='text-white'>
                                                    Big data services help companies maximize data value and achieve business goals with big data analysis. iCloudsoft renders a range of big data services, including consulting, implementation, support, and big data as a service to help clients benefit from the big data environment.
                                                    <br />
                                                    <b className='' style={{ fontWeight: "800" }}> iCloudsoft Big Data Services                                                    </b> <br />
                                                    1 . Big data consulting                                                    <br />
                                                    2 . Big data implementation                                                    <br />
                                                    3 . Big data support                                                    <br />
                                                    4 . Big data managed analytics services
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6">
                                <div className="row g-4 d-flex justify-content-center">
                                    <div className="col-lg-12">
                                        <div className='d-flex justify-content-center text-center mb-4 '>
                                            <img className='CoomanImgService' src={require("../Img/ServicePortfolioBottomSection3.png")} alt="Artifical Intelligence" title='Artifical Intelligence' />
                                            <div className='CommonTextService'>
                                                <h2 className='text-white'> Artifical Intelligence </h2>
                                                <p className='text-white'>
                                                    Artificial intelligence (AI) consulting helps companies automate and optimize business processes and make accurate forecasts with AI-powered software. iCloudsoft guides clients through AI implementation and delivers end-to-end AI solutions for non-IT enterprises and software product companies.
                                                    <br />
                                                    <b className='' style={{ fontWeight: "800" }}> AI Consulting Service Scope by iCloudsoft                                                    </b> <br />
                                                    1 . Business analysis                                                    <br />
                                                    2 . AI solution quality assurance                                                    <br />
                                                    3 . Data preparation management                                                    <br />
                                                    4 . AI solution development
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-12 col-md-6">
                                <div className="row g-4 d-flex justify-content-center">
                                    <div className="col-lg-12">
                                        <div className='d-flex justify-content-center text-center mb-4 pb-lg-4'>
                                            <img className='CoomanImgService' src={require("../Img/ServicePortfolioBottomSection2.png")} alt="Data Science" title='Data Science' />
                                            <div className='CommonTextService'>
                                                <h2 className='text-white'> Data Science</h2>
                                                <p className='text-white'>
                                                    Data science services help companies run experiments on their data in search of business insights. iCloudsoft renders data science consulting leveraging Machine Learning, Artificial Intelligence, and Deep Learning technologies to meet our clients’ most deliberate analytics needs.
                                                    <br />
                                                    <b className='' style={{ fontWeight: "800" }}> Our Data Science Services Include                                                    </b> <br />
                                                    1 . Business needs analysis                                                    <br />
                                                    2 . Data preparation                                                    <br />
                                                    4 . Machine learning (ML) Model design
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </section>

            <section>
                <div className="container-lg p-xl-4 merriweather-bold">
                    <div className='Trending'>
                        <h2>
                            Platforms
                        </h2>

                        <div className='pt-2' style={{ borderBottom: "3px solid #33A69A", width: "7%" }}>
                        </div>
                    </div>

                    <div className="row mt-lg-5 d-flex justify-content-around g-4">
                        <div className="col-xl-6 col-md-6">
                            <div className="row g-4 d-flex justify-content-center">
                                <div className="col-lg-12">
                                    <div className='d-flex text-center mb-4 pb-lg-4'>
                                        <img className='CoomanImgService' src={require("../Img/ServicePortfolioBottomSection.png")} alt="Microsoft" title='Microsoft' />
                                        <div className='CommonTextService'>
                                            <h2> Microsoft </h2>
                                            <p>
                                                Microsoft Consulting Services is a division of Microsoft Corporation that provides consulting services to businesses and organizations worldwide. MCS offers a range of services to help customers adopt, integrate, and optimize Microsoft technologies and solutions, including cloud computing, artificial intelligence, data analytics, and digital transformation.
                                                <br />
                                                <b className='' style={{ fontWeight: "800" }}> Our Microsoft Services </b> <br />
                                                1 . Digital transformation powered by Microsoft techs <br />
                                                2 . Big data solutions and platforms on Azure <br />
                                                3 . Data management & analytics with Azure and Power BI <br />
                                                4 . Cloud-native apps on Azure
                                            </p>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-center text-center mb-4 pb-lg-4'>
                                        <img className='CoomanImgService' src={require("../Img/ServicePortfolioBottomSection2.png")} alt="Power Apps" title='Power Apps' />
                                        <div className='CommonTextService'>
                                            <h2> Power Apps </h2>
                                            <p>
                                                Microsoft Power Apps is a suite of cloud-based software tools that allows users to create custom business applications without the need for extensive coding or development experience. With Power Apps, users can build custom applications that can be used across different devices, including desktops, mobile phones, and tablets.
                                                <br />
                                                <b className='' style={{ fontWeight: "800" }}> Our Power App Service                                                </b> <br />
                                                1 . App creation                                                <br />
                                                2 . App management                                                <br />
                                                3 . Data & integrations management                                                <br />
                                                4 . Data security & compliance
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-md-6">
                            <div className="row g-4 d-flex justify-content-center">
                                <div className="col-lg-12">
                                    <div className='d-flex justify-content-center text-center mb-4 pb-lg-4'>
                                        <img className='CoomanImgService' src={require("../Img/ServicePortfolioBottomSection3.png")} alt="Power BI" title='Power BI' />
                                        <div className='CommonTextService'>
                                            <h2> Power BI </h2>
                                            <p>
                                                iCloudsoft helps companies implement, improve, and support Power BI solutions to allow them to deliver timely and quality analytics insights across the company. We also assist with making the existing Power BI platform stay relevant to a company’s changing analytics needs.
                                                <br />
                                                <b className='' style={{ fontWeight: "800" }}> Power BI Use Cases                                                </b> <br />
                                                1 . Power BI Implementation Consulting                                                <br />
                                                2 . Power BI Consulting + Implementation                                                <br />
                                                3 . Power BI Improvement Consulting                                                <br />
                                                4 . Power BI Consulting + Support
                                            </p>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-center text-center mt-lg-5 pt-lg-2 mb-4 pb-lg-4'>
                                        <img className='CoomanImgService' src={require("../Img/ServicePortfolioBottomSectio4.png")} alt="SharePoint and Office 365" title='SharePoint and Office 365' />
                                        <div className='CommonTextService '>
                                            <h2> SharePoint and Office 365 </h2>
                                            <p>
                                                SharePoint is a web-based collaboration and document management platform developed by Microsoft. It is primarily used for managing and sharing documents, lists, and information within organizations. SharePoint provides a centralized location for employees to store, organize, and access files and data from anywhere, using any device.

                                                <br />
                                                <b className='' style={{ fontWeight: "800" }}> SharePoint Functionality</b> <br />
                                                1 . Document management                                                <br />
                                                2 . Collaboration                                                <br />
                                                3 . Human resources management                                                <br />
                                                4 . Learning management
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section >

        </>
    )
}

export default Technology