import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const VideoMarketing = () => {
    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/digiatlmarketing/videomarketing" />
                    <meta name="keywords" content="Video Marketing iCloudsoft, Software Development Company in Pune." />
                    <title> Video Marketing | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-5 mt-5 d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className=''>
                                        Video Marketing
                                    </h3>
                                    <h1>
                                        Dynamic Video Marketing Services in Pune
                                    </h1>
                                    <p>
                                        At iCloudsoft technology, we help you to promotes products, services, or brands through video content, engaging audiences and driving conversions. Benefits include increased engagement, brand recall, and emotional connection. Popular platforms like YouTube and Instagram Reels host effective content types like explainers, demos, and testimonials, boosting SEO, conversions, and sales.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <img className='w-100' src={require("../Img/videoMarketing.png")} alt="videoMarketing" title='videoMarketing' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block ArtificeMainBg" >
                    <div className="container-lg p-2">
                        <div className="row g-4 pt-2 pb-2 d-flex justify-content-between align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Video Marketing
                                    </h3>
                                    <h4 className='laila-semibold' style={{ fontSize: "17px", fontWeight: "600", color: "#fff" }}>
                                        Dynamic Video Marketing Services in Pune
                                    </h4>
                                    <p>
                                        At iCloudsoft technology, we help you to promotes products, services, or brands through video content, engaging audiences and driving conversions. Benefits include increased engagement, brand recall, and emotional connection. Popular platforms like YouTube and Instagram Reels host effective content types like explainers, demos, and testimonials, boosting SEO, conversions, and sales.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5">
                                <img className='w-100' src={require("../Img/videoMarketing.png")} alt="videoMarketing" title='videoMarketing' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-3" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='IntelligenceArtificeMain'>
                            <h2>
                                Our Prime Focus
                            </h2>
                            <div>
                                <p>
                                    Our prime focus of video marketing is to engage audiences, tell compelling brand stories, and provide value through informative or entertaining content. Relevance to the target audience's interests and needs is crucial. Authenticity in showcasing the brand's personality and messaging is vital. A clear call-to-action (CTA) should encourage viewers to take desired actions. Measurable goals, consistency, and optimization for search engines, devices, and platforms are also essential. By prioritizing these elements, our video marketing will effectively achieve business objectives, build brand awareness, and drive conversions. Our well-crafted strategy will resonate with the target audience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Video Marketing </span> Services
                            </h2>
                            <div className='d-flex justify-content-center'>
                                <p>
                                    Our video marketing solutions encompasses various solutions, from corporate videos and product demos to social media content and immersive brand storytelling We understand the power of visual storytelling and are mitted to helping your brand shine through video
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Explainer Video Production
                                    </h2>
                                    <p>
                                        - Short, engaging videos explaining products, services, or concepts <br />
                                        - Simplify complex information, increase understanding, and drive conversions <br />
                                        - Typically 60-90 seconds, 2D/3D animation, or live-action
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Brand Storytelling
                                    </h2>
                                    <p>
                                        - Emotional, narrative-driven videos showcasing brand history, mission, and values <br />
                                        - Build brand awareness, establish trust, and create connections with audiences <br />
                                        - Often 2-5 minutes, cinematic, and story-driven
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Product Demo Videos
                                    </h2>
                                    <p>
                                        - Detailed, instructional videos demonstrating product features and benefits <br />
                                        - Showcase product capabilities, increase sales, and reduce support queries <br />
                                        - Typically 2-10 minutes, screencasts, or live-action
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Testimonial and Case Study Videos
                                    </h2>
                                    <p>
                                        - Authentic customer testimonials and success stories <br />
                                        - Build trust, credibility, and social proof <br />
                                        - Often 2-5 minutes, interview-style, and documentary-like
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Social Media Video Content
                                    </h2>
                                    <p>
                                        - Short-form, engaging videos for social media platforms (Facebook, Instagram, Twitter, etc.) <br />
                                        - Increase brand awareness, drive engagement, and boost website traffic <br />
                                        - Typically 15-60 seconds, vertical, and attention-grabbing
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Live-Action and Event Coverage Videos
                                    </h2>
                                    <p>
                                        - High-quality, cinematic videos capturing events, conferences, and product launches <br />
                                        - Showcase company culture, build brand reputation, and create memorable experiences <br />
                                        - Often 3-10 minutes, live-action, and documentary-style
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Video Marketing </span> Services
                            </h2>
                            <div className='d-flex justify-content-center pt-3'>
                                <p>
                                    Our video marketing solutions encompasses various solutions, from corporate videos and product demos to social media content and immersive brand storytelling We understand the power of visual storytelling and are mitted to helping your brand shine through video
                                </p>
                            </div>
                        </div>

                        <div className="row g-4 pt-3">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Explainer Video Production
                                    </h2>
                                    <p>
                                        - Short, engaging videos explaining products, services, or concepts <br />
                                        - Simplify complex information, increase understanding, and drive conversions <br />
                                        - Typically 60-90 seconds, 2D/3D animation, or live-action
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Brand Storytelling
                                    </h2>
                                    <p>
                                        - Emotional, narrative-driven videos showcasing brand history, mission, and values <br />
                                        - Build brand awareness, establish trust, and create connections with audiences <br />
                                        - Often 2-5 minutes, cinematic, and story-driven
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Product Demo Videos
                                    </h2>
                                    <p>
                                        - Detailed, instructional videos demonstrating product features and benefits <br />
                                        - Showcase product capabilities, increase sales, and reduce support queries <br />
                                        - Typically 2-10 minutes, screencasts, or live-action
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2>
                                        Testimonial and Case Study Videos
                                    </h2>
                                    <p>
                                        - Authentic customer testimonials and success stories <br />
                                        - Build trust, credibility, and social proof <br />
                                        - Often 2-5 minutes, interview-style, and documentary-like
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Social Media Video Content
                                    </h2>
                                    <p>
                                        - Short-form, engaging videos for social media platforms (Facebook, Instagram, Twitter, etc.) <br />
                                        - Increase brand awareness, drive engagement, and boost website traffic <br />
                                        - Typically 15-60 seconds, vertical, and attention-grabbing
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='ArtificeMainService'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gears"></i>
                                    </div>
                                    <h2>
                                        Live-Action and Event Coverage Videos
                                    </h2>
                                    <p>
                                        - High-quality, cinematic videos capturing events, conferences, and product launches <br />
                                        - Showcase company culture, build brand reputation, and create memorable experiences <br />
                                        - Often 3-10 minutes, live-action, and documentary-style
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Benefits Of Our  <span> Video Marketing </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-8">
                                <div className="row g-4 pt-3 AIDevelopmentBenefits">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-gear"></i>
                                            </div>
                                            <h2>
                                                Increased Engagement
                                            </h2>
                                            <p>
                                                - Videos capture audience attention, encouraging interaction and participation <br />
                                                - Boosts social media engagement, website traffic, and conversion rates <br />
                                                - Emotional connections through storytelling and visuals
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-brands fa-hive"></i>
                                            </div>
                                            <h2 >
                                                Improved Brand Awareness
                                            </h2>
                                            <p>
                                                - Videos showcase brand personality, values, and mission <br />
                                                - Establishes trust, credibility, and recognition <br />
                                                - Consistent branding across video content reinforces identity
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-layer-group"></i>
                                            </div>
                                            <h2>
                                                Enhanced Conversion Rates
                                            </h2>
                                            <p>
                                                - Videos demonstrate products, services, and benefits <br />
                                                - Increases understanding, builds trust, and drives sales <br />
                                                - Clear calls-to-action (CTAs) encourage desired actions
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                Better SEO and Search Engine Rankings
                                            </h2>
                                            <p>
                                                - Videos optimize for search engines, improving visibility and rankings <br />
                                                - Increased dwell time, reduced bounce rates, and improved user experience <br />
                                                - YouTube, the second-largest search engine, prioritizes video content
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <i class="fa-solid fa-chart-simple"></i>
                                            </div>
                                            <h2>
                                                Increased Customer Retention and Loyalty
                                            </h2>
                                            <p>
                                                - Videos educate, entertain, and build relationships with customers <br />
                                                - Regular video content maintains audience interest and engagement <br />
                                                - Fosters loyalty, reduces churn, and encourages advocacy
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='BenefitsOfAI'>
                                            <div className='CommonIconArtificeMain '>
                                                <img className='' src={require("../Img/SocialMediaIcon.png")} alt="SocialMediaIcon" title='SocialMediaIcon' />
                                                {/* <i class="fa-solid fa-gears"></i> */}
                                            </div>
                                            <h2>
                                                Measurable Results and ROI
                                            </h2>
                                            <p>
                                                - Trackable metrics: views, clicks, conversions, and engagement <br />
                                                - Analyze video performance, refine strategies, and optimize content <br />
                                                - Demonstrates ROI, justifying video marketing investments
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/BenefitsUp.png")} alt="BenefitsUp" title='BenefitsUp' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 pt-4 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "24px" }}>
                                Benefits Of Our <span> AI Development </span>
                            </h2>
                        </div>

                        <div className="row g-4 pt-3 AIDevelopmentBenefits">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-gear"></i>
                                    </div>
                                    <h2>
                                        Increased Engagement
                                    </h2>
                                    <p>
                                        - Videos capture audience attention, encouraging interaction and participation <br />
                                        - Boosts social media engagement, website traffic, and conversion rates <br />
                                        - Emotional connections through storytelling and visuals
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-brands fa-hive"></i>
                                    </div>
                                    <h2 >
                                        Improved Brand Awareness
                                    </h2>
                                    <p>
                                        - Videos showcase brand personality, values, and mission <br />
                                        - Establishes trust, credibility, and recognition <br />
                                        - Consistent branding across video content reinforces identity
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-layer-group"></i>
                                    </div>
                                    <h2>
                                        Enhanced Conversion Rates
                                    </h2>
                                    <p>
                                        - Videos demonstrate products, services, and benefits <br />
                                        - Increases understanding, builds trust, and drives sales <br />
                                        - Clear calls-to-action (CTAs) encourage desired actions
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Better SEO and Search Engine Rankings
                                    </h2>
                                    <p>
                                        - Videos optimize for search engines, improving visibility and rankings <br />
                                        - Increased dwell time, reduced bounce rates, and improved user experience <br />
                                        - YouTube, the second-largest search engine, prioritizes video content
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <i class="fa-solid fa-chart-simple"></i>
                                    </div>
                                    <h2>
                                        Increased Customer Retention and Loyalty
                                    </h2>
                                    <p>
                                        - Videos educate, entertain, and build relationships with customers <br />
                                        - Regular video content maintains audience interest and engagement <br />
                                        - Fosters loyalty, reduces churn, and encourages advocacy
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='BenefitsOfAI'>
                                    <div className='CommonIconArtificeMain '>
                                        <img className='w-100 bg-danger' src={require("../Img/SocialMediaIcon.png")} alt="SocialMediaIcon" title='SocialMediaIcon' />
                                        {/* <i class="fa-solid fa-gears"></i> */}
                                    </div>
                                    <h2>
                                        Measurable Results and ROI
                                    </h2>
                                    <p>
                                        - Trackable metrics: views, clicks, conversions, and engagement <br />
                                        - Analyze video performance, refine strategies, and optimize content <br />
                                        - Demonstrates ROI, justifying video marketing investments
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-3">
                        <div className='WhyChoseArtificeMain'>
                            <h2>
                                Our <span> Video Marketing </span> Work
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AIWork'>
                                    <h2>
                                        <span> Video Marketing </span> made Accessible And Easy
                                    </h2>
                                    <p>
                                        Our advancements like Affordable equipment and editing software, Cloud-based platforms for hosting and management, Social media integration for easy sharing, Template-based solutions for simple video creation, Increased bandwidth and storage for seamless uploading, Mobile optimization for wider reach, Drag-and-drop tools for easy editing, AI-assisted video editing and analysis & Online resources and tutorials for education have made video marketing more accessible, allowing businesses and individuals to create and share high-quality video content without extensive technical expertise or resources.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/AIwork.png")} alt="AIwork" title='AIwork' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pt-5 pb-5 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-3">
                        <div className='WhyChoseArtificeMain'>
                            <h2 className='' style={{ fontSize: "28px" }}>
                                Our <span> Video Marketing </span> Work
                            </h2>
                        </div>

                        <div className="row g-4 pt-4 d-flex justify-content-around align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/AIwork.png")} alt="AIwork" title='AIwork' />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className='AIWork'>
                                    <h2>
                                        <span> Video Marketing </span> made Accessible And Easy
                                    </h2>
                                    <p>
                                        Our advancements like Affordable equipment and editing software, Cloud-based platforms for hosting and management, Social media integration for easy sharing, Template-based solutions for simple video creation, Increased bandwidth and storage for seamless uploading, Mobile optimization for wider reach, Drag-and-drop tools for easy editing, AI-assisted video editing and analysis & Online resources and tutorials for education have made video marketing more accessible, allowing businesses and individuals to create and share high-quality video content without extensive technical expertise or resources.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-3 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg p-2 pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../Img/AITechnology.png")} alt="AITechnology" title='AITechnology' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AITechnology'>
                                    <h2>
                                        Transform Your Business With <span> Video Marketing </span>
                                    </h2>
                                    <p>
                                        Transform your business with our advance facilities of video marketing! Boost engagement, showcase products, build brand trust, drive website traffic, and enhance customer experience. Leverage video content to tell your brand's story, connect with audiences, and achieve business goals. Elevate your marketing strategy with compelling video content and watch your business thrive!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-2 d-lg-none d-md-block" style={{ backgroundColor: "#000C14" }}>
                    <div className="container-lg  pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../Img/AITechnology.png")} alt="AITechnology" title='AITechnology' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AITechnology'>
                                    <h2>
                                        Transform Your Business With <span> Video Marketing </span>
                                    </h2>
                                    <p>
                                        Transform your business with our advance facilities of video marketing! Boost engagement, showcase products, build brand trust, drive website traffic, and enhance customer experience. Leverage video content to tell your brand's story, connect with audiences, and achieve business goals. Elevate your marketing strategy with compelling video content and watch your business thrive!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default VideoMarketing